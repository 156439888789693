import * as React from "react";
import { ChakraProvider, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

export function NotFound() {
  return (
    <>
      <Helmet>
        <title>404 - CUC Fitness Studio</title>
        <meta
          name="description"
          content="Página no encontrada en CUC Fitness Studio."
        />
      </Helmet>
      <ChakraProvider>
        <Text mt={"100px"}>404 NOT FOUND</Text>
      </ChakraProvider>
    </>
  );
}

export default NotFound;
