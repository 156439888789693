import { Skeleton, HStack, Box } from "@chakra-ui/react";

import React from "react";

export function SkeletonBonoCard(props) {
  return (
    <>
      <Box
        mt={6}
        role={"group"}
        pt={6}
        px={5}
        pb={3}
        bg="white"
        boxShadow={"xl"}
        rounded={"sm"}
        zIndex={1}
        w="300px"
        mx={3}
        mb={8}
        _hover={{
          transition: "all 0.5s",
        }}
        h={"504px"}
      >
        <Skeleton h="250px" w="260px" borderRadius={0} />
        <Skeleton h="45px" w="260px" mt={1} borderRadius={0} />
        <Skeleton h="90px" w="260px" mt={1} borderRadius={0} />
        <Skeleton h="21px" w="260px" mt={1} borderRadius={0} />
        <HStack mt={1} justifyContent={"center"}>
          <Skeleton h="18px" w="50px" borderRadius={0} />
          <Skeleton h="18px" w="50px" borderRadius={0} />
          <Skeleton h="18px" w="50px" borderRadius={0} />
          <Skeleton h="18px" w="50px" borderRadius={0} />
        </HStack>
        <HStack mt={1} justifyContent="center">
          <Skeleton h="18px" w="50px" borderRadius={0} />
          <Skeleton h="18px" w="50px" borderRadius={0} />
        </HStack>
      </Box>
    </>
  );
}
