import * as React from "react";
import { ChakraProvider, Box, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

export function Cookies() {
  return (
    <>
      <Helmet>
        <title>Política de cookies - CUC Fitness Studio</title>
        <meta
          name="description"
          content="Política de cookies de CUC Fitness Studio."
        />
      </Helmet>
      <ChakraProvider>
        <Box
          pt={"100px"}
          pb={8}
          textAlign="justify"
          margin={"auto"}
          width="80%"
          maxWidth={"1100px"}
        >
          <Heading
            fontFamily={"Oswald"}
            fontWeight={500}
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color={"gray.800"}
            textAlign={"center"}
            mt={"20px"}
            mb={2}
          >
            Política de{" "}
            <Text as={"span"} color={"blue.500"}>
              cookies
            </Text>
          </Heading>
          <Box mt={3}>
            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={5}
            >
              INTRODUCCIÓN
            </Text>
            <Text mb={2}>
              El presente apartado tiene como finalidad informar a los clientes,
              usuarios y demás partes interesadas sobre el uso y manejo de
              cookies en CUC Fitness Studio. Las cookies son pequeños archivos
              de texto que los sitios web envían al navegador y se almacenan en
              el dispositivo del usuario. Estas son esenciales para el correcto
              funcionamiento del sitio, permitiendo una experiencia de usuario
              más fluida y eficiente.
            </Text>

            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={5}
            >
              COOKIES UTILIZADAS
            </Text>
            <Text mb={2}>
              En CUC Fitness Studio se utilizan exclusivamente cookies técnicas,
              es decir, aquellas necesarias para la navegación y el correcto
              funcionamiento de nuestro sitio web. Permiten controlar el tráfico
              desde el servidor a múltiples usuarios a la vez, la identificación
              y el acceso como usuario del sistema, entre otros aspectos.
            </Text>
            <Box ml={"40px"} mb={4}>
              <ul>
                <li>- Cookies de sesión:</li>
                <Text mb={2}>
                  Son temporales y se eliminan automáticamente cuando cierras tu
                  navegador. Son esenciales para permitir que el sitio web
                  funcione correctamente, facilitando la transición entre
                  diferentes páginas del sitio y asegurando que las
                  funcionalidades de usuario permanezcan accesibles.
                </Text>
                <li>- Cookies de seguridad:</li>
                <Text mb={2}>
                  Utilizamos cookies de seguridad para autenticar usuarios,
                  prevenir el uso fraudulento de credenciales de inicio de
                  sesión y proteger los datos de usuario de accesos no
                  autorizados. Son fundamentales para la seguridad del sitio y
                  del usuario.
                </Text>
              </ul>
            </Box>

            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={5}
            >
              GESTIÓN DE COOKIES
            </Text>
            <Text mb={2}>
              Los navegadores web permiten a los usuarios visualizar, eliminar o
              restringir las cookies. Sin embargo, nuestro sitio requiere el uso
              de cookies técnicas para operar correctamente. Si se desactivan o
              eliminan, es posible que algunas partes del sitio no funcionen
              como se espera. Para la gestión de cookies, los usuarios deben
              seguir las instrucciones proporcionadas por el navegador
              utilizado. Aquí se incluyen enlaces a guías para los navegadores
              más comunes:
            </Text>
            <Box ml={"40px"} mb={4} textAlign="left">
              <ul>
                <li>
                  - Google Chrome:{" "}
                  <a href="https://support.google.com/chrome/answer/95647">
                    https://support.google.com/chrome/answer/95647
                  </a>
                </li>
                <li>
                  - Mozilla Firefox:{" "}
                  <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">
                    https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
                  </a>
                </li>
                <li>
                  - Safari:{" "}
                  <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                    https://support.apple.com/es-es/guide/safari/sfri11471/mac
                  </a>
                </li>
                <li>
                  - Microsoft Edge:{" "}
                  <a href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                    https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
                  </a>
                </li>
              </ul>
            </Box>

            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={5}
            >
              COMPROMISO DE PRIVACIDAD
            </Text>
            <Text mb={2}>
              Desde CUC Fitness Studio nos comprometemos a utilizar las cookies
              de manera responsable y solo para los fines especificados en este
              apartado. Respetamos la privacidad de nuestros usuarios y
              garantizamos que el tratamiento de la información recopilada es
              seguro y confidencial.
            </Text>
            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={5}
            >
              CONTACTO
            </Text>
            <Text mb={2}>
              Para cualquier pregunta o consulta relacionada con nuestra
              política de cookies, no dude en contactarnos a través del correo
              electrónico cucfitness@gmail.com.
            </Text>
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Cookies;
