import React from "react";
import {
  HStack,
  Badge,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Switch,
} from "@chakra-ui/react";

export function UserInfo(props) {

  function getFullTimestamp(d) {
    if (d === null) return "Nunca";

    const formattedTime = new Date(d).toLocaleTimeString("es-ES", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  
    const formattedDate = new Date(d).toLocaleDateString("es-ES", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      timeZone: "UTC", // Ajustar la zona horaria según sea necesario
    });
  
    return `${formattedDate}, ${formattedTime}`;
  }

  function getRoleName(role) {
    const sufixIndex = role.indexOf("_ROLE");
    return sufixIndex !== -1 ? role.substring(0, sufixIndex) : role;
  }

  return (
    <>
      <TableContainer m="auto">
        <Table variant="simple" size="sm">
          <Tbody>
            <Tr>
              <Td>Miembro desde: </Td>
              <Td isNumeric>{getFullTimestamp(props.user.created)}</Td>
            </Tr>
            <Tr>
              <Td>Última modificación</Td>
              <Td isNumeric>{getFullTimestamp(props.user.modified)}</Td>
            </Tr>
            <Tr>
              <Td>Último acceso</Td>
              <Td isNumeric>{getFullTimestamp(props.user.lastLogin)}</Td>
            </Tr>
            <Tr>
              <Td>Roles</Td>
              <Td isNumeric>
                <HStack spacing={2} justifyContent="right">
                  {props.user.roles.map((role) => (
                    <Badge key={role} colorScheme="blue" size="xs">
                      {getRoleName(role)}
                    </Badge>
                  ))}
                </HStack>
              </Td>
            </Tr>
            <Tr>
              <Td>Notificaciones por correo</Td>
              <Td>
                <Switch
                  size="md"
                  isDisabled
                  isChecked={props.user.emailNotifEnabled}
                  display="flex"
                  justifyContent="right"
                />
              </Td>
            </Tr>
            <Tr>
              <Td>Privacidad asistencia</Td>
              <Td>
                <Switch
                  size="md"
                  isDisabled
                  isChecked={props.user.showAttendanceEnabled}
                  display="flex"
                  justifyContent="right"
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
