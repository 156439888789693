import {
  Text,
  SimpleGrid,
  Box,
  AvatarGroup,
  Avatar,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  HStack,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Checkbox,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import "./style.css";
import { MdChecklist } from "react-icons/md";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { MdOutlineEventAvailable } from /*MdOutlinePersonRemove,*/ "react-icons/md";
import ConfirmationModal from "../ConfirmationModal";
import axios from "axios";

export function CoachNextCard(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const now = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(now.getDate() + 1);
  const endDate = new Date(props.trainingEndDate);
  const startDate = new Date(props.trainingStartDate);
  const day = new Date(props.trainingStartDate);
  const hours = day.getHours();
  const minutes = day.getMinutes().toString().padStart(2, "0");
  const [isConfirmAttendanceModal, setIsConfirmAttendanceModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [destroyed, setDestroyed] = useState(false);

  const { control, handleSubmit, register } = useForm({
    defaultValues: {},
    shouldFocusError: false,
  });

  function onSubmit(data) {
    setIsConfirmAttendanceModal(data.participants);
  }

  function onErrors(errors) {
    console.log("FORM ERRORS:", errors);
  }

  async function handleConfirmAttendance(response) {
    setIsConfirmAttendanceModal(false);

    if (response) {
      setIsLoading(true);
      const formResult = isConfirmAttendanceModal
        .filter((item) => item.disabled === "false") // Filtrar los objetos con disabled: "false"
        .map(({ id, attended }) => ({ userId: parseInt(id), attended })); // Mapear solo los campos id y attended

      /*console.log(
        "MAKE API CALL",
        JSON.stringify(formResult),
        props.trainingId
      );*/

      await axios
        .put("/api/coach/booking/attendance", {
          trainingId: props.trainingId,
          participants: formResult,
        })
        .then((response) => {
          toast({
            title: "Asistencia actualizada",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });

          setDestroyed(true);
        })
        .catch((error) => {
          toast({
            title: error.response.data.message,
            status: "error",
            position: "bottom-right",
            duration: 4000,
            isClosable: true,
          });
          console.log(error);
        });

      onClose();
      setIsLoading(false);
    } else {
      setIsConfirmAttendanceModal(false);
    }
  }

  function handleClick(e) {
    if (now < endDate) return;

    //if (process.env.REACT_APP_ENV !== "LOCAL") return;
    if (props.trainingClients && props.trainingClients.length === 0) return;
    onOpen();
  }

  function handleButtonClick(e) {
    e.stopPropagation();
  }

  function getTrainingDate() {
    if (now.toDateString() === startDate.toDateString()) {
      // The class has already ended
      return "HOY";

    }else if (tomorrow.toDateString() === startDate.toDateString()) {
      return "MAÑANA";

    } else {
      // The class is happening in the future on a different day
      return startDate.toLocaleDateString("es-ES", {
        month: "2-digit",
        day: "2-digit",
      });
    }
  }

  function trainingColor() {
    if (now > startDate) {
      // The class has already ended
      return "red.900";
    } else {
      // The class has not started yet
      return "gray.800";
    }
  }

  if (destroyed) {
    return null;
  }

  return (
    <>
      {isConfirmAttendanceModal && (
        <ConfirmationModal
          title="Confirmación de asistencia"
          message="La asistencia no podrá editarse una vez guardada. ¿Estás seguro que deseas continuar?"
          yesButton="Sí, continuar"
          optionalButton="Seguir editando"
          onConfirm={handleConfirmAttendance}
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="sm"
        borderRadius={"0px"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={2} pb={2} textAlign={"left"}>
            <Text>
              Asistencia {props.trainingName} ({getTrainingDate()})
            </Text>
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <ModalBody>
              <Stack
                spacing={3}
                w="100%"
                border="1px solid #e2e8f0"
                px={2.5}
                py={"11px"}
                //maxH="145px"
                overflow="auto"
              >
                {props.trainingClients.map((item, index) => (
                  <div key={index}>
                    <HStack key={item.name + item.surname1}>
                      <Avatar
                        size="sm"
                        name={`${item.name} ${item.surname1}`.trim()}
                        src={""}
                        className="prevent-select"
                      />
                      <label>
                        <Text id="avatar-text" ml={1}>
                          {item.name + " " + item.surname1}
                        </Text>
                      </label>
                      <Controller
                        name={`participants[${index}].attended`}
                        control={control}
                        defaultValue={
                          item.attended === null ? true : item.attended
                        }
                        render={({ field }) => (
                          <Checkbox
                            defaultChecked={
                              item.attended === null ? true : item.attended
                            }
                            isDisabled={item.attended != null}
                            {...field}
                            ml="auto"
                            mr="4px"
                          />
                        )}
                      />
                      <input
                        type="hidden"
                        value={item.id}
                        {...register(`participants[${index}].id`)}
                      />
                      <input
                        type="hidden"
                        value={item.attended != null}
                        {...register(`participants[${index}].disabled`)}
                      />
                    </HStack>
                  </div>
                ))}
              </Stack>
            </ModalBody>
            <ModalFooter pt={3}>
              <Button
                onClick={onClose}
                borderRadius={0}
                display={"flex"}
                justifyContent={"flex-end"}
                alt="Cancelar"
                mr={2}
                isDisabled={isLoading}
                /* Botón de cancelación */
              >
                Cancelar
              </Button>
              <Spacer />
              <Button
                type="submit"
                justifyContent="center"
                variant={"outline"}
                colorScheme="blue"
                borderRadius={0}
                display={"flex"}
                alt="Guardar sesión"
                isLoading={isLoading}
                /* Botón de guardado */
              >
                Guardar&nbsp;
                <MdOutlineEventAvailable />
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Box onClick={handleClick}>
        <SimpleGrid
          columns={3}
          spacing={0}
          bgColor={trainingColor()}
          color="white"
          py={{ base: 3, sm: 5 }}
          px={4}
          textAlign="left"
          fontFamily={"Oswald"}
          cursor={"pointer"}
          className="prevent-select"
        >
          <Box my="auto">
            <Text
              fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
              fontWeight="500"
            >
              {getTrainingDate()}
            </Text>
            <Text
              fontWeight={100}
              fontSize={{ base: "md", sm: "md", md: "md", lg: "lg" }}
            >
              {`${hours}:${minutes}h`}
            </Text>
          </Box>
          <Box textAlign="center" m="auto">
            <Text
              fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
              fontWeight={400}
            >
              {props.trainingName}
            </Text>
            <Text
              fontWeight={100}
              fontSize={{ base: "md", sm: "md", md: "lg", lg: "lg" }}
            >
              {props.trainingLocation}
            </Text>
          </Box>
          <Box spacing={2} margin="auto" mr={now > endDate ? 0 : 1}>
            {props.trainingClients.length > 0 ? (
              <Stack spacing={2} alignItems="flex-end">
                <Popover returnFocusOnClose={false}>
                  <HStack spacing={{ md: 1.5, lg: 0.5 }}>
                    <PopoverTrigger>
                      <Button
                        size="xl"
                        onClick={handleButtonClick}
                        variant="ghost"
                        _hover={{
                          bg: "transparent",
                        }}
                        display={
                          now > endDate
                            ? { base: "none", sm: "none", md: "flex" }
                            : "flex"
                        }
                      >
                        <AvatarGroup
                          id="avatar-group"
                          size={
                            now > endDate
                              ? { md: "md", lg: "md" }
                              : { base: "sm", sm: "md", md: "md", lg: "md" }
                          }
                          max={now > endDate ? 2 : 3}
                          textAlign={"right"}
                          justifyContent="flex-start"
                          color="gray.800"
                          fontFamily={"system-ui"}
                        >
                          {props.trainingClients.map((item) => {
                            if (item.name === "") {
                              return <Avatar className="prevent-spelect" />;
                            } else {
                              return (
                                <Avatar
                                  key={item.name + " " + item.surname1}
                                  name={`${item.name} ${item.surname1}`.trim()}
                                  src={item.avatar}
                                  className="prevent-select"
                                />
                              );
                            }
                          })}
                        </AvatarGroup>
                      </Button>
                    </PopoverTrigger>
                    <Button
                      color={"white"}
                      borderRadius={0}
                      fontWeight={400}
                      fontFamily={"Oswald"}
                      size={{ base: "lg", sm: "lg", md: "sm", lg: "md" }}
                      colorScheme="black"
                      display={now > endDate ? "flex" : "none"}
                      pl={"4px !important"}
                      pr={"0px !important"}
                      //isLoading={isLoading}
                      //spinner={<BeatLoader size={8} color="white" />}
                    >
                      <MdChecklist size="1.5em" />
                    </Button>
                  </HStack>
                  <PopoverContent
                    color="black"
                    fontFamily="system-ui"
                    width="fit-content"
                  >
                    <PopoverArrow />
                    <PopoverBody className="allow-select" cursor="auto">
                      <Stack spacing={2}>
                        {props.trainingClients.map((item) => {
                          if (item.name === "") {
                            return (
                              <HStack id="testing" key={item.name}>
                                <Avatar size="sm" className="prevent-select" />
                                <Text>Anónimo</Text>
                              </HStack>
                            );
                          } else {
                            return (
                              <HStack id="testing" key={item.name}>
                                <Avatar
                                  size="sm"
                                  key={item.name + " " + item.surname1}
                                  name={`${item.name} ${item.surname1}`.trim()}
                                  src={item.avatar}
                                  className="prevent-select"
                                />
                                <Text>{item.name + " " + item.surname1}</Text>
                              </HStack>
                            );
                          }
                        })}
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Stack>
            ) : (
              <Text fontFamily={"Oswald"} fontSize={{sm: "md", md: "lg"}} py={{ md: 0, lg: 1 }}>
                0 reservas
              </Text>
            )}
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
}
