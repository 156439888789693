import React from "react";
import { TrainingActivity } from "./TrainingActivityCard";
import { SimpleGrid, Skeleton, Box, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";

export function UserBookings() {
  const [bookings, setBookings] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/api/bookings");
      setBookings(data);

    })();
  }, []);

  return (
    <>
      <SimpleGrid
        id="my-bono-grid"
        columns={{ md: 2, lg: 3, xl: 3, "2xl": 3 }}
        spacing={4}
        width={{ base: "100%", sm: "100%", md: "80%" }}
        maxW="1300px"
        m="auto"
      >
        {!bookings && (
          <>
            <Skeleton height="144px" width="100%" borderRadius={0} />
            <Skeleton height="144px" width="100%" borderRadius={0} />
          </>
        )}

        {bookings &&
          bookings.length !== 0 &&
          bookings.map((item) => {
            return (
              <TrainingActivity
                key={item.trainingId}
                trainingStatus={item.status}
                trainingName={item.trainingName}
                trainingTime={item.startDate}
                trainingDate={item.startDate}
                trainingImage={item.trainingImage}
              />
            );
          })}
      </SimpleGrid>

      {bookings && bookings.length === 0 && (
        <Box
          h={{ base: "78px", sm: "104px", md: "110px", lg: "121px" }}
          border="2px solid #EDF2F7"
          display="flex"
          width={{ sm: "100%", md: "80%" }}
          maxW="1300px"
          m="auto"
        >
          <Stack m="auto">
            <Text fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}>
              Todavía no has hecho ninguna reserva
            </Text>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default UserBookings;
