import {
  Box,
  Text,
  SimpleGrid,
  Stack,
  HStack,
  AvatarGroup,
  Avatar,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Collapse,
  useDisclosure,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "../../auth/useAuth";
import { useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import ConfirmationModal from "../ConfirmationModal";
import { useTranslation } from "react-i18next";

export function TrainingCard(props) {
  const { isOpen: isCardOpen, onToggle: onCardToggle } = useDisclosure();
  const auth = useAuth();
  const toast = useToast();
  const [status, setStatus] = useState(props.trainingStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmCancellation, setIsConfirmCancellation] = useState(false);
  const { t } = useTranslation();

  let trainingStatus;
  switch (status) {
    case "BOOKED":
      trainingStatus = {
        status: "BOOKED",
        color: "red.400",
        hover: "red.600",
        text: "Cancelar",
      };
      break;
    case "BOOKED_NOT_REFUNDABLE": // TODO: en este caso la clase no se devolverá al bono
      trainingStatus = {
        status: "BOOKED",
        color: "red.400",
        hover: "red.600",
        text: "Cancelar",
      };
      break;
    case "BOOKABLE":
      trainingStatus = {
        status: "BOOKABLE",
        color: "blue.400",
        hover: "blue.600",
        text: "Reservar",
      };
      break;
    case "NOT_AVAILABLE":
      trainingStatus = {
        status: "NOT_AVAILABLE",
        color: "gray.500",
        hover: "gray.600",
        text: "No disponible",
      };
      break;
    case "FULL":
      trainingStatus = {
        status: "FULL",
        color: "green.400",
        hover: "green.600",
        text: "Completa",
      };
      break;
    default:
      trainingStatus = {
        status: "BOOKABLE",
        color: "blue.400",
        hover: "blue.600",
        text: "Reservar",
      };
      break;
  }

  async function handleButtonClick() {
    const cancellationMessage =
      "¡Oh, vaya! Parece que no vas a poder asistir... La sesión será reembolsada automáticamente a tu bono. ¿Estás seguro que deseas continuar?";
    const noRefundableMessage = (
      <>
        Recuerda que cancelando con menos de 4 horas de antelación tu sesión{" "}
        <u>no será reeembolsada</u>. ¿Estás seguro que deseas cancelar?
      </>
    );

    if (!auth.isLogged()) {
      document.getElementById("menu-button-log-button").click();
      return;
    }

    switch (status) {
      case "BOOKED":
        console.log("Cancel booking...", props.trainingId, props.trainingStart);
        const currentTime = new Date();
        const returnTime = new Date(props.trainingStart);
        returnTime.setHours(returnTime.getHours() - 4);

        if (returnTime >= currentTime) {
          setIsConfirmCancellation(cancellationMessage);
        } else {
          setIsConfirmCancellation(noRefundableMessage);
        }
        break;

      case "BOOKABLE":
        console.log("Booking training...", props.trainingId);
        setIsLoading(true);
        await axios
          .post("/api/booking/" + props.trainingId)
          .then((response) => {
            toast({
              title: "Sesión reservada 🔥",
              position: "bottom-right",
              duration: 3000,
              isClosable: true,
            });

            // TODO: que la api te devuelva el nuevo status y actualice la reserva
            props.addColleague(props.trainingId);
            setStatus("BOOKED");
          })
          .catch((error) => {
            toast({
              title: t(error.response.data.code),
              status: "error",
              position: "bottom-right",
              duration: 4000,
              isClosable: true,
            });
          });
        setIsLoading(false);
        break;
      case "BOOKED_NOT_REFUNDABLE":
        setIsConfirmCancellation(noRefundableMessage);
        break;
      default:
        break;
    }
  }

  function getDuration() {
    const day = new Date(props.trainingStart);
    const hours = day.getHours();

    return (new Date(props.trainingEnd).getHours() - hours) * 60;
  }

  function getStartTime() {
    const day = new Date(props.trainingStart);
    const hours = day.getHours();
    const minutes = day.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  function handleClick(e) {
    const clickedElement = e.target;

    if (
      !clickedElement.classList.contains("chakra-button") &&
      auth.isLogged()
    ) {
      onCardToggle();
    }
  }

  async function handleCancellation(value) {
    setIsConfirmCancellation(false);

    if (value) {
      setIsLoading(true);
      await axios
        .delete("/api/booking/" + props.trainingId)
        .then((response) => {
          toast({
            title: "Reserva cancelada 😭",
            position: "bottom-right",
            duration: 4000,
            isClosable: true,
          });

          props.removeColleague(props.trainingId);
          setStatus(response.data);
        })
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.response.data.message,
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        });
      setIsLoading(false);
    }
  }

  return (
    <>
      {isConfirmCancellation && (
        <ConfirmationModal
          title="Cancelación reserva"
          message={isConfirmCancellation}
          yesButton="Sí, cancelar"
          optionalButton="Volver atras"
          isDangerous
          onConfirm={handleCancellation}
        />
      )}
      <Box
        width="100%"
        bgColor="gray.800"
        color="white"
        p={5}
        textAlign="left"
        className="prevent-select"
        display={{ base: "none", sm: "none", md: "inherit" }}
      >
        <SimpleGrid columns={4} spacing={6}>
          <Box
            w="300px"
            m="auto"
            mt={{ md: -0.5, lg: -1 }}
            fontFamily={"Oswald"}
          >
            <Text
              fontSize={{ sm: "xl", md: "3xl", lg: "4xl" }}
              fontWeight={400}
            >
              {props.trainingName}
            </Text>
            <Text fontWeight={100} fontSize={{ sm: "md", md: "lg", lg: "xl" }}>
              Coach: {props.trainingCoach}
            </Text>
          </Box>
          <Box textAlign="center" m="auto" mt={-0.5} fontFamily={"Oswald"}>
            <Text fontSize={{ sm: "xs", md: "3xl", lg: "4xl" }}>
              {getStartTime()}h
            </Text>
            <Text fontWeight={100} fontSize={{ sm: "xs", md: "lg", lg: "lg" }}>
              {getDuration()} minutos
            </Text>
          </Box>
          <Box textAlign="center" m="auto" pl={"20px"} fontFamily={"Oswald"}>
            <Text fontSize={{ md: "xl", lg: "2xl" }} mt={0}>
              Capacidad: {props.trainingMaxCap - props.trainingCurrentCap}/
              {props.trainingMaxCap}
            </Text>
            <Text
              fontSize={{ base: "sm", sm: "sm", md: "2xl", lg: "3xl" }}
              mt={0}
            >
              {props.trainingLocation}
            </Text>
          </Box>
          <Stack
            textAlign="right"
            spacing={1.5}
            m="auto"
            mr={0}
            alignItems="flex-end"
          >
            {props.trainingColleagues &&
              props.trainingColleagues.length > 0 && (
                <Popover returnFocusOnClose={false}>
                  <PopoverTrigger>
                    <Button
                      size="xl"
                      variant="ghost"
                      w="fit-content"
                      _hover={{
                        bg: "transparent",
                      }}
                    >
                      <AvatarGroup
                        size={{ md: "xs", lg: "sm" }}
                        max={5}
                        textAlign={"right"}
                        justifyContent="flex-start"
                        color="gray.800"
                      >
                        {props.trainingColleagues &&
                          props.trainingColleagues.map((item) => {
                            if (item.name === "") return <Avatar />;

                            return (
                              <Avatar
                                key={item.name}
                                name={`${item.name} ${item.surname1}`.trim()}
                                src={item.avatar}
                              />
                            );
                          })}
                      </AvatarGroup>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    color="black"
                    fontFamily="system-ui"
                    width="fit-content"
                  >
                    <PopoverArrow />
                    <PopoverBody className="allow-select" cursor="auto">
                      <Stack spacing={2}>
                        {props.trainingColleagues.map((item) => {
                          if (item.name === "") {
                            return (
                              <HStack key={item.name + " " + item.surname1}>
                                <Avatar
                                  size="sm"
                                  border="2px solid gray"
                                  className="prevent-select"
                                />
                                <Text id="avatar-text">Anónimo</Text>
                              </HStack>
                            );
                          } else {
                            return (
                              <HStack key={item.name + " " + item.surname1}>
                                <Avatar
                                  size="sm"
                                  border="2px solid gray"
                                  key={item.name + " " + item.surname1}
                                  name={`${item.name} ${item.surname1}`.trim()}
                                  src={item.avatar}
                                  className="prevent-select"
                                />
                                <Text id="avatar-text">
                                  {item.name + " " + item.surname1}
                                </Text>
                              </HStack>
                            );
                          }
                        })}
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            {auth.isLogged() &&
              props.trainingColleagues &&
              props.trainingColleagues.length === 0 && (
                <Text fontFamily={"Oswald"} py={{ md: 0, lg: 1 }}>
                  Todavía sin reservas
                </Text>
              )}
            <Box>
              <Button
                bg={trainingStatus.color}
                color={"white"}
                borderRadius={0}
                _hover={{
                  bg: trainingStatus.hover,
                }}
                fontWeight={400}
                fontFamily={"Oswald"}
                minW="150x !important"
                size={auth.isLogged() ? { sm: "xs", md: "sm", lg: "md" } : "lg"}
                onClick={handleButtonClick}
                isLoading={isLoading}
                spinner={<BeatLoader size={8} color="white" />}
              >
                {trainingStatus.text}
              </Button>
            </Box>
          </Stack>
        </SimpleGrid>
      </Box>

      <Box display={{ base: "block", sm: "block", md: "none" }}>
        <Box
          width="100%"
          bgColor="gray.800"
          color="white"
          py={{ base: 3, sm: 5 }}
          px={4}
          textAlign="left"
          fontFamily={"Oswald"}
          onClick={handleClick}
          cursor={auth.isLogged() ? "pointer" : "auto"}
          className="prevent-select"
        >
          <HStack h="100%">
            <Box width="40%" h="100%">
              <Stack h="100%" spacing={0}>
                <Text fontSize={{ base: "xl", sm: "2xl" }} fontWeight={400}>
                  {props.trainingName}
                </Text>
                <HStack
                  fontWeight={100}
                  fontSize={{ sm: "md", md: "lg", lg: "lg" }}
                  pt={-5}
                  spacing={1}
                >
                  <Text display={{ base: "none", sm: "block" }}>Coach:</Text>
                  <Text ml={"0 !important"}>{props.trainingCoach}</Text>
                </HStack>
              </Stack>
            </Box>
            <Box w="60%" h="100%">
              <Stack h="100%" spacing={{ base: 1.5, sm: 2 }}>
                <Box w="100%" h="100%" /*bg="tomato"*/ textAlign={"right"}>
                  <Text fontSize={{ base: "sm", sm: "sm" }}>
                    {props.trainingLocation} - {getStartTime()}h
                  </Text>
                </Box>
                <Box
                  w="100%"
                  h="100%"
                  justifyContent="flex-end"
                  display="flex"
                  textAlign={"right"}
                >
                  <Button
                    size={{ base: "xs", sm: "sm", md: "sm", lg: "md" }}
                    bg={trainingStatus.color}
                    _hover={{
                      bg: trainingStatus.hover,
                    }}
                    color={"white"}
                    borderRadius={0}
                    minW={{ base: "70px !important", sm: "90px !important" }}
                    fontWeight={400}
                    fontFamily={"Oswald"}
                    onClick={handleButtonClick}
                    isLoading={isLoading}
                  >
                    {trainingStatus.text}
                  </Button>
                </Box>
              </Stack>
            </Box>
          </HStack>
        </Box>
        <Collapse in={isCardOpen}>
          <Flex
            pb={{ base: 1, sm: 2 }}
            pt={{ base: 3, sm: 4 }}
            px={4}
            minH="48px"
            m="auto"
          >
            <Text m="auto">
              Capacidad: {props.trainingMaxCap - props.trainingCurrentCap}/
              {props.trainingMaxCap}
            </Text>
            <Spacer />
            <Popover
              returnFocusOnClose={false}
              flip={false}
              placement="top-end"
            >
              <PopoverTrigger>
                <Button
                  size="xl"
                  variant="ghost"
                  _hover={{
                    bg: "transparent",
                  }}
                >
                  <AvatarGroup
                    size={{ base: "sm", sm: "sm" }}
                    max={8}
                    color="gray.800"
                    spacing={-2}
                  >
                    {props.trainingColleagues &&
                      props.trainingColleagues.map((item) => {
                        if (item.name === "") return <Avatar />;

                        return (
                          <Avatar
                            border="2px solid gray"
                            key={item.name + " " + item.surname1}
                            name={`${item.name} ${item.surname1}`.trim()}
                            src={item.avatar}
                          />
                        );
                      })}
                  </AvatarGroup>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                color="black"
                fontFamily="system-ui"
                width="fit-content"
              >
                <PopoverArrow />
                <PopoverBody className="allow-select" cursor="auto">
                  <Stack spacing={1.5}>
                    {props.trainingColleagues.map((item) => {
                      if (item.name === "") {
                        return (
                          <HStack key={item.name + " " + item.surname1}>
                            <Avatar
                              border="2px solid gray"
                              size="sm"
                              className="prevent-select"
                            />
                            <Text id="avatar-text">Anónimo</Text>
                          </HStack>
                        );
                      } else {
                        return (
                          <HStack key={item.name + " " + item.surname1}>
                            <Avatar
                              size="sm"
                              border="2px solid gray"
                              key={item.name + " " + item.surname1}
                              name={`${item.name} ${item.surname1}`.trim()}
                              src={item.avatar}
                              className="prevent-select"
                            />
                            <Text id="avatar-text">
                              {item.name + " " + item.surname1}
                            </Text>
                          </HStack>
                        );
                      }
                    })}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            {auth.isLogged() &&
              props.trainingColleagues &&
              props.trainingColleagues.length === 0 && (
                <Text m="auto">Sin reservas</Text>
              )}
          </Flex>
        </Collapse>
      </Box>
    </>
  );
}
