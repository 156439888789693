import {
  Box,
  HStack,
  Text,
  TabPanel,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { CoachStats } from "../CoachStats";
import { MdOutlineBook, MdOutlineQueryStats } from "react-icons/md";
import { CoachNextTrainings } from "../CoachNextTrainings";
//import { HiOutlineRefresh } from "react-icons/hi";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";

export function AdminCoaches({ coachesList }) {
  const [coach, setCoach] = useState(0);

  return (
    <>
      <Box
        width={{ base: "100%", sm: "100%", md: "80%" }}
        margin="auto"
        maxW="1300px"
      >
        <HStack justifyContent="space-between" mb={-1} mx={{ md: -4 }}>
          <Button
            id="prev"
            background="transparent"
            _hover={{ bg: "transparent" }}
            isDisabled={coach === 0}
            onClick={() => setCoach(coach - 1)}
            aria-label="Semana anterior"
          >
            <GoChevronLeft />
          </Button>
          <Menu placement="bottom">
            <MenuButton size="sm" mr={-1}>
              {coachesList[coach].coachName + " "}
              <ChevronDownIcon />
            </MenuButton>
            <MenuList borderRadius={0} minW="80px">
              {coachesList.map((coach, index) => (
                <MenuItem onClick={() => setCoach(index)}>
                  {coach.coachName}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          <Button
            id="next"
            isDisabled={coach === coachesList.length - 1}
            onClick={() => setCoach(coach + 1)}
            background="transparent"
            _hover={{ bg: "transparent" }}
            aria-label="Semana siguiente"
          >
            <GoChevronRight />
          </Button>
        </HStack>

        <Tabs isFitted isLazy lazyBehavior="keepMounted" mt={1}>
          <TabList width={"100%"} maxW="1300px" m="auto">
            <Tab>
              <MdOutlineQueryStats />
              <Text display={{ base: "none", sm: "none", md: "flex" }}>
                &nbsp;Estadísticas
              </Text>
            </Tab>
            <Tab>
              <MdOutlineBook />
              <Text display={{ base: "none", sm: "none", md: "flex" }}>
                &nbsp;Reservas
              </Text>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel className="p0-tab-panel pb0-tab-panel ">
              <CoachStats
                coachId={coachesList[coach].coachId}
                coachPayRate={coachesList[coach].payRate}
              />
            </TabPanel>
            <TabPanel className="p0-tab-panel pb0-tab-panel">
              <CoachNextTrainings coachId={coachesList[coach].coachId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
