import * as React from "react";
import { CoachNextTrainings } from "../components/CoachNextTrainings";
import {
  ChakraProvider,
  Box,
  Heading,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Alert,
  AlertIcon,
  Stack,
} from "@chakra-ui/react";
import {
  MdOutlineCalendarMonth,
  MdOutlineBook,
  MdOutlineQueryStats,
} from "react-icons/md";
import { CoachStats } from "../components/CoachStats";
import { CoachScheduler } from "../components/CoachScheduler";
import { Helmet } from "react-helmet-async";

export function Coach() {
  return (
    <>
      <Helmet>
        <title>Entrenador - CUC Fitness Studio</title>
        <meta
          name="description"
          content="Área de entrenador de CUC Fitness Studio."
        />
      </Helmet>
      <ChakraProvider>
        <Box textAlign="justify" margin={"auto"} pt="100px" maxWidth={"800px"}>
          <Heading
            fontFamily={"Oswald"}
            fontWeight={400}
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color={"gray.800"}
            p={6}
            pt={4}
            textAlign={"center"}
          >
            Área
            <Text as={"span"} color={"blue.500"}>
              &nbsp;entrenador
            </Text>
          </Heading>
        </Box>
        <Box>
          <Tabs isFitted isLazy lazyBehavior="keepMounted">
            <TabList
              width={{ base: "100%", sm: "100%", md: "80%" }}
              maxW="1300px"
              m="auto"
            >
              <Tab>
                <MdOutlineQueryStats />
                <Text display={{ base: "none", sm: "none", md: "flex" }}>
                  &nbsp;Estadísticas
                </Text>
              </Tab>
              {process.env.REACT_APP_ENV === "LOCAL" && (
                <Tab>
                  <MdOutlineCalendarMonth />
                  <Text display={{ base: "none", sm: "none", md: "flex" }}>
                    &nbsp;Calendario
                  </Text>
                </Tab>
              )}
              <Tab>
                <MdOutlineBook />
                <Text display={{ base: "none", sm: "none", md: "flex" }}>
                  &nbsp;Reservas
                </Text>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel className="p0-tab-panel pb0-tab-panel ">
                <CoachStats />
              </TabPanel>
              {process.env.REACT_APP_ENV === "LOCAL" && (
                <TabPanel className="p0-tab-panel pb0-tab-panel">
                  <Stack
                    spacing={2}
                    width={{ sm: "100%", md: "80%" }}
                    margin="auto"
                    maxW="1300px"
                  >
                    <Alert status="warning" textAlign="left">
                      <AlertIcon />
                      El calendario todavía no está disponible. Estamos
                      trabajando en ello...
                    </Alert>
                  </Stack>

                  <Box pt={4} m="auto">
                    <CoachScheduler />
                  </Box>
                </TabPanel>
              )}
              <TabPanel className="p0-tab-panel pb0-tab-panel">
                <CoachNextTrainings />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        {/*<Box py={4} h="fit-content">
                        <NextTrainings />
              </Box>*/}
      </ChakraProvider>
    </>
  );
}

export default Coach;
