import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  HStack,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  AbsoluteCenter,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
//import { NavLink as ReachLink } from "react-router-dom";
import { HashLink as ReachHashLink } from "react-router-hash-link"; // sorts out problem with navliink
import { useLocation } from "react-router-dom";
import Logo from "../../static/CUC_LOGO.svg";
import { LoginMenu } from "./LoginMenu/LoginMenu";
import React, { useState } from "react";
import { UserMenu } from "./UserMenu/UserMenu";
import "./style.css";
import { useAuth } from "../../auth/useAuth";
import { useNavigate } from "react-router-dom";
import FacebookLogo from "../../images/footer/facebook.svg";
import InstagramLogo from "../../images/footer/instagram.svg";
import WhatsappLogo from "../../images/footer/whatsapp.svg";
import { FaArrowLeft } from "react-icons/fa6";
import Cookies from "js-cookie";
import { MdOutlineLocalFireDepartment } from "react-icons/md";

export function Navbar({ isOpaque }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [opaque, setOpaque] = useState(true);
  const opaqueMandatory = isOpaque == null ? false : isOpaque;
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  function handleLogout() {
    auth.logout();
    if (Cookies.get("AppView")) {
      navigate("/login");
    } else {
      navigate("/");
    }
  }

  function goBack() {
    if (location.pathname !== "/") {
      navigate("/");
    } else {
      navigate("bonos");
    }
  }

  // Efecto navbar transparente
  onscroll = (event) => {
    window.scrollY > 85 ? setOpaque(false) : setOpaque(true);
  };

  function onCloseDelay() {
    onClose();
  }

  const DesktopNav = ({ onAction }) => {
    const linkColor = "gray.200";
    const linkHoverColor = "white";
    const popoverContentBgColor = "white";

    return (
      <Stack direction={"row"} spacing={4} pt={3} px={1}>
        {NAV_ITEMS.map(
          (navItem) =>
            (navItem.loggedMenu === auth.isLogged() || !auth.isLogged()) && (
              <Box key={navItem.label}>
                <Popover
                  trigger={"hover"}
                  placement={"bottom-start"}
                  bgColor="black"
                >
                  <PopoverTrigger>
                    <Text
                      p={2}
                      as={ReachHashLink}
                      to={navItem.href}
                      fontSize={"md"}
                      fontWeight={500}
                      color={linkColor}
                      _hover={{
                        textDecoration: "none",
                        color: linkHoverColor,
                      }}
                    >
                      {navItem.label}
                    </Text>
                  </PopoverTrigger>

                  {navItem.children && (
                    <PopoverContent
                      boxShadow={"xl"}
                      bg={popoverContentBgColor}
                      p={4}
                      rounded={"md"}
                      minW={"sm"}
                      textAlign="left"
                    >
                      <Stack textAlign={"center"}>
                        {navItem.children.map((child) => (
                          <DesktopSubNav key={child.label} {...child} />
                        ))}
                      </Stack>
                    </PopoverContent>
                  )}
                </Popover>
              </Box>
            )
        )}
      </Stack>
    );
  };

  const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
      <Link
        bgColor="white"
        color="white"
        as={ReachHashLink}
        to={href}
        role={"group"}
        display={"block"}
        rounded={"sm"}
        p={0.5}
      >
        <Stack direction={"row"} bgColor="white">
          <Box color="gray.800" align={"flex-start"}>
            <Text
              transition={"all 0.3s ease"}
              _groupHover={{ color: "blue.500" }} /* on click subnav */
              fontWeight={500}
            >
              {label}
            </Text>
            <Text fontSize={"sm"}>{subLabel}</Text>
          </Box>
          <Flex
            transition={"all .3s ease"} /* transición cajita y azul */
            transform={"translateX(-10px)"}
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify={"flex-end"}
            align={"left"}
            flex={1}
          >
            <Icon color={"blue.500"} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    );
  };

  const MobileNav = ({ onAction }) => {
    return (
      <Stack className="fade-in">
        {NAV_ITEMS.map(
          (navItem) =>
            (navItem.loggedMenu === auth.isLogged() || !auth.isLogged()) && (
              <MobileNavItem
                onAction={onAction}
                key={navItem.label}
                {...navItem}
              />
            )
        )}
      </Stack>
    );
  };

  const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();

    function clicked(e) {
      onCloseDelay();
    }

    return (
      <Stack spacing={2} ml={-2} onClick={children && onToggle}>
        <Link
          py={0.5}
          as={ReachHashLink}
          to={href ?? "#"}
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
          onClick={clicked}
        >
          <Text
            fontWeight={300}
            color="white"
            fontSize={"4xl"}
            textAlign="center"
            id={label}
          >
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={"all 0.3s ease-in-out"} /* Transición icono submenú */
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </Link>

        <Collapse in={isOpen} mb={5}>
          <Stack
            mb={4}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor="gray.800"
            align={"start"}
          >
            {children &&
              children.map((child) => (
                <Link
                  key={child.label}
                  py={0}
                  as={ReachHashLink}
                  to={child.href}
                  color="gray.800"
                >
                  {child.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
  }

  const NAV_ITEMS: Array<NavItem> = [
    {
      label: "Home",
      href: "/#",
      loggedMenu: true,
    },
    {
      label: "Servicios",
      href: "/#services",
      loggedMenu: false,
    },
    {
      label: "Horarios",
      href: "/#schedule",
      loggedMenu: true,
    },
    {
      label: "Tarifas",
      href: "/#bonos",
      loggedMenu: true,
    },
    {
      label: "Redes",
      href: "/#social",
      loggedMenu: false,
    },
    {
      label: "Equipo",
      href: "/#team",
      loggedMenu: false,
    },
    {
      label: "FAQs",
      href: "/#faqs",
      loggedMenu: false,
    },
  ];

  return (
    <Box>
      <Flex
        position={"fixed"}
        width={"100%"}
        backgroundColor={
          opaqueMandatory || !opaque || isOpen ? "gray.900" : "transparent"
        }
        style={{ transition: "background-color 0.2s ease-in-out" }}
        color="white"
        minH={"80px"}
        py={{ base: 3 }}
        px={{ base: 4 }}
        align={"center"}
        zIndex={11}
      >
        <Flex
          flex={auth.isLogged() ? 1 : { base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={auth.isLogged() ? "flex" : { base: "flex", md: "none" }}
        >
          <IconButton
            onClick={auth.isLogged() ? goBack : onOpen}
            color="white"
            ml={2}
            icon={
              auth.isLogged() ? (
                location.pathname !== "/" ? (
                  <FaArrowLeft size="1.25em" />
                ) : (
                  <MdOutlineLocalFireDepartment size="1.5em" />
                )
              ) : (
                !isOpen && <HamburgerIcon w={5} h={5} />
              )
            }
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            aria-label={"Toggle Navigation"}
          />
        </Flex>

        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", md: "start" }}
          py={1}
          className="oswald-font"
        >
          <Link as={ReachHashLink} to={"/"}>
            <img src={Logo} width="70px" height="50px" alt="CUC logo" />
          </Link>

          <Flex
            display={auth.isLogged() ? "none" : { base: "none", md: "flex" }}
            ml={8}
          >
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Stack direction={"row"} spacing={7}>
            {auth.isLogged() && (
              <UserMenu onAction={handleLogout} user={auth.user} />
            )}
            {!auth.isLogged() && <LoginMenu />}
          </Stack>
        </Stack>
      </Flex>

      <Drawer
        placement={"left"}
        onClose={onClose}
        isOpen={isOpen}
        size="full"
        autoFocus={false}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />

        <DrawerContent
          color="white"
          bgColor="gray.900"
          fontFamily="Oswald"
          fontWeight={200}
        >
          <DrawerHeader bgColor="gray.900">
            <HStack h="48px" ml={-4} mr="40px">
              <IconButton
                backgroundColor="transparent"
                _hover={{ backgroundColor: "transparent" }}
                onClick={onClose}
                color="white"
              >
                <CloseIcon w={3} h={3} />
              </IconButton>
              <Flex
                flex={{ base: 1 }}
                justify={{ base: "center", md: "start" }}
                className="oswald-font"
              >
                <Link as={ReachHashLink} to="/" onClick={onCloseDelay}>
                  <img src={Logo} width="70px" height="50px" alt="CUC logo" />
                </Link>
              </Flex>
            </HStack>
          </DrawerHeader>
          <DrawerBody m="auto" /*ml={{base: "30px", sm:"90px", md: "200px"}}*/>
            <AbsoluteCenter>
              <MobileNav onAction={onCloseDelay} />
              <HStack pt={12} spacing={7}>
                <a href="https://wa.me/34674088825">
                  <img src={WhatsappLogo} width="40px" alt="Whatsapp logo" />
                </a>
                <a href="https://www.facebook.com/cucfitness/">
                  <img src={FacebookLogo} width="40px" alt="Facebook logo" />
                </a>
                <a href="https://www.instagram.com/cucfitness/">
                  <img src={InstagramLogo} width="40px" alt="Instagram logo" />
                </a>
              </HStack>
            </AbsoluteCenter>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
