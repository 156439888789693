import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Home } from "./pages/home";
import { ActivateAccount } from "./pages/activate-account";
import { ResetPassword } from "./pages/reset-password";
import { Admin } from "./pages/admin";
import { Coach } from "./pages/coach";
import { Activity } from "./pages/activity";
import { AuthProvider } from "./auth/AuthProvider";
import { PrivateRoute } from "./PrivateRoute";
import { Profile } from "./pages/profile";
import { Careers } from "./pages/careers";
import { About } from "./pages/about";
import { Cookies } from "./pages/cookies";
import { Privacy } from "./pages/privacy";
import { Terms } from "./pages/terms";
import { NotFound } from "./pages/not-found";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MainLayout } from "./layout/MainLayout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AppLogin } from "./components/AppLogin";
import { ProtectedRoute } from "./ProtectedRoute";
import ScrollToTop from "./components/ScrollToTop";
import "./config/i18n";
import "./interceptors/axios";
import "./index.css";
import { Bonos } from "./components/Bonos";
import { HelmetProvider } from "react-helmet-async";

const ROLES = {
  USER_ROLE: "USER_ROLE",
  COACH_ROLE: "COACH_ROLE",
  ADMIN_ROLE: "ADMIN_ROLE",
};

const isDarkMode =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;
console.log("Dark mode", isDarkMode);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <GoogleOAuthProvider clientId="910561076464-8pi9m44vots8t9jf65no1k4p33ghif65.apps.googleusercontent.com">
          <AuthProvider>
            <Router>
              <ScrollToTop>
                <Routes>
                  {/* Routes with Main Layout */}
                  <Route path="/" element={<MainLayout />}>
                    <Route index element={<Home />} />

                    <Route
                      element={
                        <PrivateRoute allowedRoles={[ROLES.USER_ROLE]} />
                      }
                    >
                      <Route path="activity" element={<Activity />} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="bonos" element={<Bonos />} />
                    </Route>

                    <Route
                      element={
                        <PrivateRoute allowedRoles={[ROLES.COACH_ROLE]} />
                      }
                    >
                      <Route path="coach" element={<Coach />} />
                    </Route>

                    <Route
                      element={
                        <PrivateRoute allowedRoles={[ROLES.ADMIN_ROLE]} />
                      }
                    >
                      <Route path="admin" element={<Admin />} />
                    </Route>

                    <Route path="careers" element={<Careers />} />
                    <Route path="about" element={<About />} />
                    <Route path="cookies" element={<Cookies />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="terms" element={<Terms />} />

                    <Route path="/offline" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>

                  {/* Routes without Main Layout */}
                  <Route
                    path="/activate/:token"
                    element={<ActivateAccount />}
                  />
                  <Route path="/reset/:token" element={<ResetPassword />} />

                  {/* Route that will not load if already logged */}
                  <Route element={<ProtectedRoute />}>
                    <Route path="/login" element={<AppLogin />} />
                  </Route>
                </Routes>
              </ScrollToTop>
            </Router>
          </AuthProvider>
        </GoogleOAuthProvider>
      </React.StrictMode>
    </QueryClientProvider>
  </HelmetProvider>
);

// <Route path="/admin" /* This will have to be a Private Route */ element={<Admin />} />
