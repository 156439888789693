import { BonoActivity } from "../../Activity/UserBonos/BonoActivityCard";
import React from "react";
import { Box, SimpleGrid, Skeleton, Text } from "@chakra-ui/react";
//import { MdOutlineBookmarkAdd } from "react-icons/md";
import axios from "axios";
import { useEffect, useState } from "react";

export function UserBonos(props) {
  const [bonos, setBonos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      setBonos(null);
      setLoading(true);

      const { data } = await axios.get("/api/admin/bonos/" + props.userId);
      setBonos(data);
      
      setLoading(false);
    })();
  }, [props.userId, refresh]);

  return (
    <>
      <Box w="100%" m="auto" background="#EDF2F7">
        {/*<Button
          title="Añadir nuevo bono"
          variant="ghost"
          size={{ base: "sm", sm: "sm", md: "sm" }}
          fontWeight={400}
          background-color="#EDF2F7"
        >
          Añadir bono&nbsp;
          <MdOutlineBookmarkAdd size="1.25em" />
  </Button>*/}
      </Box>
      {!loading && bonos && bonos.length === 0  && (
        <Text mt={5} mb={4}>No existen bonos para este usuario</Text>
      )}
      <SimpleGrid
        w="100%"
        m="auto"
        spacing={2}
        columns={{ md: 1, lg: 1, xl: 2 }}
      >
        {bonos &&
          bonos.map((bono) => (
            <BonoActivity
              key={bono.bonoId}
              bonoId={bono.bonoId}
              bonoName={bono.bonoTypeName}
              bonoImage={bono.bonoTypeImage}
              bonoStatus={bono.bonoStatus}
              bonoSessions={bono.remainingSessions}
              bonoCreationDate={bono.creationDate}
              bonoActivationDate={bono.activationDate}
              bonoExpirationDate={bono.expirationDate}
              onAction={() => setRefresh(!refresh)}
            />
          ))}

        {(!bonos || loading) && (
          <>
            <Skeleton h="145px" />
            <Skeleton h="145px" />
          </>
        )}
      </SimpleGrid>
    </>
  );
}
