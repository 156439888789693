import * as React from "react";
import { ChakraProvider, Box, Heading, Text, Link } from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export function Privacy() {
  return (
    <>
      <Helmet>
        <title>Política de privacidad - CUC Fitness Studio</title>
        <meta
          name="description"
          content="Política de privacidad de CUC Fitness Studio."
        />
      </Helmet>
      <ChakraProvider>
        <Box
          pt={"100px"}
          pb={8}
          textAlign="justify"
          margin={"auto"}
          width="80%"
          maxWidth={"1100px"}
        >
          <Heading
            fontFamily={"Oswald"}
            fontWeight={500}
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color={"gray.800"}
            textAlign={"center"}
            mt={"20px"}
            mb={2}
          >
            Política de{" "}
            <Text as={"span"} color={"blue.500"}>
              privacidad
            </Text>
          </Heading>
          <Box mt={3}>
            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={5}
            >
              INFORMACIÓN AL USUARIO
            </Text>
            <Text mb={2}>
              CUC Fitness Studio, como Responsable del Tratamiento, le informa
              que, según lo dispuesto en el Reglamento (UE) 2016/679, de 27 de
              abril, (RGPD) y en la L.O. 3/2018, de 5 de diciembre, de
              protección de datos y garantía de los derechos digitales
              (LOPDGDD), trataremos su datos tal y como reflejamos en la
              presente Política de Privacidad.
            </Text>
            <Text mb={2}>
              En esta Política de Privacidad describimos cómo recogemos sus
              datos personales y por qué los recogemos, qué hacemos con ellos,
              con quién los compartimos, cómo los protegemos y sus opciones en
              cuanto al tratamiento de sus datos personales. Los datos
              personales son recogidos por la empresa para la prestación de sus
              servicios. Si acepta las medidas de esta Política, acepta que
              tratemos sus datos personales como en ella se definen.
            </Text>
            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={7}
            >
              RECOGIDA Y TRATAMIENTO DE SUS DATOS PERSONALES
            </Text>
            <Text mb={2}>
              Recibimos, recopilamos y almacenamos cualquier información que
              ingrese en nuestro sitio web o nos proporciones de otra manera.
              Además, recogemos datos de inicio de sesión, dirección de correo
              electrónico, contraseña, información de la computadora y la
              conexión. Podemos usar herramientas de software para medir y
              recopilar información de la sesión, incluidos los tiempos de
              respuesta de la página, la duración de las visitas a determinadas
              páginas, la información de interacción de la página y los métodos
              utilizados para navegar fuera de la página. También recopilamos
              información de identificación personal (incluido nombres, correo
              electrónico, contraseña, comunicaciones), detalles de pago
              (incluida la información de la tarjeta de crédito), comentarios,
              sugerencias, reseñas de productos, recomendaciones y perfil
              personal.
            </Text>
            <Text mb={2}>
              Cuando realizas una transacción en nuestra página web, como parte
              del proceso, recopilamos información personal que tu mismo nos
              proporcionas, como tu nombre, dirección física y dirección de
              correo electrónico. Tu información personal será utilizada solo
              para los motivos específicos indicados anteriormente.
            </Text>
            <Text mb={2}>
              Recopilamos dicha información personal y no personal para los
              siguientes propósitos:
            </Text>
            <Box ml={"40px"} mb={4}>
              <ul>
                <li>- Para proporacionar y operar los servicios.</li>
                <li>
                  - Para proporcionar a nuestros usuarios asistencia continua y
                  soporte técnico.
                </li>
                <li>
                  - Para poder contactar a nuestros visitantes y usuarios con
                  avisos generales y personalizados relacionados con el servicio
                  y mensajes promocionales.
                </li>
                <li>
                  - Para crear datos estadísticos agregados y otra información
                  no personal agregada o deducida, que nosotros o nuestros
                  socios comerciales podamos utilizar para proporcionar y
                  mejorar nuestros servicios respectivos.
                </li>
                <li>- Para cumplir con las leyes y regulaciones aplicables.</li>
              </ul>
            </Box>
            <Text mb={2}>
              Podemos comunicarnos contigo para notificarte sobre tu cuenta,
              para solucionar problemas con tu cuenta, resolver una disputa,
              cobrar tarifas o dinero adeudado, para sondear tus opiniones a
              través de encuestas o cuestionarios, para enviar actualizaciones
              sobre nuestra empresa, o cuando sea necesario para contactarte
              para hacer cumplir nuestras{" "}
              <Link
                as={ReachLink}
                to="/terms"
                color="blue.400"
                fontWeight={"bold"}
              >
                Condiciones generales
              </Link>
              , las leyes nacionales aplicables y cualquier acuerdo que podamos
              tener contigo. A estos efectos, podemos comunicarnos contigo por
              correo electrónico, teléfono, mensajes de texto y correo postal.
            </Text>
            <Text mb={2}>
              Si no deseas que procesemos tus datos, contáctanos a
              cucfitness@gmail.com.
            </Text>
            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={7}
            >
              REDES SOCIALES
            </Text>
            <Text mb={2}>
              Al aceptar la Política de Privacidad autorizas a CUC Fitness
              Studio a realizar fotografías y grabaciones de vídeo y publicación
              de las mismas en los siguientes medios:
            </Text>
            <Box ml={"40px"} mb={4}>
              <ul>
                <li>- Página web.</li>
                <li>- Perfiles en redes sociales de la empresa.</li>
                <li>
                  - Filmaciones varias destinadas a la difusión comercial.
                </li>
                <li>
                  - Fotografías para revistas o publicaciones de ámbito
                  relacioando con nuestro sector.
                </li>
              </ul>
            </Box>
            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={7}
            >
              INFORMACIÓN LEGAL
            </Text>
            <Text mb={2}>
              Nos reservamos el derecho de modificar esta Política en cualquier
              momento, por lo tanto, revísala con frecuencia. Los cambios y
              aclaraciones tendrán efecto inmediatamente después de su
              publicación en la página web. Si realizamos cambios sustanciales a
              esta política, te notificaremos que se ha actualizado, para que
              sepas qué información recopilamos, cómo la usamos y en qué
              circunstancias, si corresponde, usamos o divulgamos la
              información.
            </Text>
            <Text mb={2}>
              Si deseas: acceder, corregir, modificar o eliminar cualquier
              información personal que tengamos sobre ti, puedes contactarnos en
              cucfitness@gmail.com.
            </Text>
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Privacy;
