import { Text, Skeleton, Stack, Box, Button, HStack } from "@chakra-ui/react";
import { CoachNextCard } from "./CoachNextCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { MdChecklist } from "react-icons/md";
import { HiOutlineRefresh } from "react-icons/hi";

export function CoachNextTrainings({ coachId }) {
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    let baseUrl = "/api/coach/bookings";

    if (coachId) {
      baseUrl = "/api/admin/bookings/coach/" + coachId;
    }

    (async () => {
      setData(null);
      await axios.get(baseUrl).then((response) => {
        setData(response.data);
      });
    })();
  }, [refresh, coachId]);

  return (
    <>
      <Stack
        spacing={2}
        width={coachId ? "100%" : { sm: "100%", md: "80%" }}
        margin="auto"
        maxW="1300px"
        mb={{ base: 0, sm: 0, md: 4 }}
      >
        <Box w={{ base: "90%", sm: "90%%", md: "100%" }} m="auto">
          <HStack pb={2} display="flex" justifyContent="space-between">
            <HStack fontSize={{ base: "xs", sm: "md" }} mt={0.5}>
              <MdChecklist size="1.25em" />
              <Text fontWeight={400}>REVISAR ASISTENCIA</Text>
            </HStack>

            <HStack spacing={-3} mr={-3.5}>
              <Button
                title="Guardar eventos"
                variant="ghost"
                colorScheme="black"
                onClick={() => setRefresh(!refresh)}
                isDisabled={data === null}
                size={{ base: "sm", sm: "sm", md: "md" }}
                /* Save events button */
              >
                <HiOutlineRefresh size="1.25em" />
              </Button>
            </HStack>
          </HStack>
        </Box>
        {data &&
          data.map((item) => {
            return (
              <CoachNextCard
                key={item.trainingId}
                trainingId={item.trainingId}
                trainingName={item.trainingName}
                trainingLocation={item.location}
                trainingStartDate={item.startDate}
                trainingEndDate={item.endDate}
                trainingClients={item.participants}
              />
            );
          })}

        {data && data.length === 0 && (
          <Box
            h={{ base: "78px", sm: "104px", md: "110px", lg: "121px" }}
            border="2px solid #EDF2F7"
            display="flex"
          >
            <Stack m="auto">
              <Text fontSize={{ base: "lg", sm: "lg", md: "xl", lg: "xl" }}>
                {coachId
                  ? "No tiene entrenamientos asignados"
                  : "No tienes entrenamientos asignados"}
              </Text>
            </Stack>
          </Box>
        )}

        {!data && (
          <>
            <Skeleton
              h={{
                base: "78px",
                sm: "100px",
                md: "112px",
                lg: "121px",
                xl: "121px",
              }}
              borderRadius={0}
            />
          </>
        )}
      </Stack>
    </>
  );
}
