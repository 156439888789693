import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Flex,
  Image,
  useDisclosure,
  Collapse,
  SimpleGrid,
  HStack,
  Center,
} from "@chakra-ui/react";
import "./style.css";
import FisioFoto from "../../images/services/fisio2-min.webp";
//import NutriFoto from "../../images/services/nutri1.jpg";
import NutriFoto from "../../images/services/asesoramiento-min.webp";
//import EntrenoFoto from "../../images/services/entreno2.jpg";
//import EntrenoFoto from "../../images/services/cuc_main_room.jpg";
import EntrenoFoto from "../../images/services/mancuernas-min.webp";
import ADULTOS_IMG from "../../images/services/icons/adultos.webp";
import FAMILIA_IMG from "../../images/services/icons/familiar.webp";
import NINOS_IMG from "../../images/services/icons/niños.webp";

const entrenamientos = [
  {
    trainingName: "FUNCIONAL",
    img: "https://res.cloudinary.com/cuc-fitness-pro/image/upload/v1683661754/cuc_out_xxtrxj.svg",
    description:
      "Entrenamiento funcional adaptado e individualizado (máx. 8 personas)",
  },
  /*{
    trainingName: "HYROX",
    img: "https://res.cloudinary.com/cuc-fitness-pro/image/upload/v1715728966/training-types/HYROX-min_lqk2yp.webp",
    description: "Entrenamiento funcional de alta intensidad (máx. 8 personas)",
  },*/
  {
    trainingName: "ESPALDA SANA",
    img: "https://res.cloudinary.com/cuc-fitness-pro/image/upload/v1716987520/espalda-sana_m0gpth.svg",
    description:
      "Entrenamiento enfocado a mejorar la postura corporal (máx. 8 personas)",
  },
  /*{
    trainingName: "STRONG RACE",
    img: "https://res.cloudinary.com/cuc-fitness-pro/image/upload/v1716987520/strong-race_sdptko.svg",
    description:
      "Entrenamiento preaparatorio para la STRONG RACE (máx. 8 personas)",
  },*/
  {
    trainingName: "STRETCH",
    img: "https://res.cloudinary.com/cuc-fitness-pro/image/upload/v1683661754/stretch_izsvad.svg",
    description:
      "Entrenamiento de estiramientos y flexibilidad (máx. 12 personas)",
  },
  {
    trainingName: "YOGA",
    img: "https://res.cloudinary.com/cuc-fitness-pro/image/upload/v1683661756/pilates_clh06e.svg",
    description:
      "Clases de yoga convencional: meditación y flexibilidad (máx. 8 personas)",
  },
  {
    trainingName: "YOGA RESTAURATIVO",
    img: "https://res.cloudinary.com/cuc-fitness-pro/image/upload/v1716987579/rest-yoga_tqixly.svg",
    description:
      "Clases de yoga restaurativo: relajación profunda (máx. 8 personas)",
  },
];

export function ServicesOffered() {
  const { isOpen: isTrainingsOpen, onToggle: onTrainingsToggle } =
    useDisclosure();
  const { isOpen: isNutriOpen, onToggle: onNutriToggle } = useDisclosure();

  return (
    <>
      <Box>
        <Heading
          fontFamily={"Oswald"}
          fontWeight={400}
          fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
          lineHeight={"110%"}
          color={"gray.800"}
          my={8}
        >
          Nuestros&nbsp;
          <Text as={"span"} color={"blue.500"}>
            servicios
          </Text>
        </Heading>
      </Box>
      <Center width="100%" bgColor="gray.800" border="white">
        <Box fontFamily="Oswald" fontWeight="200">
          <Box bgColor="gray.800" width="100%">
            <Stack direction={{ base: "column-reverse", md: "row" }} gap={0}>
              <Flex
                flex={1}
                textAlign={"left"}
                align="center"
                justify={"center"}
              >
                <Stack
                  p={{ base: 10, sm: 8, md: 10, lg: 10, "2xl": 0 }}
                  spacing={5}
                  maxW={"2xl"}
                >
                  <Heading
                    fontSize={{ base: "xl", sm: "xl", md: "2xl" }}
                    color="blue.500"
                  >
                    ENTRENAMIENTOS
                  </Heading>
                  <Text color="white" fontSize="lg">
                    Proporcionamos un servicio exclusivo y personalizado para
                    cada tipo de cliente y con entrenadores altamente
                    cualificados.
                  </Text>
                  <Button
                    className="cuc-section-btn"
                    bg={"blue.400"}
                    color={"white"}
                    mt={{ base: "4vw", sm: "3vw", md: "3vw", lg: "2vw" }}
                    borderRadius={0}
                    _hover={{
                      bg: "blue.600",
                    }}
                    onClick={onTrainingsToggle}
                    fontWeight={400}
                    width="240px"
                  >
                    Tipos de entrenamiento
                  </Button>
                </Stack>
              </Flex>
              <Flex flex={1} h={"350px"} className="marginLeft">
                <Image
                  alt={"Mancuernas"}
                  objectFit={"cover"}
                  width="100%"
                  height="100%"
                  src={EntrenoFoto}
                />
              </Flex>
            </Stack>

            <Collapse in={isTrainingsOpen}>
              <SimpleGrid
                columns={{ base: 1, sm: 1, md: 1, lg: 2 }}
                py={5}
                bgColor="white"
                spacingX="80px"
                px={{
                  base: "20px",
                  sm: "30px",
                  md: 10,
                  lg: 20,
                  xl: 20,
                  "2xl": "250px",
                }}
              >
                {entrenamientos.map((item) => (
                  <HStack p={2} key={item.trainingName} spacing={5} h="120px">
                    <Stack w="80px;">
                      <Image src={item.img} />
                    </Stack>
                    <Stack textAlign="left" width="100%">
                      <Heading
                        fontSize={{ base: "xl", sm: "xl", md: "2xl" }}
                        color="blue.500"
                      >
                        {item.trainingName}
                      </Heading>
                      <Text fontSize="lg">{item.description}</Text>
                    </Stack>
                  </HStack>
                ))}
              </SimpleGrid>
            </Collapse>

            <Stack direction={{ base: "column", md: "row" }} gap={0}>
              <Flex flex={1} h="350px">
                <Image
                  alt={"Nutrición"}
                  objectFit={"cover"}
                  width="100%"
                  height="100%"
                  src={NutriFoto}
                />
              </Flex>
              <Flex
                flex={1}
                textAlign={"left"}
                align="center"
                justify={"center"}
                className="marginLeft"
              >
                <Stack
                  p={{ base: 10, sm: 8, md: 10, lg: 10, "2xl": 0 }}
                  spacing={5}
                  maxW={"2xl"}
                >
                  <Heading
                    fontSize={{ base: "xl", sm: "xl", md: "2xl" }}
                    color="blue.500"
                  >
                    NUTRICIÓN
                  </Heading>
                  <Text color="white" fontSize="lg">
                    Analizamos tus necesidades nutricionales para establecer un
                    plan de alimentación personalizado que permita alcanzar tus
                    objetivos de forma realista y duradera.
                  </Text>
                  <Button
                    className="cuc-section-btn"
                    bg={"blue.400"}
                    color={"white"}
                    mt={{ base: "4vw", sm: "3vw", md: "3vw", lg: "2vw" }}
                    borderRadius={0}
                    _hover={{
                      bg: "blue.600",
                    }}
                    onClick={onNutriToggle}
                    fontWeight={400}
                    width="240px"
                  >
                    Tipos de asesoramiento
                  </Button>
                </Stack>
              </Flex>
            </Stack>

            <Collapse in={isNutriOpen}>
              <Box
                bgColor="white"
                px={{
                  base: "20px",
                  sm: "30px",
                  md: 10,
                  lg: 20,
                  xl: 20,
                  "2xl": "250px",
                }}
                py={6}
              >
                <HStack p={3} spacing={5}>
                  <Stack w="80px;">
                    <Image src={FAMILIA_IMG} />
                  </Stack>
                  <Stack textAlign="left" width="100%">
                    <Heading
                      fontSize={{ base: "xl", sm: "xl", md: "2xl" }}
                      color="blue.500"
                    >
                      FAMILIAR
                    </Heading>
                    <Text fontSize="lg" textAlign={"justify"}>
                      Realización de planificaciones de comidas, ingestas y
                      aprender a hacer recetas.
                    </Text>
                  </Stack>
                </HStack>
                <HStack p={3} spacing={5}>
                  <Stack w="80px;">
                    <Image src={NINOS_IMG} />
                  </Stack>
                  <Stack textAlign="left" width="100%">
                    <Heading
                      fontSize={{ base: "xl", sm: "xl", md: "2xl" }}
                      color="blue.500"
                    >
                      NIÑOS
                    </Heading>
                    <Text fontSize="lg" textAlign={"left"}>
                      Enseñar hábitos de alimentación saludables con el objetivo
                      de evitar enfermedades como la obesidad, diabetes o
                      hipertensión.
                    </Text>
                  </Stack>
                </HStack>
                <HStack p={3} spacing={5}>
                  <Stack w="80px;">
                    <Image src={ADULTOS_IMG} />
                  </Stack>
                  <Stack textAlign="left" width="100%">
                    <Heading
                      fontSize={{ base: "xl", sm: "xl", md: "2xl" }}
                      color="blue.500"
                    >
                      ADULTOS
                    </Heading>
                    <Text fontSize="lg" textAlign={"left"}>
                      Dirigido a personas que queiren cambiar o mejorar su
                      alimentación, ya sea por motivos de salud, objetivos
                      físicos, alergias o intolerancias.
                    </Text>
                  </Stack>
                </HStack>
              </Box>
            </Collapse>

            <Stack
              direction={{ base: "column-reverse", md: "row" }}
              gap={0}
              maxH={{ md: "350px" }}
            >
              <Flex
                flex={1}
                textAlign={"left"}
                align="center"
                justify={"center"}
              >
                <Stack
                  p={{ base: 10, sm: 8, md: 10, lg: 10, "2xl": 0 }}
                  spacing={5}
                  maxW={"2xl"}
                >
                  <Heading
                    fontSize={{ base: "xl", sm: "xl", md: "2xl" }}
                    color="blue.500"
                  >
                    FISIOTERAPIA
                  </Heading>
                  <Text color="white" fontSize="lg">
                    Nuestro servicio de fisioterapia le ofrece a nuestros
                    deportistas un servicio completo para mejorar su rendimiento
                    y ayudar en la recuperación muscular.
                  </Text>
                  <Button
                    as="a"
                    className="cuc-section-btn"
                    bg={"blue.400"}
                    color={"white"}
                    mt={{ base: "4vw", sm: "3vw", md: "3vw", lg: "2vw" }}
                    borderRadius={0}
                    _hover={{
                      bg: "blue.600",
                    }}
                    fontWeight={400}
                    width="240px"
                    href="https://wa.me/34674088825"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Reserva una cita
                  </Button>
                </Stack>
              </Flex>
              <Flex flex={1} className="marginLeft">
                <Image
                  alt={"Login Image"}
                  objectFit={"cover"}
                  width="100%"
                  height="100%"
                  src={FisioFoto}
                />
              </Flex>
            </Stack>
          </Box>
        </Box>
      </Center>
    </>
  );
}
