import { Heading, Text, Box, Stack, Spinner } from "@chakra-ui/react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useQuery } from "react-query";
import { BsHeart } from "react-icons/bs";
import "./style.css";

export function InstagramFeed() {
  function shuffle(array) {
    function comparadorAleatorio() {
      return Math.random() - 0.5;
    }

    array.sort(comparadorAleatorio);
  }

  const fetchFeed = async () => {
    console.log("Calling instagram feed API...");

    const response = await fetch(
      "https://feeds.behold.so/70YSpVnMsiG60kFEMIc4"
    );

    const data = await response.json();
    const pics = [];

    data.forEach((item) => {
      if (item.mediaType === "VIDEO") return;

      if (item.mediaType === "CAROUSEL_ALBUM") {
        item.children.forEach((child) => {
          if (child.mediaType === "VIDEO") return;
          pics.push(child.mediaUrl);
        });
        return;
      }

      pics.push(item.mediaUrl);
    });

    shuffle(pics);
    return pics;
  };

  const { data: feed /*, isLoading*/ } = useQuery(["feed"], fetchFeed, {
    staleTime: 300000, // 5 minutes in milliseconds
    cacheTime: 600000, // 10 minutes in milliseconds
  });

  return (
    <>
      <Box width={"full"} bgColor="gray.800" pb={8} pt={2} mb={8}>
        <Heading
          fontFamily={"Oswald"}
          fontWeight={500}
          fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
          lineHeight={"110%"}
          color={"gray.100"}
          mb={12}
          mx={6}
        >
          Síguenos en&nbsp;
          <Text
            as={"span"}
            color={"blue.500"}
            display={{ base: "block", sm: "inline" }}
          >
            redes sociales
          </Text>
        </Heading>
        <div className="insta-carousel">
          <ScrollContainer className="insta-container">
            <div className="insta-carousel-content">
              {feed ? (
                feed.map((item) => (
                  <Stack
                    key={item}
                    p={5}
                    background="white"
                    pb={"70px"}
                    ml={3}
                    mr={3}
                    boxShadow={"xl"}
                  >
                    <a
                      href="https://www.instagram.com/cucfitness/"
                      className="textWithBlurredBg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="insta-img"
                        src={item}
                        alt={item}
                        width="260px"
                        height="260px"
                      />
                      <BsHeart size={70} />
                    </a>
                  </Stack>
                ))
              ) : (
                <Spinner size="xl" />
              )}
              <div className="clear-fix">&nbsp;</div>
            </div>
          </ScrollContainer>
        </div>
        <br />
      </Box>
    </>
  );
}
