import * as React from "react";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
//import { FooterNavbar } from "../components/FooterNavbar";
import { CookiesConsent } from "../components/Cookies";
import { Outlet } from "react-router-dom";
import { useAuth } from "../auth/useAuth";
import Cookies from "js-cookie";

export function MainLayout({ children }) {
  const isAppView = Cookies.get("AppView"); // if app view enabled, does not display the footer and when logging out returns to the app login page
  const auth = useAuth();

  return (
    <>
      <ChakraProvider>
        <Box
          minH="100vh"
          display="flex"
          flexDirection="column"
          position="relative"
        >
          {!isAppView && <CookiesConsent />}
          <Navbar isOpaque={true} isApp={isAppView} />
          <Box
            flex={1}
            mb={!auth.isLogged() ? null : 2}
          >
            <Outlet /> {/* Aquí se renderizan los componentes hijos */}
          </Box>
          {!auth.isLogged() && <Footer />} {/*<FooterNavbar />*/}
        </Box>
      </ChakraProvider>
    </>
  );
}

export default MainLayout;
