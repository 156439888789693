import React from "react";
import {
  ChakraProvider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Alert,
  AlertIcon,
  AlertDescription,
  HStack,
  Image,
  Stack,
  Box,
  Center,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import "./style.css";
import { LoginForm } from "../Navbar/LoginMenu/LoginForm";
import { RegisterForm } from "../Navbar/LoginMenu/RegisterForm";
import { useState } from "react";
import Logo from "../../static/CUC_LOGO.svg";
import { ForgotForm } from "../Navbar/LoginMenu/ForgotForm";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useViewportSize } from "../../hooks/useViewportSize";
import { useAuth } from "../../auth/useAuth";
import LoginPortrait from "../../images/portrait/move_it.jpg";
import { useTranslation } from "react-i18next";

// TUTORIAL INICIO SESIÓN CON GOOGLE: https://www.youtube.com/watch?v=UUJfTsn6S_Y

export function AppLogin() {
  const { t } = useTranslation();
  const [isResetOk, setIsResetOk] = useState(false);
  const [isResetError, setIsResetError] = useState(false);
  const [isForgotPwd, setIsForgotPwd] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [isRegisterError, setIsRegisterError] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { height } = useViewportSize();
  const navigate = useNavigate();
  const auth = useAuth();

  Cookies.set("AppView", "true", { expires: 365 });

  auth.isLogged() && navigate("/");

  function handleAction(value) {
    if (value === true) {
      setIsForgotPwd(!isForgotPwd);
      return;
    }

    if (value.response && value.response.status === 200) {
      console.log("Logged successfuly");
      navigate("/"); // redirects to homepage
    } else {
      setIsLoginError(true);
    }
  }

  function handleRegisterAction(value) {
    if (value === true) {
      setIsForgotPwd(!isForgotPwd);
      return;
    }

    if (value.response && value.response.status === 200) {
      console.log("Logged successfuly");
      navigate("/"); // redirects to homepage
    } else {
      setIsRegisterError(true);
    }
  }

  function handleForgotAction(value) {
    if (value.response && value.response.status !== 200) {
      setIsResetError(t(value.response.data.code));
      return;
    }

    if (value === false) {
      setIsForgotPwd(!isForgotPwd);
      return;
    } else {
      setIsResetOk("Revisa tu bandeja de entrada");
      setIsResetError(false);
    }
  }

  return (
    <ChakraProvider>
      <Stack spacing={0} h={"100vh"}>
        <Box
          w="100%"
          h="80px"
          bg="gray.900"
          textAlign="center"
          display={{ base: "block", sm: "block", md: "block", lg: "none" }}
        >
          <Center p={4}>
            <Image
              src={Logo}
              width="70px"
              height="50px"
              alt="CUC logo"
              style={{ fill: "black" }}
            />
          </Center>
        </Box>

        <Stack minH={{ lg: "100vh" }} direction={"row"} spacing={0}>
          <Flex
            flex={1}
            display={{ base: "none", sm: "none", md: "none", lg: "flex" }}
          >
            <Image
              alt={"Login Image"}
              objectFit={"cover"}
              src={LoginPortrait}
            />
          </Flex>
          <Flex
            flex={1}
            align={"center"}
            justify={"center"}
            direction={"column"}
            //minH={{ base: "750px", sm: "750px" }}
          >
            <Stack
              spacing={4}
              display={{ base: "none", sm: "none", md: "none", lg: "flex" }}
              mb={8}
            >
              <Heading
                fontFamily={"Oswald"}
                color={"gray.800"}
                lineHeight={"110%"}
                fontWeight={500}
                fontSize={{
                  base: "4xl",
                  sm: "5xl",
                  md: "4xl",
                  lg: "5xl",
                  xl: "5xl",
                  "2xl": "6xl",
                }}
                pt={{
                  base: height < 750 ? "103px" : 14,
                  sm: height < 750 ? "103px" : 16,
                  md: 0,
                }}
              >
                ¡Bienvenido a&nbsp;
                <Text
                  as={"span"}
                  bgGradient="linear(to-r, blue.500,blue.600)"
                  bgClip="text"
                >
                  CUC
                </Text>
                !
              </Heading>
              <Text
                color={"gray.500"}
                fontSize={{ base: "md", sm: "md" }}
                maxW="500px"
              >
                Inicia sesión o regístrate para continuar
              </Text>
            </Stack>
            <HStack
              border="1px solid #E2E8F0"
              bg="white"
              py={2}
              px={0}
              w={{ base: "100%", sm: "100%", md: "100%", lg: "80%" }}
              maxW={{ lg: "600px" }}
              h={{
                base: "calc(100vh - 80px)",
                sm: "calc(100vh - 80px)",
                md: "calc(100vh - 80px)",
                lg: "fit-content",
              }}
            >
              {isForgotPwd ? (
                <Tabs isFitted id="login-tabs" h="100%" w="100%">
                  <TabList>
                    <Tab textAlign="left">Restablecer contraseña</Tab>
                  </TabList>

                  {isResetOk && (
                    <Alert status="success">
                      <AlertIcon />
                      <AlertDescription>{isResetOk}</AlertDescription>
                    </Alert>
                  )}

                  {isResetError && (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertDescription>{isResetError}</AlertDescription>
                    </Alert>
                  )}

                  <TabPanels
                    h={
                      isResetOk || isResetError
                        ? "calc(100% - 90px)"
                        : "calc(100% - 42px)"
                    }
                  >
                    <TabPanel pb={5} h="100%">
                      <ForgotForm appView onAction={handleForgotAction} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              ) : (
                <Tabs
                  isFitted
                  w="100%"
                  h="100%"
                  onChange={(index) => {
                    setActiveTab(index);
                  }}
                >
                  <TabList>
                    <Tab>Inicia sesión</Tab>
                    <Tab>Regístrate</Tab>
                  </TabList>
                  <TabPanels
                    p={0}
                    h={
                      (activeTab === 0 && isLoginError) ||
                      (activeTab === 1 && isRegisterError)
                        ? "calc(100% - 90px)"
                        : "calc(100% - 42px)"
                    }
                  >
                    <TabPanel p={0} h="100%">
                      <LoginForm onAction={handleAction} appView />
                    </TabPanel>
                    <TabPanel p={0} h="100%">
                      <RegisterForm
                        onAction={handleRegisterAction}
                        onError={(value) => setIsRegisterError(value)}
                        appView
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
            </HStack>
          </Flex>
        </Stack>
      </Stack>
    </ChakraProvider>
  );
}
