import {
  Button,
  HStack,
  Box,
  Text,
  Divider,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Link,
  Spacer,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { FcGoogle } from "react-icons/fc";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
//import { /*GoogleLogin,*/ useGoogleLogin } from "@react-oauth/google";
import "./style.css";

export function ForgotForm({ appView, onAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const {
    handleSubmit,
    register: eventRegister,
    formState: { errors },
  } = useForm();

  async function onSubmit(values) {
    setIsLoading(true);

    await axios
      .post("api/user/password/request", values)
      .then((res) => {
        onAction(true);
        setFormDisabled(true);
      })
      .catch((error) => {
        console.log(error);
        onAction(error);
      });

    setIsLoading(false);
  }

  function onErrors(errors) {
    console.log("FORM ERRORS:", errors);
  }

  return (
    <>
      <Box
        px={3}
        pb={!appView && 1}
        width={
          !appView
            ? { base: "293px", sm: "318px", md: "368px", lg: "418px" }
            : null
        }
        h="100%"
      >
        <form
          onSubmit={handleSubmit(onSubmit, onErrors)}
          className="same-height"
        >
          <Stack spacing={2} h="100%">
            <Stack>
              <Stack mb={2} textAlign="left">
                <Text size="md">
                  Introduce tu correo electrónico y recibirás las instrucciones para restablecer tu cuenta.
                </Text>
              </Stack>

              <FormControl isRequired isInvalid={errors.email}>
                <FormLabel>Correo electrónico</FormLabel>
                <Input
                  id="log-email"
                  type="email"
                  placeholder="ejemplo@mail.com"
                  {...eventRegister("email", {
                    required: "Obligatorio",
                  })}
                  isReadOnly={formDisabled}
                  isDisabled={isLoading}
                />
              </FormControl>
            </Stack>

            <Stack pt={2} spacing={2}>
              <Stack
                direction={{ base: "column", xs: "row" }}
                align={"start"}
                justify={"space-between"}
                mb={2}
              >
                <Button
                  variant="link"
                  colorScheme="white"
                  color={"blue.800"}
                  size="xs"
                  fontSize="sm"
                  onClick={() => onAction(false)}
                >
                  Ver anterior
                </Button>
                <Link as={Text} color={"blue.400"} pb={0} fontSize="sm">
                  ¿Ya eres miembro?
                </Link>
              </Stack>
            </Stack>
            {appView && <Spacer />}
            <Stack>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.600",
                }}
                variant="solid"
                id="log-btn"
                isLoading={isLoading}
                isDisabled={formDisabled}
                spinner={<BeatLoader size={8} color="white" />}
                type="submit"
              >
                Enviar
              </Button>
              <HStack py={1}>
                <Divider />
                <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                  próximamente...
                </Text>
                <Divider />
              </HStack>
              <HStack>
                <Button
                  w={"full"}
                  variant={"outline"}
                  leftIcon={<FcGoogle />}
                  isDisabled={true}
                  fontWeight={300}
                >
                  Continúa con Google
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  );
}
