import {
  Box,
  Button,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  SimpleGrid,
  useToast,
  Spinner,
  Text,
  Spacer,
} from "@chakra-ui/react";
import "./style.css";
//import { Bono } from "../Bonos/BonoCard";
//import ScrollContainer from "react-indiana-drag-scroll";
//import { MdVerified } from "react-icons/md";
import { useEffect, useState } from "react";
import axios from "axios";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import { HiOutlineRefresh } from "react-icons/hi";
import { BonoRequested } from "./BonoRequested";
import { BonoPayment } from "./BonoPayment";
import { ModalBono } from "./ModalBono";

export function AdminBonos() {
  const toast = useToast(); // to display toasts
  const [bonosPendientes, setBonosPendientes] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [activationAccordion, setActivationAccordion] = useState(false);
  const [paymentAccordion, setPaymentAccordion] = useState(false);
  const [newBono, setNewBono] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      console.log("Obteniendo bonos pendientes...");
      await axios
        .get("/api/admin/bonos/outstanding")
        .then((response) => {
          setBonosPendientes(response.data);
          response.data.activationList.length > 0 &&
            setActivationAccordion(true);
          response.data.paymentList.length > 0 && setPaymentAccordion(true);

        })
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        });

      await axios
        .get("/api/admin/users/bono/active")
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        });

      setIsLoading(false);
    })();
  }, [toast, refresh]);

  function handleAction() {
    console.log("Refreshing data...");
    setRefresh(!refresh);
  }

  return (
    <>
      <ModalBono
        isOpen={newBono}
        onClose={() => setNewBono(false)}
        usersList={users}
        bonoTypesList={bonosPendientes ? bonosPendientes.bonoTypes : null}
        returnFocusOnClose={false}
      />
      <Box width={{base: "90%", sm: "90%", md: "80%"}} margin="auto" maxW="1300px">
        <HStack display="flex" justifyContent="space-between">
          {/* Button can be displayed on the left hand side if located here and spacer can be deleted*/}
          <Spacer />

          <HStack spacing={-3} mr={-2}>
            <Button
              title="Refrescar página"
              variant="ghost"
              colorScheme="black"
              size={{ base: "sm", sm: "sm", md: "md" }}
              onClick={() => setRefresh(!refresh)}
              /* Save events button */
            >
              <HiOutlineRefresh size="1.25em" />
            </Button>
            <Button
              title="Añadir nuevo bono"
              variant="ghost"
              colorScheme="black"
              size={{ base: "sm", sm: "sm", md: "md" }}
              fontWeight={400}
              px={"0px !important"}
              onClick={() => setNewBono(true)}
              /* Save events button */
            >
              <MdOutlineBookmarkAdd size="1.25em" />
            </Button>
          </HStack>
        </HStack>
      </Box>

      <Box
        width={{ base: "100%", sm: "100%", md: "80%", lg: "80%" }}
        margin="auto"
        mt={4}
        maxW="1300px"
      >
        <Accordion
          allowMultiple
          index={
            bonosPendientes
              ? [activationAccordion ? 0 : null, paymentAccordion ? 1 : null]
              : [null, null]
          }
        >
          <AccordionItem>
            <AccordionButton
              onClick={() => setActivationAccordion(!activationAccordion)}
            >
              <Box as="span" flex="1" textAlign="left" fontWeight={400}>
                Pendientes de activación{" "}
                {!isLoading && bonosPendientes ? (
                  "(" + bonosPendientes.activationList.length + ")"
                ) : (
                  <Spinner size="xs" ml={1} />
                )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              p={0}
              px={{ base: 0, sm: 0, md: 1, lg: 2 }}
              my={!isLoading && {base: 0, sm: "10px"}}
            >
              {isLoading && <Text display="none">Cargando... </Text>}

              {!isLoading &&
                bonosPendientes &&
                bonosPendientes.activationList.length > 0 && (
                  <SimpleGrid
                    id="my-bono-grid"
                    columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 3, "2xl": 4 }}
                    spacing={{ base: 2, sm: 3, md: 3, lg: 3, xl: 3 }}
                    w="100%"
                    maxW="1300px"
                  >
                    {bonosPendientes.activationList.map((item) => {
                      return (
                        <BonoRequested
                          key={item.id}
                          bonoId={item.id}
                          bonoTypeId={item.bonoTypeId}
                          bonoType={item.bonoTypeName}
                          bonoSessions={item.sessions}
                          bonoPrice={item.price}
                          bonoRequestDate={item.requestDate}
                          bonoUser={item.user}
                          bonoTypesList={bonosPendientes.bonoTypes}
                          onAction={handleAction} // once the bono is accepted or rejected, outstanding bonos are refreshed
                        />
                      );
                    })}
                  </SimpleGrid>
                )}
              {!isLoading &&
                bonosPendientes &&
                bonosPendientes.activationList.length === 0 && (
                  <Text py={4}>No hay bonos pendientes de activación</Text>
                )}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              onClick={() => setPaymentAccordion(!paymentAccordion)}
            >
              <Box as="span" flex="1" textAlign="left" fontWeight={400}>
                Pendientes de pago{" "}
                {!isLoading && bonosPendientes ? (
                  "(" + bonosPendientes.paymentList.length + ")"
                ) : (
                  <Spinner size="xs" ml={1} />
                )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              p={0}
              px={{ base: 0, sm: 0, md: 1, lg: 2 }}
              my={!isLoading && {base: 0, sm: "10px"}}
            >
              {isLoading && <Text display="none">Cargando... </Text>}

              {!isLoading &&
                bonosPendientes &&
                bonosPendientes.paymentList.length > 0 && (
                  <SimpleGrid
                    id="my-bono-grid"
                    columns={{ base: 1, sm: 1, md: 2, lg: 3, xl: 3, "2xl": 4 }}
                    spacing={{ base: 2, sm: 3, md: 3, lg: 3, xl: 3 }}
                    w="100%"
                    maxW="1300px"
                  >
                    {bonosPendientes.paymentList.map((item) => {
                      return (
                        <BonoPayment
                          key={item.id}
                          bonoId={item.id}
                          bonoType={item.bonoTypeName}
                          bonoPvp={
                            bonosPendientes.bonoTypes.filter(
                              (bonoType) => bonoType.id === item.bonoTypeId
                            )[0].price
                          }
                          bonoTotal={item.total}
                          bonoDiscount={item.discount}
                          bonoUser={item.user}
                          onAction={handleAction} // once the bono is accepted or rejected, outstanding bonos are refreshed
                        />
                      );
                    })}
                  </SimpleGrid>
                )}

              {!isLoading &&
                bonosPendientes &&
                bonosPendientes.paymentList.length === 0 && (
                  <Text py={4}>No hay bonos pendientes de pago</Text>
                )}
            </AccordionPanel>
          </AccordionItem>

          {/*<AccordionItem>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontWeight={500}>
                Todos los bonos
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              p={{ base: 0, sm: 0, md: 0, lg: 0 }}
              mt={-2}
              mb={-7}
            >
              <div id="my-carousel" className="bono-carousel">
                <ScrollContainer className="bono-container">
                  <div className="bono-carousel-content">
                    <Bono
                      bonoAdmin
                      key={1}
                      bonoId={bonos.id}
                      bonoImage={bonos.imageUrl}
                      bonoName={bonos.name}
                      bonoPrice={bonos.price}
                      bonoDescription={bonos.description}
                      bonoInclusions={bonos.trainings}
                      onAction={() => {
                        console.log("Bono clicked");
                      }}
                    />
                    <div className="clear-fix">&nbsp;</div>
                  </div>
                </ScrollContainer>
              </div>
            </AccordionPanel>
          </AccordionItem>*/}
        </Accordion>
      </Box>
    </>
  );
}
