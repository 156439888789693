import { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode'

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  useEffect(() => {
    try {
      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      localStorage.removeItem("user");
      console.log(error);
    }
  }, [user]);

  const contextValue = {
    user,

    updateUserAvatar(avatarURL){
      if (user) {
        setUser({ ...user, avatar: avatarURL });
      }
    },

    login: async (email, password, rememberChecked) => {
      // Login request to get access and refresh tokens
      var url = process.env.REACT_APP_API_URL + "/api/user/login";

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          email: email,
          password: password,
        }),
      });

      let data = await response.json();

      if (!response.ok) {
        console.log(data.message);
        return { response, data };
      }

      //console.log(data);

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data["access_token"]}`;

      /*let decodedToken = jwtDecode(axios.defaults.headers.common["Authorization"]);
      console.log("decoded token", decodedToken.exp);
      console.log("fecha expiración", decodedToken.exp - Math.floor(Date.now() / 1000));*/

      // Sets the cookie to expire at the same time as the access token expires if remember me is checked, otherwise it expires when the browser is closed
      Cookies.set("refreshToken", data.refresh_token, rememberChecked ? {expires: 10} : {}); // TODO: CAMBIAR ESTO POR EL VALOR REAL UNA VEZ ECHAS LAS PRUEBAS
      //console.log("authorization", axios.defaults.headers.common["Authorization"]);

      // Who Am I request to get user details and set user 
      // TODO: delete this making the first endpoint get back this info
      const me = await axios.get("/api/whoami");

      if (me.status !== 200) {
        let r = me.response;
        let d = {message: me.message}
        return {r, d}
      }

      setUser(me.data);

      return { response, data };
    },

    googleLogin() {
      // here we'll cal the google login endpoint that verifies the token and returns the access and refresh tokens
    },

    logout() {
      // Delete refresh token from cookie
      document.cookie =
      "refreshToken=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";

      setUser(null);
    },

    isLogged() {
      return !!user && !!Cookies.get("refreshToken");
    },

    updateLastLogin(){
      if (user) {
        setUser({ ...user, lastLogin: new Date() });
      }
    },

    getRoles() {
      try {
        const token = Cookies.get("refreshToken");//axios.defaults.headers.common["Authorization"];
        const decodedToken = jwtDecode(token);
        return decodedToken.roles; // TODO: cambiar esto por los roles del usuario

      } catch (error){
        console.log(error);
        return [];
      }
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
