import {
  FormLabel,
  Input,
  FormControl,
  Checkbox,
  Stack,
  Link,
  Button,
  Box,
  InputGroup,
  InputRightElement,
  Text,
  Divider,
  HStack,
  createIcon,
  Alert,
  AlertIcon,
  AlertDescription,
  Spacer,
} from "@chakra-ui/react";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
//import axios from "axios";
//import { Navigate } from "react-router-dom";
import { useAuth } from "../../../auth/useAuth";
import { FcGoogle } from "react-icons/fc";
import { BeatLoader } from "react-spinners";
import { /*GoogleLogin,*/ useGoogleLogin } from "@react-oauth/google";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
//import jwt_decode from "jwt-decode";

export const TwitterIcon = createIcon({
  displayName: "TwitterIcon",
  path: (
    <path
      fill="#03A9F4"
      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
    />
  ),
});

export function LoginForm({ appView, onAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberChecked, setRememberChecked] = useState(
    appView ? true : false
  );
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const auth = useAuth();

  const {
    //control,
    handleSubmit,
    register: eventRegister,
    formState: { errors },
  } = useForm();

  const GoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        console.log("Google data", response);

        // Login request to get access and refresh tokens

        /*const googleResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo", //"https://www.googleapis.com/auth/userinfo.profile", // "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${response.access_token}` } }
        );*/

        // TODO: Hacer la petición de login con el token de google en la API y que devuelva el token de acceso y el de refresco
      } catch (error) {
        console.log("Google login failed", error);
      }
    },
  });

  // This is necessary due to a bug in Chakra UI, which does not allow to use the Tab key
  // to navigate through the login form fields, neither the Enter key to submit the form
  function mailPressed(event) {
    if (event.key === "Tab") {
      document.getElementById("log-pwd").focus();
      return;
    }
  }

  function pwdPressed(event) {
    if (event.key === "Tab") {
      document.getElementById("log-btn").focus();
      return;
    }
  }

  async function onSubmit(values) {
    setIsLoading(true);
    const response = await auth.login(
      values.email,
      values.pwd,
      rememberChecked // remember checked
    );

    console.log("remember checked", rememberChecked);

    if (response.status !== 200) {
      setIsError(t(response.data.code));
      setIsLoading(false);
    }

    onAction(response);
  }

  function onErrors(errors) {
    console.log("FORM ERRORS:", errors);
  }

  return (
    <>
      {isError && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{isError}</AlertDescription>
        </Alert>
      )}
      <Box
        pt={4}
        pb={5}
        px={7}
        h={"100%"}
        width={
          !appView
            ? { base: "325px", sm: "350px", md: "400px", lg: "450px" }
            : null
        }
      >
        <form
          onSubmit={handleSubmit(onSubmit, onErrors)}
          className="same-height"
        >
          <Stack spacing={2} h="100%">
            <Stack>
              <FormControl isRequired isInvalid={errors.email}>
                <FormLabel htmlFor="email">Correo electrónico</FormLabel>
                <Input
                  id="log-email"
                  type="email"
                  name="username"
                  autoComplete="username" // usefull for password managers to store login data
                  placeholder="ejemplo@mail.com"
                  disabled={isLoading}
                  {...eventRegister("email", {
                    required: "Obligatorio",
                  })}
                  onKeyDown={!appView ? mailPressed : null}
                />
              </FormControl>
              <FormControl isRequired pt={2} isInvalid={errors.pwd}>
                <FormLabel htmlFor="pwd">Contraseña</FormLabel>
                <InputGroup>
                  <Input
                    disabled={isLoading}
                    id="log-pwd"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    autoComplete="current-password" // usefull for password managers to store login data
                    placeholder="Introduce tu clave"
                    onKeyDown={!appView ? pwdPressed : null}
                    {...eventRegister("pwd", {
                      required: "Obligatorio",
                    })}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      isDisabled={isLoading}
                      variant={"ghost"}
                      colorScheme={"white"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>
            <Stack
              direction={{ base: "column", xs: "row" }}
              align={"start"}
              justify={"space-between"}
              mb={2}
              pt={2}
            >
              <Checkbox
                size="sm"
                disabled={isLoading}
                isChecked={rememberChecked}
                onChange={(e) => setRememberChecked(e.target.checked)}
              >
                Recuérdame
              </Checkbox>
              <Link
                as={Text}
                color={"blue.400"}
                fontSize="sm"
                onClick={() => onAction(true)}
                disabled={isLoading}
              >
                Olvidé mi contraseña
              </Link>
            </Stack>
            {appView && <Spacer />}
            <Stack flex="1" justify="flex-end">
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.600",
                }}
                id="log-btn"
                isLoading={isLoading}
                spinner={<BeatLoader size={8} color="white" />}
                type="submit"
              >
                Entrar
              </Button>
              {/* Ajusta esta altura según sea necesario */}
              <HStack py={1}>
                <Divider />
                <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                  próximamente...
                </Text>
                <Divider />
              </HStack>
              <HStack>
                <Button
                  w={"full"}
                  variant={"outline"}
                  leftIcon={<FcGoogle />}
                  onClick={() => GoogleLogin()}
                  isDisabled={true}
                  fontWeight={300}
                >
                  Continúa con Google
                </Button>
              </HStack>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  );
}
