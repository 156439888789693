import { Stack, Text, Badge, Box, Link } from "@chakra-ui/react";
import { BsInstagram } from "react-icons/bs";
import "./style.css";

export function Coach(props) {
  return (
    <>
      <Stack
        background="white"
        m={props.about ? "auto" : 3}
        mt={3}
        mb={10}
        p={5}
        boxShadow={"xl"}
        minW="300px"
        w="300px"
        cursor={props.coachLink && "pointer"}
        className={
          props.coachLink ? "bono-card prevent-select" : "prevent-select"
        }
      >
        <Link
          as={!props.coachLink && Box}
          href={props.coachLink}
          className={
            props.coachLink
              ? "textWithBlurredBgBono"
              : "textWithNoBlurredBgBono"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width="260px"
            height="260px"
            className="coach-img"
            src={props.coachImage}
            alt={"Imagen del entrenador " + props.coachName}
          />
          {props.coachLink && <BsInstagram size={70} pl={2} />}
        </Link>
        <Stack px={1} py={1} textAlign="left">
          <Text fontSize="28px" fontFamily="Oswald" fontWeight={400}>
            {props.coachName}
          </Text>
          <Text fontSize="16px" mt={-2}>
            {props.coachTitle}
          </Text>
          <Box>
            {props.coachTrainings &&
              props.coachTrainings.map((item) => (
                <Badge
                  key={item}
                  mt={-1}
                  mr={1}
                  variant="subtle"
                  className="badge-color"
                >
                  {item}
                </Badge>
              ))}
          </Box>
          <Text color="gray.600" fontSize="sm">
            {props.coachDescription}
          </Text>
        </Stack>
      </Stack>
    </>
  );
}
