import * as React from "react";
import { ChakraProvider, Box, AbsoluteCenter, Heading } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { useParams } from "react-router-dom";

// TODO: Set a message error when the token is invalid --> Fix the API call to return a 404 error
// SAMPLE: url.com/activate/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJjbXVyZ2E5QGdtYWlsLmNvbSIsImFjdGl2YXRlIjp0cnVlLCJleHAiOjE2OTI3NDg4MDB9.vld5AK5-tIikKxMkIRkdLgSn1SIHai48wTnvDEmHfYE

export function ActivateAccount() {
  const navigate = useNavigate();
  const { token } = useParams();

  console.log(token);

  // API call to activate the account
  const { data } = useFetch("/api/user/activate/" + token, null);

  // If activated, the client will be redirected to the home page after a little delay
  data &&
    setTimeout(() => {
      navigate("/");
    }, 3000);

  return (
    <>
      <ChakraProvider>
        <Box position="relative" height={"100vh"}>
          <AbsoluteCenter>
            <Spinner size="xl" mb={10} thickness="3px" />
            {data ? (
              <Heading size={{ base: "md", md: "lg", lg: "lg" }}>
                Tu cuenta ha sido activada. Redirigiendo...
              </Heading>
            ) : (
              <Heading size={{ base: "md", md: "lg", lg: "lg" }}>
                Activando cuenta...
              </Heading>
            )}
          </AbsoluteCenter>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default ActivateAccount;