import axios from "axios";
import Cookies from 'js-cookie';

axios.defaults.baseURL = process.env.REACT_APP_API_URL; // all requests will be prefixed with this URL

axios.interceptors.response.use(response => response, async error => { // to handle errors

    if (error.response && ((error.response.status === 401 || error.response.status === 403 /* || error.response.status === 500*/ ))) { // if the error is 403 or 500 (this might need to be changed - only 403)
        try {
            console.log("Refreshing token...");
            axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get("refreshToken")}`;
            const response = await axios.get('/api/user/token/refresh');
        
            if (response.status === 200) {
                let originalRequest = error.config;
                originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`; // modify the request to include the new access token

                axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`; // set the new access token as the default authorization header so that it is used in all subsequent requests

                return axios(originalRequest); // retry the request that threw an error using the new access token
            }

        } catch (error) {
            console.error("Error refreshing the token");
        }
    }

    return Promise.reject(error);
});

// TODO: ELIMINAR ESTO UNA VEZ SE CAMBIE EL BACKEND
// REVISAR: https://www.youtube.com/watch?v=VJLSaq1Ll0U