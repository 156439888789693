import { Box, Heading, Text } from "@chakra-ui/react";
import "./style.css";
import Andreu from "../../images/coaches/andreu_massot.webp";
import Maria from "../../images/coaches/maria_portalo.webp";
import Elo from "../../images/coaches/elo_salva.webp";
import Carlota from "../../images/coaches/carlota_provenzali.webp";
import Marta from "../../images/coaches/marta_daviu.webp";
import ScrollContainer from "react-indiana-drag-scroll";
import { Coach } from "./CoachCard";

export function Coaches(props) {
  return (
    <Box /*bg="gray.800"*/>
      <Heading
        fontFamily={"Oswald"}
        fontWeight={400}
        fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
        lineHeight={"110%"}
        color={"gray.800"}
        pt={3}
        px={4}
        pb={8}
        textAlign={"center"}
      >
        Conoce a todo
        <Text
          as={"span"}
          color={"blue.500"}
          display={{ base: "block", sm: "inline" }}
        >
          {" "}
          nuestro equipo
        </Text>
      </Heading>
      <div id="my-carousel" className="coach-carousel">
        <ScrollContainer className="coach-container">
          <div className="coach-carousel-content">
            <Coach
              coachName="Andreu Massot"
              coachTitle="CEO & Coach"
              coachDescription="TAFAD, HYROX Coach y Especialista Universitario en Nutrición Deportiva"
              coachImage={Andreu}
              coachLink="https://www.instagram.com/andreu_massot/"
              coachTrainings={["FUNCIONAL"]}
            />
            <Coach
              coachName="Elo Salvà"
              coachTitle="Coach"
              coachDescription="Graduada en CAFYD y Máster en Formación del Profesorado"
              coachImage={Elo}
              coachTrainings={["FUNCIONAL", "ESPALDA SANA"]}
            />
            <Coach
              coachName="Maria Portalo"
              coachTitle="Yoga coach"
              coachDescription="Formación 300 RYT y Especialista en Yoga para la Mujer"
              coachImage={Maria}
              coachTrainings={["YOGA", "YOGA REST"]}
            />
            <Coach
              coachName="Carlota Provenzali"
              coachTitle="Diestista-nutricionista"
              coachDescription="Especialista en salud hormonal y digestiva. Col. IB00285"
              coachImage={Carlota}
              coachLink="https://www.instagram.com/carlota_nutri/"
              coachTrainings={["NUTRICIÓN"]}
            />
            <Coach
              coachName="Marta Daviu"
              coachTitle="Fisioterapeuta & Coach"
              coachDescription="Graduada en Fisioterapia y Especialista en Ejercicio Terapéutico. Col. 2082"
              coachImage={Marta}
              coachLink="https://www.instagram.com/marthaviu/"
              coachTrainings={["FISIOTERAPIA", "STRETCH"]}
            />
            <div className="clear-fix">&nbsp;</div>
          </div>
        </ScrollContainer>
      </div>
    </Box>
  );
}
