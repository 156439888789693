import {
  Menu,
  MenuButton,
  MenuList,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { RegisterForm } from "./RegisterForm";
import { LoginForm } from "./LoginForm";
import { ForgotForm } from "./ForgotForm";
import { AiOutlineUser } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import "./style.css";

export function LoginMenu() {
  const [isForgotPwd, setIsForgotPwd] = useState(false);
  const { t } = useTranslation();
  const [isResetOk, setIsResetOk] = useState(false);
  const [isResetError, setIsResetError] = useState(false);

  function handleAction(value) {
    if (value === true) {
      setIsForgotPwd(true);
    }
  }

  function handleForgotAction(value) {
    if (value.response && value.response.status !== 200) {
      setIsResetError(t(value.response.data.code));
      return;
    }

    if (value === false) {
      setIsForgotPwd(!isForgotPwd);
      return;
    } else {
      setIsResetOk("Revisa tu bandeja de entrada");
      setIsResetError(false);
    }
  }

  return (
    <>
      <Menu id="log-button">
        <MenuButton
          as={Button}
          rounded={"full"}
          variant={"link"}
          aria-label="Iniciar sesión"
        >
          <HStack spacing={0.5}>
            <AiOutlineUser color="white" size="1.5em" />
            <ChevronDownIcon color="white" mt={0.7} />
          </HStack>
        </MenuButton>
        <MenuList alignItems={"center"} color={"black"} borderRadius="0">
          {isForgotPwd ? (
            <Tabs isFitted id="login-tabs">
              <TabList>
                <Tab textAlign="left">Restablecer contraseña</Tab>
              </TabList>

              {isResetOk && (
                <Alert status="success">
                  <AlertIcon />
                  <AlertDescription>{isResetOk}</AlertDescription>
                </Alert>
              )}

              {isResetError && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>{isResetError}</AlertDescription>
                </Alert>
              )}

              <TabPanels>
                <TabPanel>
                  <ForgotForm onAction={handleForgotAction} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <Tabs isFitted id="login-tabs">
              <TabList>
                <Tab id="login-tab">Inicia sesión</Tab>
                <Tab>Regístrate</Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={0}>
                  <LoginForm onAction={handleAction} />
                </TabPanel>
                <TabPanel p={0}>
                  <RegisterForm onError={() => {}} onAction={handleAction} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </MenuList>
      </Menu>
    </>
  );
}

// TODO: Add the forgot password panel
// TODO: sign in and sign up with google
