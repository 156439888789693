import {
  Text,
  SimpleGrid,
  Box,
  AvatarGroup,
  Avatar,
  Collapse,
  useDisclosure,
  Button,
  HStack,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";
import "./style.css";

export function NextCard(props) {
  const { isOpen: isCardOpen /*, onToggle: onCardToggle */ } = useDisclosure();

  function handleClick(e) {
    // TODO: modificar y utilizar closest
    const clickedElement = e.target;

    if (
      !clickedElement.classList.contains("chakra-avatar__group") &&
      !clickedElement.classList.contains("chakra-avatar__initials") &&
      !clickedElement.classList.contains("chakra-avatar__excess") &&
      !clickedElement.classList.contains("chakra-avatar") &&
      !clickedElement.classList.contains("chakra-popover__body") &&
      !clickedElement.classList.contains("chakra-stack") &&
      !clickedElement.classList.contains("chakra-avatar__img") &&
      !clickedElement.classList.contains("chakra-avatar__svg") &&
      clickedElement.id !== "avatar-text"
    ) {
      //onCardToggle();
    }
  }

  return (
    <>
      <Box>
        <SimpleGrid
          columns={3}
          spacing={0}
          bgColor="gray.800"
          color="white"
          px={{ base: 4, sm: 5 }}
          py={{ base: 3, sm: 5 }}
          textAlign="left"
          fontFamily={"Oswald"}
          onClick={handleClick}
          cursor={"pointer"}
          className="prevent-select"
        >
          <Box my="auto">
            <Text
              fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
              fontWeight="400"
            >
              {props.trainingDate}
            </Text>
            <Text
              fontWeight={100}
              fontSize={{ base: "md", sm: "md", md: "md", lg: "lg" }}
            >
              {props.trainingStart + "h"}
            </Text>
          </Box>
          <Box m="auto" textAlign="center">
            <Text
              fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
              fontWeight={400}
            >
              {props.trainingName}
            </Text>
            <HStack
              fontSize={{ sm: "md", md: "lg", lg: "lg" }}
              spacing={1}
              justifyContent="center"
            >
              <Text display={{ base: "none", sm: "none", md: "flex" }}>
                Coach:
              </Text>
              <Text>{props.trainingCoach}</Text>
            </HStack>
          </Box>
          <Box spacing={2} margin="auto" mr="0">
            <Popover
              returnFocusOnClose={false}
              flip={false}
              placement="bottom-end"
            >
              <PopoverTrigger>
                <Button
                  size="xl"
                  variant="ghost"
                  _hover={{
                    bg: "transparent",
                  }}
                >
                  <AvatarGroup
                    id="avatar-group"
                    size={{ base: "sm", sm: "md", md: "md" }}
                    max={2}
                    textAlign={"right"}
                    justifyContent="flex-start"
                    color="gray.800"
                    fontFamily={"system-ui"}
                  >
                    {props.trainingClients.length > 0 &&
                      props.trainingClients.map((item) => {
                        if (item.name === "") {
                          return <Avatar className="prevent-select" />;
                        } else {
                          return (
                            <Avatar
                              key={item.name + " " + item.surname1}
                              name={`${item.name} ${item.surname1}`.trim()}
                              src={item.avatar}
                              className="prevent-select"
                            />
                          );
                        }
                      })}
                  </AvatarGroup>
                </Button>
              </PopoverTrigger>
              <PopoverContent
                color="black"
                fontFamily="system-ui"
                width="fit-content"
              >
                <PopoverArrow />
                <PopoverBody className="allow-select" cursor="auto">
                  <Stack spacing={2}>
                    {props.trainingClients.map((item) => {
                      if (item.name === "") {
                        return (
                          <HStack id="testing" key={item.name}>
                            <Avatar
                              border="2px solid gray"
                              size="sm"
                              className="prevent-select"
                            />
                            <Text>Anónimo</Text>
                          </HStack>
                        );
                      } else {
                        return (
                          <HStack id="testing" key={item.name}>
                            <Avatar
                              size="sm"
                              border="2px solid gray"
                              key={item.name + " " + item.surname1}
                              name={`${item.name} ${item.surname1}`.trim()}
                              src={item.avatar}
                              className="prevent-select"
                            />
                            <Text>{item.name + " " + item.surname1}</Text>
                          </HStack>
                        );
                      }
                    })}
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </SimpleGrid>
        <Collapse in={isCardOpen}>
          <HStack
            pt={4}
            pb={2}
            width={{ sm: "100%", md: "90%" }}
            spacing={5}
            margin="auto"
            maxW="1000px"
            justifyContent="center"
          >
            <Text>
              Puedes cancelar la reserva hasta 4 horas antes del inicio
            </Text>
            <Button m={3} colorScheme="red">
              Cancelar
            </Button>
          </HStack>
        </Collapse>
      </Box>
    </>
  );
}
