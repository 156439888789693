import { Heading, Text } from "@chakra-ui/react";
//import { useEffect, useState } from "react";
import { Bono } from "./BonoCard";
import { SkeletonBonoCard } from "./SkeletonBonoCard";
import ScrollContainer from "react-indiana-drag-scroll";
import axios from "axios";
import "./style.css";
import { useAuth } from "../../auth/useAuth";
import { useQuery } from "react-query";

export function Bonos() {
  //const [bonos, setBonos] = useState(null);
  const auth = useAuth();

  const fetchBonos = async () => {
    const { data } = await axios.get("/api/bonoTypes");
    return data;
  };

  const { data: bonos, isLoading } = useQuery("fetchBonos", fetchBonos, {
    staleTime: 15 * 1000, // 15 segundos
    //cacheTime: 5 * 60 * 1000, // 5 minutos
  });

  return (
    <>
      <Heading
        fontFamily={"Oswald"}
        fontWeight={500}
        fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
        lineHeight={"110%"}
        color={"gray.800"}
        mt={auth.isLogged() ? "116px" : 10}
        mb={2}
        mx={6}
      >
        {!auth.isLogged() ? "Échale un vistazo a" : "Adquiere tu"}&nbsp;
        <Text as={"span"} color={"blue.500"}>
          {!auth.isLogged() ? "nuestros bonos" : "bono"}
        </Text>
      </Heading>

      <div id="my-carousel" className="bono-carousel">
        <ScrollContainer className="bono-container">
          <div className="bono-carousel-content">
            {bonos &&
              bonos.map((item) => (
                <Bono
                  key={item.id}
                  bonoId={item.id}
                  bonoImage={item.imageUrl}
                  bonoName={item.name}
                  bonoPrice={item.price}
                  bonoDescription={item.description}
                  bonoInclusions={item.includedTrainings}
                  bonoSessions={item.sessions}
                  removePadding={auth.isLogged()}
                  onAction={() => {
                    console.log("Bono clicked");
                  }}
                />
              ))}
            {isLoading && (
              <>
                <SkeletonBonoCard />
                <SkeletonBonoCard />
                <SkeletonBonoCard />
                <SkeletonBonoCard />
                <SkeletonBonoCard />
                <SkeletonBonoCard />
              </>
            )}
            <div className="clear-fix">&nbsp;</div>
          </div>
        </ScrollContainer>
      </div>
    </>
  );
}
