import {
  Box,
  Text,
  Stack,
  HStack,
  Badge,
  Image,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  ModalOverlay,
  Spacer,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React from "react";
import { FaRegCreditCard } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function BonoActivity(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  let statusColor;
  let statusVariant;
  let statusText;
  switch (props.bonoStatus) {
    case "ACCEPTED":
      statusColor = "blue"; // Green color for ACTIVO status
      statusVariant = "solid";
      statusText = "ACTIVO";
      break;
    case "DRAINED":
      statusColor = "red"; // Green color for ACTIVO status
      statusVariant = "outline";
      statusText = "AGOTADO";
      break;
    case "CANCELLED":
      statusColor = "red"; // Red color for CANCELADO status
      statusVariant = "solid";
      statusText = "CANCELADO";
      break;
    case "REJECTED":
      statusColor = "red"; // Red color for CANCELADO status
      statusVariant = "solid";
      statusText = "RECHAZADO";
      break;
    case "EXPIRED":
      statusColor = "red"; // Orange color for EXPIRADO status
      statusVariant = "outline";
      statusText = "CADUCADO";
      break;
    case "INACTIVE":
      statusColor = "blue"; // Orange color for EXPIRADO status
      statusVariant = "outline";
      statusText = "INACTIVO";
      break;
    case "OUTSTANDING":
      statusColor = "yellow"; // Blue color for PENDIENTE status
      statusVariant = "outline";
      statusText = "PENDIENTE";
      break;
    default:
      statusColor = "#000000"; // Default black color for unknown status
      statusVariant = "solid";
  }

  const options = { year: "2-digit", month: "2-digit", day: "2-digit" };

  function formatDate(value) {
    if (value === undefined)
      return props.bonoName === "CUC Day" ? "1 día" : "30 días";
    let date = new Date(value);
    return new Intl.DateTimeFormat("es-ES", options).format(date);
  }

  const {
    handleSubmit,
    register: eventRegister,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      bonoId: props.bonoId,
      activationDate: props.bonoActivationDate,
      expirationDate: props.bonoExpirationDate,
    },
    shouldFocusError: false,
  });

  useEffect(() => {
    reset({
      bonoId: props.bonoId,
      activationDate: props.bonoActivationDate
        ? props.bonoActivationDate.split("T")[0]
        : null,
      expirationDate: props.bonoExpirationDate
        ? props.bonoExpirationDate.split("T")[0]
        : null,
    });
  }, [props.bonoId, props.bonoActivationDate, props.bonoExpirationDate, reset]);

  async function onSubmit(values) {
    if (new Date(values.activationDate) > new Date(values.expirationDate)) {
      setError("activationDate", {
        type: "manual",
        message: t("BE-3"),
      });

      return;
    }

    console.log("submit", {
      activationDate: new Date(values.activationDate).toISOString(),
      expirationDate: new Date(values.expirationDate).toISOString(),
    });
    setIsLoading(true);
    await axios
      .put("/api/admin/bono/update/" + values.bonoId, {
        activationDate: new Date(values.activationDate).toISOString(),
        expirationDate: new Date(values.expirationDate).toISOString(),
      })
      .then(() => {
        toast({
          title: "¡Fechas actualizadas correctamente!",
          description: "",
          position: "bottom-right",
          duration: 4000,
          isClosable: true,
        });
        props.onAction(true);
        onClose(true);
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "¡Error inesperado!",
          description: t(error.response.data.code),
          status: "error",
          position: "bottom-right",
          duration: 4000,
          isClosable: true,
        });
      });
    setIsLoading(false);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="sm"
        borderRadius={"0px"}
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"left"} pb={0}>
            Editar bono
          </ModalHeader>

          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody pb={0}>
              <Stack spacing={3} mb={0.5}>
                <BonoActivity
                  key={props.bonoId}
                  bonoId={props.bonoId}
                  bonoName={props.bonoName}
                  bonoImage={props.bonoImage}
                  bonoStatus={props.bonoStatus}
                  bonoSessions={props.bonoSessions}
                  bonoCreationDate={props.bonoCreationDate}
                  bonoActivationDate={props.bonoActivationDate}
                  bonoExpirationDate={props.bonoExpirationDate}
                  bonoEdit={true}
                />
                <HStack py={2} max-width="336px">
                  <Box width={"48.5%"}>
                    <FormControl isInvalid={errors.activationDate}>
                      <FormLabel htmlFor="activationDate" fontSize="sm">
                        Fecha activación
                      </FormLabel>
                      <Input
                        id="activationDate"
                        isDisabled={props.bonoStatus === "REJECTED"}
                        type="date"
                        placeholder="Fecha de activación del bono"
                        size="sm"
                        {...eventRegister("activationDate", {
                          required: "Obligatorio",
                        })}
                        max-width="164.01px"
                      />
                    </FormControl>
                  </Box>

                  <Box width={"49%"}>
                    <FormControl isInvalid={errors.expirationDate}>
                      <FormLabel htmlFor="expirationDate" fontSize="sm">
                        Fecha caducidad{" "}
                      </FormLabel>
                      <Input
                        id="expirationDate"
                        isDisabled={props.bonoStatus === "REJECTED"}
                        type="date"
                        placeholder="Fecha de caducidad del bono"
                        size="sm"
                        {...eventRegister("expirationDate", {
                          required: "Obligatorio",
                        })}
                      />
                    </FormControl>
                  </Box>
                </HStack>
                {errors.activationDate && (
                  <Text fontSize="xs" color="red.500" fontWeight={400} pb={2}>
                    {errors.activationDate.message}
                  </Text>
                )}
              </Stack>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button onClick={onClose} isDisabled={isLoading}>
                Cancelar
              </Button>
              <Spacer />
              <Button
                variant={"outline"}
                type="submit"
                colorScheme="blue"
                isDisabled={props.bonoStatus === "REJECTED"}
                isLoading={isLoading}
              >
                Guardar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Box m="auto" w="100%" onClick={() => !props.bonoEdit && onOpen(true)}>
        <HStack
          m="auto"
          role={"group"}
          p={4}
          bg="white"
          boxShadow={"sm"}
          spacing={5}
          w="100%"
          cursor={!props.bonoRequest ? "pointer" : "default"}
        >
          <Image
            className="bono-purchased-img"
            src={props.bonoImage}
            alt={props.bonoName + " logo"}
          />

          <Stack m="auto" ml={0} textAlign={"left"}>
            <Text
              fontFamily={"Oswald"}
              fontWeight="500"
              fontSize="2xl"
              color="black"
            >
              {props.bonoName}
            </Text>
            {!props.bonoRequest && (
              <HStack>
                <Badge
                  variant={statusVariant}
                  colorScheme={statusColor}
                  width={"fit-content"}
                  borderRadius={0}
                >
                  {statusText}
                </Badge>
                {props.bonoPaymentStatus === "OUTSTANDING" && (
                  <>
                    <Popover returnFocusOnClose={false} flip={false}>
                      <PopoverTrigger>
                        <Button
                          size="xl"
                          variant="ghost"
                          _hover={{
                            bg: "transparent",
                          }}
                        >
                          <FaRegCreditCard />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent color="black" width="fit-content">
                        <PopoverArrow />
                        <PopoverBody className="allow-select" cursor="auto">
                          <HStack>
                            <Text>
                              Importe pendiente:{" "}
                              <b>{props.bonoTotal.toFixed(2)}€</b>{" "}
                              {parseFloat(props.bonoDiscount) !== 0 &&
                                "(-" + props.bonoDiscount + "%)"}
                            </Text>
                          </HStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </>
                )}
              </HStack>
            )}
            <Stack fontWeight={400} fontSize={"sm"} spacing={-0.5}>
              {props.bonoRequest && (
                <Text>
                  Precio: <b>{props.bonoPrice} €</b>
                </Text>
              )}
              <Text>
                Sesiones:{" "}
                <b>
                  {props.bonoSessions !== -1
                    ? props.bonoSessions
                    : "Ilimitadas"}
                </b>
              </Text>
              <Text></Text>
              <Text>
                {props.bonoStatus === "REJECTED" ? (
                  <>
                    Solicitado: <b>{formatDate(props.bonoCreationDate)}</b>
                  </>
                ) : props.bonoStatus === "INACTIVE" ? (
                  <>
                    Activación: <b>{formatDate(props.bonoActivationDate)}</b>
                  </>
                ) : props.bonoStatus === "OUTSTANDING" ? (
                  <>
                    Solicitado: <b>{formatDate(props.bonoCreationDate)}</b>
                  </>
                ) : (
                  <>
                    Caducidad: <b>{formatDate(props.bonoExpirationDate)}</b>
                  </>
                )}
              </Text>
            </Stack>
          </Stack>
        </HStack>
      </Box>
    </>
  );
}
