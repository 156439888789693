import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Text,
} from "@chakra-ui/react";
import "./style.css";

export function FAQs() {
  return (
    <>
      <Box mt={4} mb={6}>
        <Heading
          fontFamily={"Oswald"}
          fontWeight={500}
          fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
          lineHeight={"110%"}
          color={"gray.800"}
          mb={12}
          mx={8}
        >
          Preguntas&nbsp;
          <Text as={"span"} color={"blue.500"}>
            frecuentes
          </Text>
        </Heading>

        <Box
          width={{ sm: "100%", md: "90%" }}
          margin="auto"
          pt={2}
          pb={"30px"}
          textAlign="left"
        >
          <Accordion allowMultiple>
            <AccordionItem>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color="blue.500"
                  fontFamily="Oswald"
                >
                  ¿Cómo compro un bono?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} fontSize={"17px"}>
                Por el momento no aceptamos pagos telemáticos, por lo que el
                importe debe abonarse en el local. Elige el bono que más se
                adapte a ti y solicita su activación en el apartado de bonos.
                Una vez activado, podrás empezar a reservar sesiones.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color="blue.500"
                  fontFamily="Oswald"
                >
                  ¿Debo pagar una matrícula?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} fontSize={"17px"}>
                La matrícula (30€) deben abonarla todos los nuevos clientes en
                la primera clase. Los clientes que no hayan renovado su bono,
                deberán abonar también el importe total de la matrícula.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color="blue.500"
                  fontFamily="Oswald"
                >
                  ¿Durante cuánto tiempo puedo utilizar un bono?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} fontSize={"17px"}>
                Los bonos tienen una duración de 30 días desde la fecha de
                activación y no podrás reservar ninguna sesión que esté
                programada más allá de la fecha de expiración del bono.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color="blue.500"
                  fontFamily="Oswald"
                >
                  ¿Cómo puedo realizar el pago de un bono?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} fontSize={"17px"}>
                Al comprar tu bono dispones de 5 días para realizar el pago a
                través de cualquiera de los siguientes métodos: tarjeta de
                crédito o débito en CUC, transferencia bancaria (pídenos el
                número de cuenta) o bien en efectivo.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color="blue.500"
                  fontFamily="Oswald"
                >
                  ¿Puedo venir a probar?
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4} fontSize={"17px"}>
                ¡Claro que sí! Ponte en contacto con nosotros y te reservamos un
                hueco en una de nuestras sesiones.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color="blue.500"
                  fontFamily="Oswald"
                >
                  ¿Ofrecéis entrenamientos personales?
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4} fontSize={"17px"}>
                Disponemos de entrenamientos personales 100% adaptados a tus
                necesidades y con entrenadores cualificados. No dudes en ponerte
                en contacto con nosotros para más información.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color="blue.500"
                  fontFamily="Oswald"
                >
                  ¿Cómo me pongo en contacto con vosotros?
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4} fontSize={"17px"}>
                Estaremos encantados de recibirte en nuestras instalaciones,
                pero si lo prefieres, escríbenos por WhatsApp (+34 674 088 825)
                o correo electrónico a cucfitness@gmail.com.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </>
  );
}
