import {
  Heading,
  Text,
  Button,
  Box,
  Stack,
  HStack,
  Skeleton,
} from "@chakra-ui/react";
import "./style.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { TrainingCard } from "./TrainingCard";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { useAuth } from "../../auth/useAuth";
import { useQuery } from "react-query";

export function Scheduler(props) {
  const auth = useAuth();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [currentMonday, setCurrentMonday] = useState(getMonday(today));
  const [currentDay, setCurrentDay] = useState(
    today.getDay() === 0 || today.getDay() === 6 ? 1 : today.getDay()
  );
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  const weekOp = { month: "long", day: "numeric" };
  let isEmptyDay = true;

  function getMonday(d) {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    if (day === 6 || day === 0) {
      return new Date(d.setDate(diff + 7));
    }
    return new Date(d.setDate(diff));
  }

  function getThisWeek() {
    const monday = getMonday(currentMonday);
    const from = new Date(monday);
    const to = new Date(monday.setDate(from.getDate() + 4));
    return `${from.toLocaleDateString(
      "es-ES",
      weekOp
    )} - ${to.toLocaleDateString("es-ES", weekOp)}`;
  }

  function handleAgenda(value) {
    setCurrentDay(value);
    document.querySelector(".selected-day").classList.remove("selected-day");
    document.querySelector(`div[id="${value}"]`).classList.add("selected-day");
  }

  function nextWeek() {
    setIsPrevDisabled(false);
    setCurrentMonday(
      new Date(currentMonday.setDate(currentMonday.getDate() + 7))
    );
    setCurrentDay(1);
    document.querySelector(".selected-day").classList.remove("selected-day");
    document.querySelector('div[id="1"]').classList.add("selected-day");
  }

  function prevWeek() {
    setCurrentMonday(
      new Date(currentMonday.setDate(currentMonday.getDate() - 7))
    );
    if (currentMonday.getTime() <= getMonday(today).getTime()) {
      setIsPrevDisabled(true);
    }
  }

  function getnday(fecha, num) {
    const nuevaFecha = new Date(fecha);
    nuevaFecha.setDate(nuevaFecha.getDate() + num);
    return nuevaFecha.getDate();
  }

  const currentSunday = new Date(currentMonday);
  currentSunday.setDate(currentSunday.getDate() + 6);

  const mybody = {
    from: new Date(currentMonday),
    to: new Date(currentSunday),
  };

  const fetchTrainings = async ({ queryKey }) => {
    const [, mybody] = queryKey;
    if (auth.isLogged()) {
      const response = await axios.post("/api/trainings/logged", mybody);
      return response.data;
    } else {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/api/trainings",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mybody),
        }
      );
      const data = await response.json();
      return data;
    }
  };

  const { data, error, isLoading, refetch } = useQuery(
    ["fetchTrainings", mybody],
    fetchTrainings,
    {
      staleTime: 20 * 1000, // 15 seconds
      //cacheTime: 60 * 1000, // 1 minute
      enabled: true, // Enable query
    }
  );

  useEffect(() => {
    console.log("Refetching...")
    refetch();
  }, [auth, refetch]);

  function addColleague(trainingId) {
    console.log("Añadir participante", trainingId);
    const newData = [...data];
    const foundIndex = newData.findIndex(
      (training) => training.trainingId === trainingId
    );
    if (foundIndex !== -1) {
      newData[foundIndex].participants.push({
        id: auth.user.id,
        name: auth.user.name,
        surname1: auth.user.surname1,
        avatar: auth.user.avatar,
      });
      newData[foundIndex].remainingSpots--;
      newData[foundIndex].status = "BOOKED";
    } else {
      console.error(`No se encontró el trainingId ${trainingId}`);
    }

    props.onSchedulerChange();
  }

  function removeColleague(trainingId) {
    console.log("Eliminar participante", trainingId);
    const newData = [...data];
    const foundTraining = newData.find(
      (training) => training.trainingId === trainingId
    );
    if (foundTraining) {
      const indexToRemove = foundTraining.participants.findIndex(
        (participant) => participant.id === auth.user.id
      );
      if (indexToRemove !== -1) {
        foundTraining.participants.splice(indexToRemove, 1);
        foundTraining.remainingSpots++;
        foundTraining.status = "BOOKABLE";
        //setData(newData);
      } else {
        console.error(
          `No se encontró un participante con id ${auth.user.id} para el trainingId ${trainingId}`
        );
      }
    } else {
      console.error(`No se encontró el trainingId ${trainingId}`);
    }

    props.onSchedulerChange();
  }

  return (
    <>
      <Heading
        fontFamily={"Oswald"}
        fontWeight={400}
        fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
        lineHeight={"110%"}
        color={"gray.800"}
        mt={!auth.isLogged() ? 12 : 4}
        mb={!auth.isLogged() ? 4 : 2}
        mx={5}
      >
        {!auth.isLogged() ? "¿Qué hacemos" : "Reserva tu"}&nbsp;
        <Text as={"span"} color={"blue.500"}>
          {!auth.isLogged() ? "hoy" : "sesión"}
        </Text>
        {!auth.isLogged() && "?"}
      </Heading>

      <Box width={{ sm: "100%", md: "90%" }} margin="auto" maxW="1000px">
        <HStack justifyContent="space-between" mb={3}>
          <Button
            id="prev"
            background="transparent"
            _hover={{ bg: "transparent" }}
            isDisabled={isPrevDisabled}
            onClick={prevWeek}
            aria-label="Semana anterior"
          >
            <GoChevronLeft />
          </Button>
          <Text fontSize={{ base: "md", sm: "lg", md: "lg" }}>
            {currentMonday && getThisWeek()}
          </Text>
          <Button
            id="next"
            onClick={nextWeek}
            background="transparent"
            _hover={{ bg: "transparent" }}
            aria-label="Semana siguiente"
          >
            <GoChevronRight />
          </Button>
        </HStack>

        <HStack
          id="agenda"
          width="100%"
          bgColor="gray.100"
          justifyContent={"center"}
          spacing={0}
        >
          <Stack
            id="1"
            className={currentDay === 1 ? "selected-day day-btn" : "day-btn"}
            onClick={() => handleAgenda(1)}
          >
            <Text fontSize="xs">LUN</Text>
            <Text>{getnday(currentMonday, 0)}</Text>
          </Stack>
          <Stack
            id="2"
            className={currentDay === 2 ? "selected-day day-btn" : "day-btn"}
            onClick={() => handleAgenda(2)}
          >
            <Text fontSize="xs">MAR</Text>
            <Text>{getnday(currentMonday, 1)}</Text>
          </Stack>
          <Stack
            id="3"
            className={currentDay === 3 ? "selected-day day-btn" : "day-btn"}
            onClick={() => handleAgenda(3)}
          >
            <Text fontSize="xs">MIE</Text>
            <Text>{getnday(currentMonday, 2)}</Text>
          </Stack>
          <Stack
            id="4"
            className={currentDay === 4 ? "selected-day day-btn" : "day-btn"}
            onClick={() => handleAgenda(4)}
          >
            <Text fontSize="xs">JUE</Text>
            <Text>{getnday(currentMonday, 3)}</Text>
          </Stack>
          <Stack
            id="5"
            className={currentDay === 5 ? "selected-day day-btn" : "day-btn"}
            onClick={() => handleAgenda(5)}
          >
            <Text fontSize="xs">VIE</Text>
            <Text>{getnday(currentMonday, 4)}</Text>
          </Stack>
        </HStack>
      </Box>

      <Stack
        spacing={2}
        width={{ sm: "100%", md: "90%" }}
        margin="auto"
        maxW="1000px"
        pb={{ base: 3, sm: 6 }}
      >
        {isLoading ? (
          <Stack m="auto" width="100%" spacing={2}>
            <Skeleton
              h={{
                base: "78px",
                sm: "100px",
                md: "112px",
                lg: "121px",
                xl: "121px",
              }}
              borderRadius={0}
            ></Skeleton>
            <Skeleton
              h={{
                base: "78px",
                sm: "100px",
                md: "112px",
                lg: "121px",
                xl: "121px",
              }}
              borderRadius={0}
            ></Skeleton>
            <Skeleton
              h={{
                base: "78px",
                sm: "100px",
                md: "112px",
                lg: "121px",
                xl: "121px",
              }}
              borderRadius={0}
            ></Skeleton>
            <Skeleton
              h={{
                base: "78px",
                sm: "100px",
                md: "112px",
                lg: "121px",
                xl: "121px",
              }}
              borderRadius={0}
            ></Skeleton>
          </Stack>
        ) : error ? (
          <Box
            h={{ base: "78px", sm: "104px", md: "110px", lg: "121px" }}
            border="2px solid #EDF2F7"
            display="flex"
          >
            <Text
              m="auto"
              p={4}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}
            >
              Error al cargar las sesiones
            </Text>
          </Box>
        ) : (
          data &&
          data.map((item, index) => {
            const day = new Date(item.startDate);
            if (day.getDay() === currentDay) {
              isEmptyDay = false;
              return (
                <TrainingCard
                  key={index}
                  trainingId={item.trainingId}
                  trainingName={item.trainingName}
                  trainingCoach={item.coachName}
                  trainingStart={item.startDate}
                  trainingEnd={item.endDate}
                  trainingCurrentCap={item.remainingSpots}
                  trainingMaxCap={item.maxSpots}
                  trainingLocation={item.location}
                  trainingStatus={item.status}
                  trainingColleagues={item.participants || []}
                  addColleague={addColleague}
                  removeColleague={removeColleague}
                />
              );
            }
            return null;
          })
        )}

        {data && isEmptyDay && (
          <Box
            h={{ base: "78px", sm: "102px", md: "110px", lg: "121px" }}
            border="2px solid #EDF2F7"
            display="flex"
          >
            <Text
              m="auto"
              p={4}
              fontSize={{ base: "lg", sm: "lg", md: "xl", lg: "xl" }}
            >
              No hay sesiones programadas
            </Text>
          </Box>
        )}
      </Stack>
    </>
  );
}
