import {
  Th,
  Td,
  Tfoot,
  Tr,
  Tbody,
  Thead,
  Table,
  TableContainer,
  //Grid,
  //GridItem,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  Stack,
  Box,
  HStack,
  Heading,
  Spacer,
  Divider,
  useToast,
  Text,
  Button,
} from "@chakra-ui/react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect } from "react";
import axios from "axios";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { HiOutlineRefresh } from "react-icons/hi";
import { useAuth } from "../../auth/useAuth";

// if coachId is received, means that admin is requesting that coach stats
export function CoachStats({ coachId, coachPayRate }) {
  const current_month = new Date().getMonth() + 1;
  const [data, setData] = useState([]);
  const [month, setMonth] = useState(current_month);
  const toast = useToast();
  const [refresh, setRefresh] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();

  //TODO: incluir payrate en algún lado

  const monthsList = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    (async () => {
      let baseUrl = "/api/coach/bookings/2024";
      setIsLoading(true);

      // Fetch data from API
      if (coachId) {
        console.log("admin requesting coach stats", coachId);
        baseUrl = "/api/admin/bookings/" + coachId + "/2024";
      }

      await axios
        .get(baseUrl)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        });

      setIsLoading(false);
    })();
  }, [toast, coachId, refresh, auth.user.id]);

  function formatDataToSeries(value) {
    return value
      .flatMap((monthlyData) => monthlyData.trainings) // Aplanamos la lista de ventas
      .reduce((acc, current) => {
        // Buscamos si ya existe una serie para este bonoName
        let serie = acc.find((s) => s.name === current.trainingName);

        if (!serie) {
          // Si no existe, la creamos
          serie = { name: current.trainingName, data: [] };
          acc.push(serie);
        }

        // Añadimos el total al array de data
        serie.data.push(current.total);
        return acc;
      }, []);
  }

  const first_chart = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false, // disables the credits (highcharts.com)
    },
    title: {
      text: "", //"Ingresos 2º semestre bonos julio 2023",
      align: "center",
    },
    xAxis: {
      categories: monthsList,
      crosshair: true,
      accessibility: {
        description: "Facturación anual total y por tipo de bono",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "", //"Moneda: Euros (€)",
      },
    },
    tooltip: {
      valueSuffix: " €",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: data ? formatDataToSeries(data) : [],
  };

  const second_chart = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false, // disables the credits (highcharts.com)
    },
    title: {
      text: "", //"Ingresos 2º semestre bonos julio 2023",
      align: "center",
    },
    xAxis: {
      categories:
        data && data.length > 0
          ? data
              .find((item) => item.month === month)
              .trainings.map((item) => item.trainingName)
          : [],
      //crosshair: true,
      accessibility: {
        description: "Facturación de los bonos para el mes y año indicados",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "", //"Moneda: Euros (€)",
      },
    },
    tooltip: {
      valueSuffix: " €",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        showInLegend: false,
        name: "Facturación",
        data:
          data && data.length > 0
            ? data
                .find((item) => item.month === month)
                .trainings.map((item) => item.total)
            : [],
      },
    ],
  };

  function getTemporalPayrate() {
    switch (auth.user.id) {
      case 1:
        return 0;
      case 2:
        return 3;
      case 3:
        return 4.5;
      case 4:
        return 3.75;
      case 5:
        return 5;
      default:
        return 0;
    }
  }

  return (
    <>
      <Stack
        width={{ base: "90%", sm: "90%", md: coachId ? "100%" : "80%" }}
        margin="auto"
        maxW="1300px"
        spacing={3}
        mb={4}
      >
        <HStack display="flex" justifyContent="space-between">
          <HStack>
            <Text fontWeight={400}>
              €/H:{" "}
              {coachPayRate ? coachPayRate.toFixed(2) : getTemporalPayrate().toFixed(2)}€
            </Text>
          </HStack>
          <HStack mr={-3.5}>
            <Button
              title="Refrescar página"
              variant="ghost"
              colorScheme="black"
              size={{ base: "sm", sm: "sm", md: "md" }}
              onClick={() => setRefresh(!refresh)}
              isLoading={isLoading}
            >
              <HiOutlineRefresh size="1.25em" />
            </Button>
          </HStack>
        </HStack>
        <Divider />
        <HStack display="flex" justifyContent="space-between" mt={2}>
          {/* Button can be displayed on the left hand side if located here and spacer can be deleted*/}
          <Heading fontSize="lg" textAlign={"left"} fontWeight={400}>
            Facturación anual
          </Heading>
          <Spacer />

          <Menu placement="bottom-end">
            <MenuButton size="sm">
              2024&nbsp;
              <ChevronDownIcon />
            </MenuButton>
            <MenuList borderRadius={0} minW="80px" mr={0}>
              <MenuItem isDisabled justifyContent="right">
                2023
              </MenuItem>
              <MenuItem justifyContent="right">2024</MenuItem>
            </MenuList>
          </Menu>
        </HStack>
        {/*<Box>
          <StatGroup spacing={4}>
            <Stat>
              <StatLabel>Ventas</StatLabel>
              <StatNumber>2350,67€</StatNumber>
              <StatHelpText>
                <StatArrow type="increase" />
                23.36%
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Reservas</StatLabel>
              <StatNumber>125</StatNumber>
              <StatHelpText>
                <StatArrow type="decrease" />
                9.05%
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Bonos</StatLabel>
              <StatNumber>85</StatNumber>
              <StatHelpText>
                <StatArrow type="decrease" />
                9.05%
              </StatHelpText>
            </Stat>
          </StatGroup>
        </Box>*/}

        <Box mt={2}>
          <HighchartsReact highcharts={Highcharts} options={first_chart} />
        </Box>
        <Divider />

        <HStack display="flex" justifyContent="space-between" my={3}>
          {/* Button can be displayed on the left hand side if located here and spacer can be deleted*/}
          <Heading fontSize="lg" textAlign={"left"} fontWeight={400}>
            Facturación mensual
          </Heading>
          <Spacer />

          <HStack spacing={4}>
            <Menu placement="bottom-end">
              <MenuButton size="sm">
                {monthsList[month - 1]}&nbsp;
                <ChevronDownIcon />
              </MenuButton>
              <MenuList borderRadius={0} minW="130px">
                <MenuItem
                  onClick={() => setMonth(1)}
                  isDisabled={!(current_month >= 1)}
                >
                  Enero
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(2)}
                  isDisabled={!(current_month >= 2)}
                >
                  Febrero
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(3)}
                  isDisabled={!(current_month >= 3)}
                >
                  Marzo
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(4)}
                  isDisabled={!(current_month >= 4)}
                >
                  Abril
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(5)}
                  isDisabled={!(current_month >= 5)}
                >
                  Mayo
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(6)}
                  isDisabled={!(current_month >= 6)}
                >
                  Junio
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(7)}
                  isDisabled={!(current_month >= 7)}
                >
                  Julio
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(8)}
                  isDisabled={!(current_month >= 8)}
                >
                  Agosto
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(9)}
                  isDisabled={!(current_month >= 9)}
                >
                  Septiembre
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(10)}
                  isDisabled={!(current_month >= 10)}
                >
                  Octubre
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(11)}
                  isDisabled={!(current_month >= 11)}
                >
                  Noviembre
                </MenuItem>
                <MenuItem
                  onClick={() => setMonth(12)}
                  isDisabled={!(current_month >= 12)}
                >
                  Diciembre
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu placement="bottom-end">
              <MenuButton size="sm">
                2024&nbsp;
                <ChevronDownIcon />
              </MenuButton>
              <MenuList borderRadius={0} minW="80px" mr={0}>
                <MenuItem isDisabled justifyContent="right">
                  2023
                </MenuItem>
                <MenuItem justifyContent="right">2024</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>

        {/*<Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(6, 1fr)"
          gap={{ base: 4, sm: 4, md: 8 }}
          mt={3}
        >
          <GridItem colSpan={{ base: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>*/}
        <Box px={{ base: 2, sm: 2, md: 2, lg: 0 }}>
          <TableContainer>
            <Table variant="simple" size="md">
              <Thead>
                <Tr>
                  <Th pt={0} px={0}>
                    ENTRENAMIENTO{" "}
                  </Th>
                  <Th pt={0} px={0} textAlign="center">
                    ASISTENCIA
                  </Th>
                  <Th pt={0} px={0} isNumeric>
                    TOTAL
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.length > 0 &&
                  data
                    .find((item) => item.month === month)
                    .trainings.map((item) => {
                      return (
                        <Tr key={item.trainingName}>
                          <Td cursor="default" py={3} px={0}>
                            {item.trainingName}
                          </Td>
                          <Td py={3} px={0} textAlign="center">
                            {item.assistants}
                          </Td>
                          <Td py={3} px={0} isNumeric>
                            {item.total.toFixed(2)} €
                          </Td>
                        </Tr>
                      );
                    })}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th fontSize="md" px={0}>
                    TOTAL
                  </Th>
                  <Th></Th>
                  <Th fontSize="md" px={0} isNumeric>
                    {data.length > 0 &&
                      data
                        .find((item) => item.month === month)
                        .trainings.reduce(
                          (sum, current) => sum + current.total,
                          0
                        )
                        .toFixed(2)}{" "}
                    €
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Box>
        {/*</GridItem>
          <GridItem colSpan={{ base: 6, sm: 6, md: 6, lg: 3, xl: 3 }}>*/}
        <Box display="none">
          <HighchartsReact highcharts={Highcharts} options={second_chart} />
        </Box>
        {/*</GridItem>
        </Grid>*/}
      </Stack>
    </>
  );
}
