import { useState, useEffect } from "react";

export function useFetch(url, body, token) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [controller, setController] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    setController(abortController);

    fetch(
      process.env.REACT_APP_API_URL + url, // concatenetes the API URL with the endpoint
      { signal: abortController.signal },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: body,
      }
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Cancelled request");
        } else {
          setError(error);
        }
      })
      .finally(() => setLoading(false));

    return () => abortController.abort();
  }, [body, url, token]);

  const handleCancelRequest = () => {
    if (controller) {
      controller.abort();
      setError("Cancelled Request");
    }
  };

  return { data, loading, error, handleCancelRequest };
}

// To call the API use:
// const { data, loading, error, handleCancelRequest } = useFetch("insert_url_here");

// To cancel the request use:
// <button onClick={handleCancelRequest}>Cancel Request</button>