import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)  // Carga traducciones desde tu servidor
  .use(LanguageDetector)  // Detecta automáticamente el idioma
  .use(initReactI18next)  // Pasa i18n abajo a react-i18next
  .init({
    fallbackLng: 'es',  // Idioma de respaldo cuando no se detecta uno
    debug: true,
    interpolation: {
      escapeValue: false,  // No necesario para react ya que escapa por defecto
    }
  });

export default i18n;