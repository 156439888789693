import { Heading, Text, Stack, Link, Box } from "@chakra-ui/react";
import { NextCard } from "./NextCard";
import { useEffect } from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { NavLink as ReachLink } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";

export function NextTrainings(props) {
  const fetchNext = async () => {
    const { data } = await axios.get("/api/bookings/next");
    return data;
  };

  const { data: next, refetch } = useQuery("fetchNext", fetchNext, {
    staleTime: 15 * 1000, // 15 segundos
    //cacheTime: 5 * 60 * 1000, // 5 minutos
  });

  useEffect(() => {
    refetch();
  }, [props.refreshNexTrainings, refetch]);

  function getTrainingDate(d) {
    const today = new Date();
    const startDate = new Date(d);
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (
      startDate.getFullYear() === today.getFullYear() &&
      startDate.getMonth() === today.getMonth() &&
      startDate.getDate() === today.getDate()
    ) {
      return "HOY";
    } else if (
      startDate.getFullYear() === tomorrow.getFullYear() &&
      startDate.getMonth() === tomorrow.getMonth() &&
      startDate.getDate() === tomorrow.getDate()
    ) {
      return "MAÑANA";
    } else {
      return startDate.toLocaleDateString("es-ES", {
        //year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }
  }

  return (
    <>
      {next && next.length !== 0 ? (
        <>
          <Box
            pt={"100px"}
            textAlign="justify"
            margin={"auto"}
            maxWidth={"800px"}
          >
            <Heading
              fontFamily={"Oswald"}
              fontWeight={400}
              fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
              lineHeight={"110%"}
              color={"gray.800"}
              p={9}
              pt={4}
              pb={7}
              textAlign={"center"}
            >
              Próximas
              <Text as={"span"} color={"blue.500"}>
                &nbsp;sesiones
              </Text>
            </Heading>
          </Box>

          <Stack
            spacing={2}
            width={{ base: "100%", sm: "100%", md: "90%" }}
            margin="auto"
            maxW="1000px"
            pb={2}
          >
            {next.map((item) => {
              const day = new Date(item.startDate);
              const hours = day.getHours();
              const minutes = day.getMinutes().toString().padStart(2, "0");

              return (
                <NextCard
                  key={item.trainingId}
                  trainingName={item.trainingName}
                  trainingCoach={item.coachName}
                  trainingDate={getTrainingDate(item.startDate)}
                  trainingStart={`${hours}:${minutes}`}
                  trainingClients={item.participants}
                />
              );
            })}

            <Link
              display="flex"
              alignItems="center"
              px={1}
              as={ReachLink}
              to="/activity"
              width="fit-content"
              alignSelf="flex-end"
            >
              Ver más
              <MdOutlineChevronRight className="icon-padding" />{" "}
            </Link>
          </Stack>
        </>
      ) : (
        <Box pt={"100px"}></Box>
      )}
    </>
  );
}
