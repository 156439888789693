import {
  SimpleGrid,
  Text,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Button,
  HStack,
  Center,
  Stack,
  Image,
  Link,
  Box,
} from "@chakra-ui/react";
//import { ReactNode } from "react";
import { NavLink as ReachLink } from "react-router-dom";
import Logo from "../../static/CUC_LOGO.svg";
import FacebookLogo from "../../images/footer/facebook.svg";
import InstagramLogo from "../../images/footer/instagram.svg";
import WhatsappLogo from "../../images/footer/whatsapp.svg";
import TelefonoLogo from "../../images/footer/telefono.svg";
import UbicacionLogo from "../../images/footer/ubicacion.svg";
import EmailLogo from "../../images/footer/email.svg";
import "./style.css";

export function Footer() {
  return (
    <>
      <Center bg="gray.900" fontFamily={"Oswald"}>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 3 }}
          color="white"
          ml={{ base: "60px", sm: 8, md: 0 }}
          py={{ base: 10, sm: 4, md: 10 }}
          w="1600px"
          spacing={10}
        >
          <VStack
            maxW="200px"
            m={{ base: 2, sm: 10, md: "auto" }}
            mb={{ base: 2, sm: 4 }}
          >
              <FormControl isRequired>
                <FormLabel mb={-0.5}>NOMBRE</FormLabel>
                <Input
                  mb={2}
                  name="contact_name"
                  type="text"
                  size="sm"
                  variant="unstyled"
                  placeholder="Tu nombre"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel mb={-0.5}>CORREO</FormLabel>
                <Input
                  mb={2}
                  name="contact_email"
                  type="email"
                  size="sm"
                  variant="unstyled"
                  placeholder="ejemplo@mail.com"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel mb={-0.5}>MENSAJE</FormLabel>
                <Input
                  mb={2}
                  name="contact_message"
                  type="text"
                  size="sm"
                  variant="unstyled"
                  placeholder="Escribe tu mensaje"
                />
              </FormControl>
              <Button
                className="cuc-section-btn"
                bg={"blue.400"}
                color={"white"}
                borderRadius={0}
                _hover={{
                  bg: "blue.600",
                }}
                fontWeight={400}
                size="sm"
                width="100%"
              >
                Enviar
              </Button>
          </VStack>

          <Stack
            spacing={5}
            mx={{ base: 2, sm: 10, md: "auto" }}
            textAlign="left"
            fontWeight={300}
            mt={1}
          >
            <HStack spacing={4} className="footer-links">
              <Box w="20px">
                <Image
                  m="auto"
                  src={TelefonoLogo}
                  width="18px"
                  height="18px"
                  alt="Logo teléfono"
                />
              </Box>

              <a
                href="https://wa.me/34674088825"
                target="_blank"
                rel="noreferrer"
              >
                <Text>+34 674 088 825</Text>
              </a>
            </HStack>
            <HStack spacing={4} className="footer-links">
              <Box w="20px">
                <Image
                  m="auto"
                  src={UbicacionLogo}
                  width="16px"
                  height="23px"
                  alt="Logo localización"
                />
              </Box>
              <a
                href="https://goo.gl/maps/wiwarSmDqsMRfiAc8"
                target="_blank"
                rel="noreferrer"
              >
                <Text>C/ Martí Rubi, 19 - Sa Cabaneta</Text>
              </a>
            </HStack>
            <HStack spacing={4}>
              <Box w="20px">
                <Image
                  m="auto"
                  src={EmailLogo}
                  width="20px"
                  height="16px"
                  alt="Logo email"
                  mt={1}
                />
              </Box>
              <Text>cucfitness@gmail.com</Text>
            </HStack>

            <HStack pt={4} spacing={7}>
              <a
                href="https://wa.me/34674088825"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={WhatsappLogo}
                  width="40px"
                  height="40px"
                  alt="Logo whatsapp"
                />
              </a>
              <a
                href="https://www.facebook.com/cucfitness/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={FacebookLogo}
                  width="40px"
                  height="40px"
                  alt="Logo facebook"
                />
              </a>
              <a
                href="https://www.instagram.com/cucfitness/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={InstagramLogo}
                  width="40px"
                  height="40px"
                  alt="Logo instagram"
                />
              </a>
            </HStack>
          </Stack>

          <Stack
            textAlign="left"
            m={{ base: 2, sm: 10, md: "auto" }}
            mt={{ base: 2, sm: 4 }}
            className="footer-links"
          >
            <Stack spacing={-0.5} mb={1}>
              <Link as={ReachLink} to="/about" fontWeight="400">
                Quiénes somos
              </Link>
              <Link as={ReachLink} to="/careers" fontWeight="400">
                Trabaja con nosotros
              </Link>
            </Stack>

            <Stack spacing={-0.5} fontWeight={300}>
              <Link as={ReachLink} to="/cookies">
                Cookies
              </Link>
              <Link as={ReachLink} to="/privacy">
                Privacidad
              </Link>
              <Link as={ReachLink} to="/terms">
                Condiciones generales
              </Link>
            </Stack>

            <Stack textAlign="left" pt={2}>
              <Image src={Logo} width="80px" height="54px" alt="CUC logo" />
              <Text fontSize="10px" fontWeight={300}>
                © {new Date().getFullYear()} CUC Fitness Studio
              </Text>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Center>
    </>
  );
}
