import {
  Text,
  Input,
  FormControl,
  FormLabel,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  Spacer,
  Box,
  Avatar,
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Badge,
  useToast,
} from "@chakra-ui/react";
import "./style.css";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import ConfirmationModal from "../ConfirmationModal";
import { MdOutlineDiscount, MdOutlineSearch, MdClose } from "react-icons/md";
import { LuCalculator } from "react-icons/lu";
import {
  MdOutlineBookmarkRemove,
  MdOutlineBookmarkAdded,
} from "react-icons/md";
import axios from "axios";

export function ModalBono({
  isOpen,
  onClose,
  bonoTypesList,
  usersList,
  bonoRequested,
  bonoPayment,
}) {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [isConfirmActivateModalOpen, setIsConfirmActivateModalOpen] =
    useState(false);
  const [isConfirmRejectModalOpen, setisConfirmRejectModalOpen] =
    useState(false);
  const [isConfirmPaymentModalOpen, setIsConfirmPaymentModalOpen] =
    useState(false);
  const [paymentMethodDisabled, setPaymentMethodDisabled] = useState(false);
  const [paymentDateDisabled, setPaymentDateDisabled] = useState(false);
  const [discountDisabled, setDiscountDisabled] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isActivateLoading, setIsActivateLoading] = useState(false);
  const toast = useToast();

  const {
    control,
    handleSubmit,
    register: eventRegister,
    setValue,
    reset,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      activationDate: getInitDate(),
      paymentDate: getInitDate(),
      expirationDate: getExpirationDate(),
      discount: bonoPayment ? bonoPayment.discount : 0,
      userId: bonoRequested || bonoPayment ? 1 : null,
      bonoTypeId: bonoRequested ? bonoRequested.bonoTypeId : null,
      remainingSessions: bonoRequested ? bonoRequested.remainingSessions : null,
      pvpAmount: bonoRequested
        ? bonoRequested.total
        : bonoPayment
        ? bonoPayment.pvp
        : null,
      totalAmount: bonoRequested
        ? bonoRequested.total
        : bonoPayment
        ? bonoPayment.total
        : null,
    },
    shouldFocusError: false,
  });

  useEffect(() => {
    if (usersList.length === 1) {
      setUserSelected(usersList[0]);
    } else {
      setUsers(usersList);
      setSearch(usersList);
    }
  }, [usersList]);

  function getInitDate() {
    return new Date().toISOString().split("T")[0];
  }

  function getExpirationDate() {
    var expirationDate = new Date();
    expirationDate.setDate(new Date().getDate() + 30);
    return expirationDate.toISOString().split("T")[0];
  }

  function updatePaymentMethod(e) {
    switch (e.target.value) {
      case "FREE":
        setDiscountDisabled(true);
        setValue("discount", 100.0);
        setValue("totalAmount", 0);
        break;

      case "OUTSTANDING":
        setPaymentDateDisabled(true);
        break;

      default:
        setDiscountDisabled(false);
        setPaymentDateDisabled(false);
        if (parseInt(getValues("discount")) === 100) setValue("discount", 0);
        setValue(
          "totalAmount",
          (
            getValues("pvpAmount") -
            (getValues("pvpAmount") * getValues("discount")) / 100
          ).toFixed(2)
        );
    }
  }

  function updatePvpPrice(e) {
    if (!e.target.value) {
      setValue("remainingSessions", 0);
      setValue("pvpAmount", null);
      setValue("totalAmount", null);
      return;
    }

    let bonoType = bonoTypesList.filter(
      (item) => item.id === parseInt(e.target.value)
    )[0];

    setValue("remainingSessions", bonoType.sessions);
    setValue("pvpAmount", bonoType.price);
    setValue(
      "totalAmount",
      (
        getValues("pvpAmount") -
        (getValues("pvpAmount") * getValues("discount")) / 100
      ).toFixed(2)
    );
  }

  function setNewPrice(e) {
    let discount = getValues("discount") > 100 ? 100 : getValues("discount");

    if (isNaN(discount)) {
      discount = 0;
      setValue("discount", 0);
    }

    if (parseFloat(discount) === parseFloat(100)) {
      setValue("paymentStatus", "FREE");
      setPaymentMethodDisabled(true);
    } else {
      setPaymentMethodDisabled(false);
    }

    setValue(
      "totalAmount",
      (
        getValues("pvpAmount") -
        (getValues("pvpAmount") * discount) / 100
      ).toFixed(2)
    );

    if (getValues("paymentStatus") === "FREE" && discount < 100) {
      setValue("paymentStatus", null);
    }
  }

  function handleClose(refresh) {
    onClose(refresh);
    setPaymentMethodDisabled(false);
    setDiscountDisabled(false);
    setPaymentDateDisabled(false);
    setUserSelected(null);
    setTimeout(() => reset(), 100); // avoids fields to flicker
  }

  async function handleConfirmActivateModal(response) {
    setIsActivateLoading(true);
    setIsConfirmActivateModalOpen(false);

    if (response) {
      // API CALL HERE
      if (bonoRequested) {
        console.log("Activar bono", bonoRequested.bonoId);
        await axios
          .put("/api/admin/bono/activate/" + bonoRequested.bonoId, {
            paymentDate: new Date(getValues("paymentDate")),
            paymentStatus: getValues("paymentStatus"),
            discount: parseFloat(getValues("discount")),
            bonoTypeId: parseInt(getValues("bonoTypeId")),
            remainingSessions: getValues("remainingSessions"),
            activationDate: new Date(getValues("activationDate")),
            expirationDate: new Date(getValues("expirationDate")),
          })
          .then(() => {
            //TODO: SET IS LOADING!!
            toast({
              title: "¡Bono activado! 🚀",
              description: "",
              position: "bottom-right",
              duration: 4000,
              isClosable: true,
            });

            handleClose(true); // refreshes the list
          })
          .catch((error) => {
            toast({
              title: "¡Error inesperado!",
              description: error.message,
              status: "error",
              position: "bottom-right",
              duration: 4000,
              isClosable: true,
            });
          });
      } else {
        console.log("Crear bono", userSelected);

        await axios
          .post("/api/admin/bono/" + userSelected.id, {
            paymentDate: new Date(getValues("paymentDate")),
            paymentStatus: getValues("paymentStatus"),
            discount: parseFloat(getValues("discount")),
            bonoTypeId: parseInt(getValues("bonoTypeId")),
            remainingSessions: getValues("remainingSessions"),
            activationDate: new Date(getValues("activationDate")),
            expirationDate: new Date(getValues("expirationDate")),
          })
          .then(() => {
            //TODO: SET IS LOADING!!
            toast({
              title: "¡Bono creado con éxito! 🚀",
              description: "",
              position: "bottom-right",
              duration: 4000,
              isClosable: true,
            });

            handleClose(true); // refreshes the list
          })
          .catch((error) => {
            toast({
              title: "¡Error inesperado!",
              description: error.message,
              status: "error",
              position: "bottom-right",
              duration: 4000,
              isClosable: true,
            });
          });
      }

      //handleClose();
    }
    setIsActivateLoading(false);
  }

  async function handleConfirmRejectModal(response) {
    setIsRejectLoading(true);
    setisConfirmRejectModalOpen(false);

    if (response) {
      await axios
        .put("/api/admin/bono/reject/" + bonoRequested.bonoId)
        .then(() => {
          toast({
            title: "Bono rechazado con éxito 🔥",
            description: "",
            position: "bottom-right",
            duration: 4000,
            isClosable: true,
          });

          handleClose(true);
        })
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        });
    }

    setIsRejectLoading(false);
  }

  function handleConfirmPaymentModal(response) {
    setIsConfirmPaymentModalOpen(false);

    if (response) {
      axios
        .put("/api/admin/bono/payment/" + bonoPayment.bonoId, {
          paymentDate: new Date(getValues("paymentDate")),
          paymentStatus: getValues("paymentStatus"),
          discount: parseFloat(getValues("discount")),
        })
        .then(() => {
          toast({
            title: "¡Pago actualizado! 🚀",
            description: "",
            position: "bottom-right",
            duration: 4000,
            isClosable: true,
          });
          handleClose(true);
        })
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 4000,
            isClosable: true,
          });
        });
    }
  }

  function onSubmit() {
    if (
      getValues("discount") !== 100 &&
      getValues("paymentStatus") === "FREE"
    ) {
      setError("paymentStatus", { type: "manual" }); // ERROR because payment method cannot be free
      return;
    }

    if (bonoPayment) {
      setIsConfirmPaymentModalOpen(true);
    } else {
      setIsConfirmActivateModalOpen(true);
    }
  }

  function onErrors(errors) {
    console.log("FORM ERRORS:", errors);
  }

  function handleSelectUser(e) {
    const element = e.target.closest(".prevent-select");

    if (element.getAttribute("data-user-selectable") === "false") return;

    console.log(
      "Participante seleccionado",
      element.id,
      "- JSON: ",
      element.getAttribute("data-user")
    );

    setValue("userId", parseInt(element.id));
    setUserSelected(JSON.parse(element.getAttribute("data-user")));
  }

  function handleType(e) {
    const value = e.target.value;
    const filteredUsers = users.filter(
      (user) =>
        user.name.toLowerCase().includes(value.toLowerCase()) ||
        user.surname1.toLowerCase().includes(value.toLowerCase()) ||
        user.surname2.toLowerCase().includes(value.toLowerCase())
    );

    setSearch(filteredUsers);
  }

  return (
    <>
      <Modal
        onClose={handleClose}
        isOpen={isOpen}
        isCentered
        size={{ base: "sm", sm: "sm", md: "md" }}
        borderRadius={"0px"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={2} pb={2} textAlign={"left"}>
            <Text>
              {bonoRequested
                ? "Solicitud de activación"
                : !bonoPayment
                ? "Añadir nuevo bono"
                : "Detalles del pago"}
            </Text>
          </ModalHeader>

          <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <ModalBody>
              {isConfirmActivateModalOpen && (
                <ConfirmationModal
                  title="Confirmación"
                  message="¿Estás seguro que deseas activar el bono?"
                  yesButton="Sí, continuar"
                  optionalButton="Seguir editando"
                  onConfirm={handleConfirmActivateModal}
                />
              )}
              {isConfirmRejectModalOpen && (
                <ConfirmationModal
                  title="Confirmación"
                  message="¿Estás seguro que deseas rechazar la solicitud de bono?"
                  yesButton="Sí, rechazar"
                  optionalButton="Cancelar"
                  isDangerous
                  onConfirm={handleConfirmRejectModal}
                />
              )}
              {isConfirmPaymentModalOpen && (
                <ConfirmationModal
                  title="Confirmación"
                  message="¿Estás seguro que deseas actualizar el pago?"
                  yesButton="Sí, actualizar"
                  optionalButton="Seguir editando"
                  onConfirm={handleConfirmPaymentModal}
                />
              )}

              <HStack>
                <FormControl isInvalid={errors.userId}>
                  <FormLabel htmlFor="userId" fontSize="sm">
                    Usuario
                  </FormLabel>
                  <Box max-width="336px" position="relative">
                    {!userSelected ? (
                      <>
                        <Box>
                          <InputGroup>
                            <InputLeftElement pointerEvents="none" mt={"-3px"}>
                              <MdOutlineSearch />
                            </InputLeftElement>
                            <InputRightElement mr={0} mt={"-3px"}>
                              <MdClose cursor="pointer" />
                            </InputRightElement>
                            <Input
                              id="userId"
                              {...eventRegister("userId", {
                                required: "Obligatorio",
                              })}
                              borderBlockEndStyle={"4px yellow solid"}
                              type="text"
                              size="sm"
                              placeholder="Buscar..."
                              onChange={handleType}
                              autoComplete="off"
                              onFocus={() => setInputFocused(true)}
                              onBlur={() =>
                                setTimeout(() => setInputFocused(false), 100)
                              }
                            />
                          </InputGroup>
                        </Box>

                        <Stack
                          mt={"0.5px"}
                          spacing={0}
                          border="1px solid #e2e8f0"
                          width="100%"
                          maxH="154px"
                          overflow="auto"
                          position="absolute"
                          zIndex={10}
                          bgColor="white"
                          className=""
                          display={isInputFocused ? "flex" : "none"}
                        >
                          {search && search.length > 0
                            ? search.map((user) => (
                                <HStack
                                  key={user.id}
                                  id={user.id}
                                  className="prevent-select user-box"
                                  data-user={JSON.stringify(user)}
                                  onClick={handleSelectUser}
                                  cursor="pointer"
                                  w="100%"
                                  px={2.5}
                                  py={2}
                                >
                                  <Avatar
                                    size="sm"
                                    src={user.avatar}
                                    name={`${user.name} ${user.surname1}`.trim()}
                                  />
                                  <Text id="avatar-text" ml={1}>
                                    {user.name + " " + user.surname1}
                                  </Text>
                                  <Spacer />
                                  {user.activeBonos &&
                                    user.activeBonos.length > 0 && (
                                      <Badge
                                        mr={1}
                                        colorScheme={
                                          user.activeBonos.length === 1
                                            ? "blue"
                                            : "red"
                                        }
                                        variant="solid"
                                        fontWeight="400"
                                        fontFamily=""
                                      >
                                        ACTIVO
                                      </Badge>
                                    )}
                                </HStack>
                              ))
                            : null}
                        </Stack>
                      </>
                    ) : (
                      <>
                        <Input
                          id="userId"
                          {...eventRegister("userId", {
                            required: "Obligatorio",
                          })}
                          type="text"
                          size="sm"
                          display="none"
                        />
                        <HStack
                          id={userSelected.id}
                          className="prevent-select"
                          border={"1px solid #e2e8f0"}
                          p={"3px"}
                          pl={3}
                        >
                          <Avatar
                            size="2xs"
                            src={userSelected.avatar}
                            name={`${userSelected.name} ${userSelected.surname1}`.trim()}
                          />
                          <Text id="avatar-text">
                            {userSelected.name +
                              " " +
                              userSelected.surname1 +
                              " " +
                              userSelected.surname2}
                          </Text>
                          <Spacer />
                          {!bonoRequested && !bonoPayment && (
                            <Button
                              size="xs"
                              onClick={() => {
                                setValue("userId", null);
                                setUserSelected(null);
                              }}
                            >
                              Editar
                            </Button>
                          )}
                        </HStack>
                      </>
                    )}
                  </Box>
                </FormControl>
              </HStack>
              {(bonoRequested || !bonoPayment) && (
                <>
                  <HStack pt={4}>
                    <FormControl isInvalid={errors.bonoTypeId}>
                      <FormLabel htmlFor="bonoTypeId" fontSize="sm">
                        Bono
                      </FormLabel>
                      <Select
                        id="bonoTypeId"
                        placeholder="Selecciona un bono"
                        size="sm"
                        {...eventRegister("bonoTypeId", {
                          required: "Obligatorio",
                        })}
                        onChange={
                          (e) => updatePvpPrice(e) //console.log("change")
                        }
                      >
                        {bonoTypesList &&
                          bonoTypesList.map((item) => {
                            return (
                              <option key={item.id} value={parseInt(item.id)}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Select>
                    </FormControl>

                    <FormControl isInvalid={errors.remainingSessions}>
                      <FormLabel htmlFor="remainingSessions" fontSize="sm">
                        Sesiones
                      </FormLabel>
                      <Controller
                        name="remainingSessions"
                        control={control}
                        rules={{ required: "Obligatorio" }}
                        defaultValue={parseInt(0) || 0} // Ensure a valid number is set as default
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <NumberInput
                            id="remainingSessions"
                            placeholder="Elige una sesión"
                            size="sm"
                            onBlur={onBlur} // Handle blur events
                            value={value || 0} // Ensure the value is not null or undefined
                            onChange={(valueAsString, valueAsNumber) =>
                              onChange(valueAsNumber)
                            } // Use the numeric value for onChange
                            min={-1}
                            max={100}
                          >
                            <NumberInputField ref={ref} name={name} />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        )}
                      />
                    </FormControl>
                  </HStack>

                  <HStack pt={4}>
                    <FormControl isInvalid={errors.activationDate}>
                      <FormLabel htmlFor="activationDate" fontSize="sm">
                        Fecha de activación
                      </FormLabel>
                      <Input
                        id="activationDate"
                        type="date"
                        placeholder="Fecha inicio de la activación"
                        size="sm"
                        {...eventRegister("activationDate", {
                          required: "Obligatorio",
                        })}
                      />
                    </FormControl>

                    <FormControl isInvalid={errors.expirationDate}>
                      <FormLabel htmlFor="expirationDate" fontSize="sm">
                        Fecha de caducidad
                      </FormLabel>
                      <Input
                        id="expirationDate"
                        type="date"
                        placeholder="Fecha de caducidad del bono"
                        size="sm"
                        {...eventRegister("expirationDate", {
                          required: "Obligatorio",
                        })}
                      />
                    </FormControl>
                  </HStack>
                </>
              )}

              {(bonoRequested || !bonoPayment) && (
                <Text fontSize="md" mt={6} fontWeight={500} textAlign="left">
                  Detalles del pago
                </Text>
              )}
              <HStack pt={4}>
                <FormControl isInvalid={errors.paymentDate}>
                  <FormLabel htmlFor="paymentDate" fontSize="sm">
                    Fecha de pago
                  </FormLabel>
                  <Input
                    id="paymentDate"
                    type="date"
                    placeholder="Fecha de pago"
                    size="sm"
                    isDisabled={paymentDateDisabled}
                    {...eventRegister("paymentDate", {
                      required: "Obligatorio",
                    })}
                  />
                </FormControl>
                <FormControl isInvalid={errors.paymentStatus}>
                  <FormLabel htmlFor="paymentStatus" fontSize="sm">
                    Método de pago
                  </FormLabel>
                  <Select
                    id="paymentStatus"
                    placeholder="Elige un método"
                    size="sm"
                    {...eventRegister("paymentStatus", {
                      required: "Obligatorio",
                    })}
                    defaultValue={bonoPayment ? "OUTSTANDING" : null}
                    isDisabled={paymentMethodDisabled}
                    onChange={(e) => updatePaymentMethod(e)}
                  >
                    <option value="OUTSTANDING">Pendiente</option>
                    <option value="CARD_PAID">Tarjeta</option>
                    <option value="CASH_PAID">Efectivo</option>
                    <option value="BIZUM_PAID">Bizum</option>
                    <option value="BANK_TRANSFER_PAID">Transferencia</option>
                    <option value="FREE">Gratis</option>
                  </Select>
                </FormControl>
              </HStack>
              <HStack pt={4}>
                <FormControl isInvalid={errors.discount}>
                  <FormLabel htmlFor="discount" fontSize="sm">
                    Descuento
                  </FormLabel>
                  <Controller
                    name="discount"
                    control={control}
                    rules={{ required: "Obligatorio" }}
                    //defaultValue={""} // Ensure a valid number is set as default
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <InputGroup>
                        <InputRightElement
                          pointerEvents="none"
                          fontSize="1em"
                          top="50%"
                          transform="translateY(-50%)"
                        >
                          <MdOutlineDiscount />
                        </InputRightElement>
                        <NumberInput
                          id="discount"
                          w="100%"
                          placeholder="Opcional"
                          size="sm"
                          onBlur={onBlur} // Handle blur events
                          isDisabled={discountDisabled}
                          value={value || 0.0} // Ensure the value is not null or undefined
                          onChange={(valueAsString) => {
                            onChange(valueAsString);
                            setNewPrice();
                          }} // Use the numeric value for onChange
                          min={0}
                          max={100}
                          precision={2}
                          step={0.2}
                        >
                          <NumberInputField ref={ref} name={name} />
                        </NumberInput>
                      </InputGroup>
                    )}
                  />
                </FormControl>
                <FormControl isInvalid={errors.totalAmount}>
                  <FormLabel htmlFor="totalAmount" fontSize="sm">
                    Total
                  </FormLabel>
                  <Controller
                    name="totalAmount"
                    control={control}
                    rules={{ required: "Obligatorio" }}
                    //defaultValue={""} // Ensure a valid number is set as default
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <InputGroup>
                        <InputRightElement
                          pointerEvents="none"
                          fontSize="1em"
                          top="50%"
                          transform="translateY(-50%)"
                        >
                          <LuCalculator />
                        </InputRightElement>
                        <NumberInput
                          id="totalAmount"
                          w="100%"
                          placeholder="Opcional"
                          size="sm"
                          isReadOnly
                          onBlur={onBlur} // Handle blur events
                          isDisabled={discountDisabled}
                          value={value || 0.0} // Ensure the value is not null or undefined
                          onChange={(valueAsString) => {
                            onChange(valueAsString);
                            setNewPrice();
                          }} // Use the numeric value for onChange
                          min={0}
                          max={100}
                          precision={2}
                          step={0.2}
                        >
                          <NumberInputField ref={ref} name={name} />
                        </NumberInput>
                      </InputGroup>
                    )}
                  />
                </FormControl>
              </HStack>
            </ModalBody>
            <ModalFooter pt={3}>
              {bonoRequested && (
                <Button
                  title={`Rechazar bono`}
                  onClick={() => setisConfirmRejectModalOpen(true)}
                  borderRadius={0}
                  colorScheme="red"
                  variant="solid"
                  display={"flex"}
                  isLoading={isRejectLoading} //  TODO: APLICAR ESTO A TODOS LOS BOTONES Y A LOS CAMPOS!!!
                  isDisabled={isActivateLoading}
                >
                  Borrar&nbsp;
                  <MdOutlineBookmarkRemove />
                </Button>
              )}
              <Spacer />
              <Button
                onClick={handleClose}
                borderRadius={0}
                alt="Cancelar"
                isDisabled={isRejectLoading || isActivateLoading}
                mr={2}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant={"outline"}
                colorScheme="blue"
                borderRadius={0}
                isLoading={isActivateLoading}
                isDisabled={isRejectLoading}
                alt="Guardar sesión"
              >
                {bonoRequested
                  ? "Activar"
                  : !bonoPayment
                  ? "Activar"
                  : "Guardar"}
                &nbsp;
                <MdOutlineBookmarkAdded />
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
