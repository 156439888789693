import * as React from "react";
import { ChakraProvider, Box, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

export function Careers() {
  return (
    <>
      <Helmet>
        <title>Trabaja con nosotros - CUC Fitness Studio</title>
        <meta
          name="description"
          content="¡Únete a nuestro equipo de trabajo!"
        />
      </Helmet>

      <ChakraProvider>
        <Box
          pt={"100px"}
          pb={8}
          textAlign="justify"
          margin={"auto"}
          width="80%"
          maxWidth={"1100px"}
        >
          <Heading
            fontFamily={"Oswald"}
            fontWeight={500}
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color={"gray.800"}
            textAlign={"center"}
            mt={"20px"}
            mb={2}
          >
            Trabaja con{" "}
            <Text as={"span"} color={"blue.500"}>
              nosotros
            </Text>
          </Heading>
          <Box mt={3}>
            <Text
              textAlign="left"
              fontFamily={"Oswald"}
              fontWeight={500}
              color="blue.400"
              mb={2}
              mt={5}
            >
              ¿ESTÁS BUCANDO TRABAJO?
            </Text>
            <Text mb={6}>
              En CUC Fitness Studio, no solo construimos músculos; construimos
              sueños. Estamos en busca de individuos apasionados, energicos y
              comprometidos con el bienestar y la salud para unirse a nuestro
              equipo. Si te apasiona el fitness, te encanta inspirar a los demás
              y estás listo para llevar tu carrera al próximo nivel, ¡queremos
              conocerte!
            </Text>
            <Text mb={2}>
              Envíanos tu CV a cucfitness@gmail.com para que le echemos un
              vistazo. Enseguida que podamos nos pondremos en contacto contigo.
            </Text>
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Careers;
