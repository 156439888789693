import {
  Box,
  Center,
  Text,
  Stack,
  HStack,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { BonoActivity } from "../Activity/UserBonos/BonoActivityCard";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import {
  MdOutlineMode,
  MdAddShoppingCart,
  MdVerified,
  MdOutlineShoppingCart,
} from "react-icons/md";
import ConfirmationModal from "../ConfirmationModal";
import { useAuth } from "../../auth/useAuth";
// TODO: Add link to bono

//const IMAGE =  "https://img.freepik.com/foto-gratis/personas-que-trabajan-gimnasio_53876-13559.jpg";

export function Bono(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast(); // to display toasts
  const auth = useAuth();

  async function requestBono() {
    setIsLoading(true);

    await axios
      .post("api/bono/request/" + props.bonoId)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "¡Bono solicitado!",
            description: "Te notificaremos cuando esté activo",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "¡Error inesperado!",
            description: "No se ha podido solicitar el bono",
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  }

  function openRequestModal(e) {
    if (!auth.isLogged()) {
      // Bono cannot be requested, therefore modal to request it is not dislayed
      document.getElementById("menu-button-log-button").click();
      return;
    }

    onOpen();
  }

  function handleConfirmModal(value) {
    setIsConfirmModalOpen(false);

    if (value) {
      requestBono();
    }
  }

  return (
    <>
      <Center pt={5} mb={auth.isLogged() ? 2 : 8} key={props.bonoId}>
        <Box
          role={"group"}
          pt={5}
          px={5}
          pb={5}
          bg="white"
          boxShadow={"xl"}
          rounded={"sm"}
          zIndex={1}
          w="300px"
          mx={3}
          mb={!props.removePadding ? 7 : 10}
          _hover={{
            transition: "all 0.5s",
          }}
          onClick={openRequestModal}
          className="prevent-select bono-card"
          cursor={"pointer"}
        >
          <Box
            style={{ textDecoration: "none" }}
            className="textWithBlurredBgBono"
          >
            <img
              className="bono-img"
              src={props.bonoImage}
              alt={props.bonoName + " logo"}
              width="260px"
              height="260px"
            />
            {props.bonoAdmin ? (
              <MdOutlineMode size={70} pl={2} />
            ) : (
              <MdAddShoppingCart size={70} pl={-5} />
            )}
          </Box>

          <Stack mt={1} spacing={2} fontFamily="Oswald">
            <Box>
              <HStack justifyContent="center">
                <Text
                  fontWeight="400"
                  fontSize="30px"
                  color="black"
                  pl={props.bonoAdmin ? 4 : 0}
                >
                  {props.bonoName}
                </Text>
                {props.bonoAdmin && (
                  <MdVerified size="22px" title="Bono activo" />
                )}
              </HStack>
              <HStack mt={-3} justifyContent="center" fontFamily={"system-ui"}>
                <Text fontSize="3xl" fontWeight="500" color="gray.800">
                  €
                </Text>
                <Text
                  fontSize="6xl"
                  fontWeight="500"
                  color="gray.800"
                  fontFamily={"Oswald"}
                >
                  {props.bonoPrice}
                </Text>
                <Text
                  fontSize="3xl"
                  fontWeight="100"
                  color="gray.500"
                  display={"flex"}
                  ml={0}
                >
                  /
                </Text>
                <Text
                  fontSize="3xl"
                  fontWeight="100"
                  color="gray.500"
                  ml={"-6px !important"}
                >
                  {props.bonoName === "CUC Day" ? "día" : "mes"}
                </Text>
              </HStack>
              <Text
                color={"gray.600"}
                fontWeight={400}
                fontSize={"sm"}
                fontFamily={"system-ui"}
              >
                {props.bonoDescription}
              </Text>
            </Box>

            <Box minH={"50px"}>
              {Object.keys(props.bonoInclusions).length !== 0 &&
                props.bonoInclusions.map((item) => (
                  <Badge
                    mx={0.5}
                    key={item}
                    variant="subtle"
                    className="badge-color"
                  >
                    {item}
                  </Badge>
                ))}
            </Box>
          </Stack>
        </Box>
      </Center>

      {/* USAR CONFIRMATION MODAL */}
      {isConfirmModalOpen && (
        <ConfirmationModal
          title="Solicitud de bono"
          message="Por el momento no aceptamos pagos telemáticos por lo que deberás abonar el importe total en el local. ¿Deseas solicitar la activación del bono? "
          yesButton="Sí, solicitar"
          optionalButton="Cancelar"
          onConfirm={handleConfirmModal}
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={!isLoading} // avoids closing when clicking outside the dialog
        size="sm"
        borderRadius={"0px"}
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"left"} pb={0}>
            Adquirir bono
          </ModalHeader>
          <ModalBody pb={0}>
            <Stack spacing={3} mb={0.5}>
              <BonoActivity
                bonoRequest
                key={props.id}
                bonoId={props.bonoId}
                bonoImage={props.bonoImage}
                bonoName={props.bonoName}
                bonoSessions={props.bonoSessions}
                bonoPrice={props.bonoPrice}
              />
              <Text
                fontSize={"xs"}
                textAlign="justify" /*width={{ base: "200px", sm: "200px", md: "100%", lg:"100%" }}*/
              >
                Matrícula no incluida (30€). La matricula debe
                volver a abonarse en caso de no renovar el bono.
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button onClick={onClose} isDisabled={isLoading}>
              Cancelar
            </Button>
            <Spacer />
            <Button
              variant={"outline"}
              onClick={() => setIsConfirmModalOpen(true)}
              colorScheme="blue"
              isLoading={isLoading}
            >
              Comprar&nbsp;
              <MdOutlineShoppingCart />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}