import React from "react";
import { BonoActivity } from "./BonoActivityCard";
import { SimpleGrid, Skeleton, Text, Box, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";

export function UserBonos() {
  const [bonos, setBonos] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/api/bonos");
      setBonos(data);
      
    })();
  }, []);

  return (
    <>
      <SimpleGrid
        id="my-bono-grid"
        columns={{ md: 2, lg: 3, xl: 3, "2xl": 3 }}
        spacing={4}
        width={{ base: "100%", sm: "100%", md: "80%" }}
        maxW="1300px"
        m="auto"
      >
        {!bonos && (
          <>
            <Skeleton height="144px" width="100%" borderRadius={0} />
            <Skeleton height="144px" width="100%" borderRadius={0} />
          </>
        )}

        {bonos &&
          bonos.length > 0 &&
          bonos.map((item) => {
            return (
              <BonoActivity
                key={item.bonoId}
                bonoName={item.bonoTypeName}
                bonoImage={item.bonoTypeImage}
                bonoStatus={item.bonoStatus}
                bonoPaymentStatus={item.paymentStatus}
                bonoSessions={item.remainingSessions}
                bonoTotal={item.totalAmount}
                bonoDiscount={item.discount}
                bonoExpirationDate={
                  item.expirationDate ? item.expirationDate : null
                }
              />
            );
          })}
      </SimpleGrid>

      {bonos && bonos.length === 0 && (
        <Box
          h={{ base: "78px", sm: "104px", md: "110px", lg: "121px" }}
          border="2px solid #EDF2F7"
          display="flex"
          width={{sm: "100%", md: "80%"}}
          maxW="1300px"
          m="auto"
        >
          <Stack m="auto">
            <Text fontSize={{ base: "md", sm: "lg", md: "xl", lg: "xl" }}>
              ¡Solicita tu primer bono ya!
            </Text>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default UserBonos;
