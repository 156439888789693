import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Link,
  FormControl,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputRightElement,
  Stack,
  HStack,
  Show,
  Hide,
  Text,
  Tooltip,
  InputLeftAddon,
  Divider,
  Alert,
  AlertIcon,
  AlertDescription,
  Spacer,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import { useTranslation } from "react-i18next";

// TODO: apply react-hook-form

// Checks if an string is empty
function isEmpty(str) {
  return !str || str.length === 0;
}

// FIRST FORM: NAME, SURNAME1, SURNAME2
var name = false;
var surname1 = false;
var surname2 = "";
const Form1 = ({ onAction, appView }) => {
  const [next, setNext] = useState(!isEmpty(name) && !isEmpty(surname1));

  // onInput listener: updates the attributes whilst typing
  function nameFunc(e) {
    e.target.value !== "" ? (name = e.target.value) : (name = false);
    name && surname1 ? setNext(true) : setNext(false);
  }

  function surname1Func(e) {
    e.target.value !== "" ? (surname1 = e.target.value) : (surname1 = false);
    name && surname1 ? setNext(true) : setNext(false);
  }

  function surname2Func(e) {
    surname2 = e.target.value; // because it is an optional attribute
  }

  // onPressed: to handle tab and enter actions when completing the forms
  function namePressed(event) {
    if (event.key === "Tab") {
      document.getElementById("sup-surname1").focus();
      return;
    }

    if (event.key === "Enter") {
      document.getElementById("sup-next1").click();
      return;
    }
  }

  function surname1Pressed(event) {
    if (event.key === "Tab") {
      document.getElementById("sup-surname2").focus();
      return;
    }

    if (event.key === "Enter") {
      document.getElementById("sup-next1").click();
      return;
    }
  }

  function surname2Pressed(event) {
    if (event.key === "Tab") {
      document.getElementById("sup-next1").focus();
      return;
    }

    if (event.key === "Enter") {
      document.getElementById("sup-next1").click();
      return;
    }
  }

  return (
    <>
      <FormControl id="sup-name" isRequired>
        <FormLabel>Nombre</FormLabel>
        <Input
          type="text"
          placeholder="Introduce tu nombre"
          onChange={nameFunc}
          defaultValue={name ? name : ""}
          onKeyDown={!appView ? namePressed : null}
        />
      </FormControl>
      <HStack pt={2} w="100%">
        <Box w="50%">
          <FormControl id="sup-surname1" isRequired>
            <FormLabel>1er apellido</FormLabel>
            <Input
              type="text"
              placeholder="Primer apellido"
              onChange={surname1Func}
              defaultValue={surname1 ? surname1 : ""}
              onKeyDown={!appView ? surname1Pressed : null}
            />
          </FormControl>
        </Box>
        <Box w="50%">
          <FormControl id="sup-surname2">
            <FormLabel>2do apellido</FormLabel>
            <Input
              type="text"
              placeholder="Opcional"
              onChange={surname2Func}
              defaultValue={surname2 ? surname2 : ""}
              onKeyDown={!appView ? surname2Pressed : null}
            />
          </FormControl>
        </Box>
      </HStack>
      <Stack
        direction={{ base: "column", xs: "row" }}
        align={"start"}
        justify={"space-between"}
        mb={2}
      >
        <Text
          variant="link"
          colorScheme="white"
          color={"blue.800"}
          size="xs"
          fontWeight={600}
          fontSize="sm"
          pt={2}
        >
          <Hide below="md">* Campos obligatorios</Hide>
          <Show below="md">* Obligatorio</Show>
        </Text>
        <Link
          as={Text}
          color={"blue.400"}
          pt={2}
          pb={0}
          fontSize="sm"
          onClick={() => console.log("TODO: Llevar al login")}
        >
          ¿Ya eres miembro?
        </Link>
      </Stack>
      {appView && <Spacer />}
      <Tooltip
        hasArrow
        label="¡Rellena los campos obligatorios!"
        bg="blue.800"
        placement="top"
        color="white"
        isDisabled={next}
      >
        <Button
          id="sup-next1"
          isDisabled={!next}
          bg={"blue.400"}
          color={"white"}
          _hover={{
            bg: "blue.600",
          }}
          onClick={() => {
            onAction(true);
          }}
        >
          Siguiente
        </Button>
      </Tooltip>
    </>
  );
};

// SECOND FORM: BIRTHDAY, GENDER
var gender = false;
var birthday = false;
var phone = false;
const Form2 = ({ onAction, appView }) => {
  const [next, setNext] = useState(!isEmpty(gender) && !isEmpty(birthday));
  const { t } = useTranslation();

  function birthdayFunc(event) {
    birthday = event.target.value;
    gender && birthday ? setNext(true) : setNext(false);
  }

  function genderFunc(event) {
    gender = event.target.value;
    gender && birthday ? setNext(true) : setNext(false);
  }

  function phoneFunc(event) {
    phone = event.target.value;
  }

  function birthdayPressed(event) {
    if (event.key === "Tab") {
      document.getElementById("sup-gender").focus();
      return;
    }

    if (event.key === "Enter") {
      document.getElementById("sup-next2").click();
      return;
    }
  }

  function genderPressed(event) {
    if (event.key === "Tab") {
      document.getElementById("sup-phone").focus();
      return;
    }

    if (event.key === "Enter") {
      document.getElementById("sup-next2").click();
      return;
    }
  }

  function phonePressed(event) {
    if (event.key === "Tab") {
      document.getElementById("sup-next2").focus();
      return;
    }

    if (event.key === "Enter") {
      document.getElementById("sup-next2").click();
      return;
    }
  }

  const calculateAge = (birthday) => {
    const [year, month, day] = birthday.split("-").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^\d{9}$/;
    return phoneRegex.test(phone);
  };

  function checkFields() {
    if (!birthday || calculateAge(birthday) < 12) {
      onAction({ code: "RVA-1", message: t("RVA-1") });
      return;
    }

    if (!birthday || calculateAge(birthday) > 100) {
      onAction({ code: "RVA-2", message: t("RVA-2") });
      return;
    }

    if (!gender) {
      onAction({ code: "RVA-3", message: t("RVA-3") });
      return;
    }

    if (phone && !isValidPhone(phone)) {
      onAction({ code: "RVA-4", message: t("RVA-4") });
      return;
    }

    onAction(true);
  }

  return (
    <>
      <HStack>
        <Box width={"full"}>
          <FormControl isRequired>
            <FormLabel>F. nacimiento</FormLabel>
            <Input
              id="sup-birthday"
              placeholder="Selecciona tu fecha de nacimiento"
              size="md"
              type="date"
              min="1920-01-01"
              max="2020-01-01"
              onChange={birthdayFunc}
              defaultValue={birthday ? birthday : ""}
              onKeyDown={!appView ? birthdayPressed : null}
              autoFocus
            />
          </FormControl>
        </Box>
        <Box width={"full"}>
          <FormControl isRequired>
            <FormLabel>Sexo</FormLabel>
            <Select
              id="sup-gender"
              placeholder="Selecciona uno"
              borderRadius="0px"
              onChange={genderFunc}
              defaultValue={gender ? gender : ""}
              onKeyDown={!appView ? genderPressed : null}
            >
              <option value="MALE">Hombre</option>
              <option value="FEMALE">Mujer</option>
            </Select>
          </FormControl>
        </Box>
      </HStack>
      <FormControl pt={2}>
        <FormLabel width="fit-content">Teléfono móvil</FormLabel>
        <InputGroup>
          <InputLeftAddon children="+34" borderRadius="0px" />
          <Input
            id="sup-phone"
            type="tel"
            placeholder="Introduce tu teléfono"
            onInput={phoneFunc}
            onKeyDown={!appView ? phonePressed : null}
            defaultValue={phone ? phone : ""}
          />
        </InputGroup>
      </FormControl>

      <Stack
        direction={{ base: "column", xs: "row" }}
        align={"start"}
        justify={"space-between"}
        mb={2}
      >
        <Button
          variant="link"
          colorScheme="white"
          color={"blue.800"}
          size="xs"
          fontSize="sm"
          mt={2.5}
          onClick={() => {
            onAction(false);
          }}
        >
          Ver anterior
        </Button>
        <Link as={Text} color={"blue.400"} pt={2} pb={0} fontSize="sm">
          ¿Ya eres miembro?
        </Link>
      </Stack>
      {appView && <Spacer />}
      <Tooltip
        hasArrow
        label="¡Rellena los campos obligatorios!"
        bg="blue.800"
        placement="top"
        color="white"
        isDisabled={next}
      >
        <Button
          id="sup-next2"
          isDisabled={!next}
          bg={"blue.400"}
          color={"white"}
          _hover={{
            bg: "blue.600",
          }}
          onClick={() => checkFields()}
        >
          Siguiente
        </Button>
      </Tooltip>
    </>
  );
};

// THIRD FORM: Email, PASSWORD, terms and conditions CHECKED
var email = false;
var password = false;
var check = false;
const Form3 = ({ onAction, appView }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [next, setNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const { t } = useTranslation();

  function emailFunc(e) {
    e.target.value !== "" ? (email = e.target.value) : (email = false);
    email && password && check ? setNext(true) : setNext(false);
  }

  function passFunc(e) {
    e.target.value !== "" ? (password = e.target.value) : (password = false);
    email && password && check ? setNext(true) : setNext(false);
  }

  function checkFunc(e) {
    check = e.target.checked;
    email && password && check ? setNext(e.target.checked) : setNext(false);
  }

  function supemailPressed(event) {
    if (event.key === "Tab") {
      document.getElementById("sup-password").focus();
      return;
    }

    if (event.key === "Enter") {
      document.getElementById("sup-register").click();
      return;
    }
  }

  function suppasswordPressed(event) {
    if (event.key === "Tab") {
      document.getElementById("sup-register").focus();
      return;
    }

    if (event.key === "Enter") {
      document.getElementById("sup-register").click();
      return;
    }
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^.{8,25}$/;
    return passwordRegex.test(password);
  };

  function checkFields() {
    if (!email || !isValidEmail(email)) {
      onAction({
        code: "RVA-5",
        message: "Correo electrónico no válida",
      });
      return;
    }

    if (!password || !isValidPassword(password)) {
      onAction({
        code: "RVA-6",
        message: "Contraseña debe tener entre 8-25 carácteres",
      });
      return;
    }

    signup();
  }

  const signup = async (e) => {
    console.log("Attempting sign up...");
    setIsLoading(true);

    await axios
      .post("/api/user/signup", {
        name,
        surname1,
        surname2,
        email,
        password,
        gender,
        birthday,
        phone: "+34" + phone,
      })
      .then((response) => {
        console.log(response);

        onAction({ code: "SUC-1", message: t("SUC-1") });
        setIsRegistered(true);
      })
      .catch((error) => {
        onAction({
          code: error.response.data.code,
          message: error.response.data.message,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <FormControl isRequired>
        <FormLabel>Correo electrónico</FormLabel>
        <Input
          id="sup-email"
          type="email"
          name="username"
          autoComplete="username"
          placeholder="ejemplo@email.com"
          onInput={emailFunc}
          isDisabled={isLoading || isRegistered}
          defaultValue={email ? email : ""}
          onKeyDown={!appView ? supemailPressed : null}
          autoFocus
        />
      </FormControl>
      <FormControl isRequired pt={2}>
        <FormLabel>Contraseña</FormLabel>
        {/*TODO: formulario para esto con react hook form */}
        <InputGroup>
          <Input
            id="sup-password"
            type={showPassword ? "text" : "password"}
            name="password"
            autoComplete="new-password"
            placeholder="Escoge tu contraseña"
            onInput={passFunc}
            isDisabled={isLoading || isRegistered}
            onKeyDown={!appView ? suppasswordPressed : null}
          />
          <InputRightElement h={"full"}>
            <Button
              variant={"ghost"}
              colorScheme={"white"}
              onClick={() => setShowPassword((showPassword) => !showPassword)}
              isDisabled={isLoading}
            >
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Stack
        direction={{ base: "column", xs: "row" }}
        align={"start"}
        justify={"space-between"}
        mb={2}
      >
        <Button
          variant="link"
          colorScheme="white"
          color={"blue.800"}
          size="xs"
          fontSize="sm"
          mt={2.5}
          onClick={() => {
            onAction(false);
          }}
          isDisabled={isLoading || isRegistered}
        >
          Ver anterior
        </Button>
        <Checkbox
          size="sm"
          pt={2}
          pb={0}
          id="t&c"
          onInput={checkFunc}
          isDisabled={isLoading || isRegistered}
          isRequired
        >
          <HStack spacing={0}>
            <Text>Acepto los&nbsp;</Text>
            <Link
              color={"blue.400"}
              href="/terms"
              display={{ base: "none", sm: "none", md: "none", lg: "flex" }}
              isExternal
            >
              términos y condiciones
            </Link>
            <Link
              color={"blue.400"}
              href="/terms"
              display={{ base: "flex", sm: "flex", md: "flex", lg: "none" }}
              isExternal
            >
              T&C
            </Link>
            <span className="chakra-form__required-indicator css-1tfjd1n bold">
              *
            </span>
          </HStack>
        </Checkbox>
      </Stack>
      {appView && <Spacer />}
      <Button
        id="sup-register"
        isDisabled={!next || isRegistered}
        bg={"blue.400"}
        color={"white"}
        _hover={{
          bg: "blue.600",
        }}
        isLoading={isLoading && !isRegistered}
        loadingText="Registrando usuario"
        onClick={() => checkFields()}
      >
        Registrarse
      </Button>
    </>
  );
};

export function RegisterForm({ onAction, onError, appView }) {
  const [step, setStep] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();

  // When true renders the next form, when false renders the previous form
  function renderForm(e) {
    console.log(e);
    if (!e.code) {
      e ? setStep(step + 1) : setStep(step - 1);
      setIsError(false);
      onError(false);
      return;
    }

    if (e.code === "SUC-1") {
      setIsError(false);
      setIsSuccess(t(e.code));
    } else {
      setIsError(t(e.code));
    }

    onAction(e.code);
    onError(true);
  }

  return (
    <>
      {isError && (
        <Alert
          status="error"
          w={
            !appView
              ? { base: "325px", sm: "350px", md: "400px", lg: "450px" }
              : null
          }
        >
          <AlertIcon />
          <AlertDescription>{isError}</AlertDescription>
        </Alert>
      )}
      {isSuccess && (
        <Alert
          status="success"
          w={
            !appView
              ? { base: "325px", sm: "350px", md: "400px", lg: "450px" }
              : null
          }
        >
          <AlertIcon />
          <AlertDescription>{isSuccess}</AlertDescription>
        </Alert>
      )}
      <Box
        pt={4}
        pb={5}
        px={7}
        w={
          !appView
            ? { base: "325px", sm: "350px", md: "400px", lg: "450px" }
            : null
        }
        h="100%"
      >
        <Stack spacing={2} h="100%">
          {step === 1 ? (
            <Form1 onAction={renderForm} appView={appView} />
          ) : step === 2 ? (
            <Form2 onAction={renderForm} appView={appView} />
          ) : (
            <Form3 onAction={renderForm} appView={appView} />
          )}
          <HStack py={1}>
            <Divider />
            <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
              próximamente...
            </Text>
            <Divider />
          </HStack>
          <HStack>
            <Button
              w={"full"}
              variant={"outline"}
              leftIcon={<FcGoogle />}
              isDisabled={true}
              fontWeight={300}
            >
              Continúa con Google
            </Button>
            {/*<Button
                w={"full"}
                variant={"outline"}
                leftIcon={<TwitterIcon />}
          >Twitter</Button>*/}
          </HStack>
        </Stack>
      </Box>
    </>
  );
}
