import * as React from "react";
import { Bonos } from "../components/Bonos";
import { Portrait } from "../components/Portrait";
import { InstagramFeed } from "../components/Instagram";
import { ChakraProvider } from "@chakra-ui/react";
import { NextTrainings } from "../components/NextTrainings";
import { Scheduler } from "../components/Scheduler";
//import { SchedulerV2 } from "../components/Scheduler";
import { ServicesOffered } from "../components/ServicesOffered";
import { FAQs } from "../components/FAQs";
import { useAuth } from "../auth/useAuth";
import { Coaches } from "../components/Coaches";
import { Helmet } from "react-helmet-async";
import ConfirmationModal from "../components/ConfirmationModal";

export function Home() {
  const auth = useAuth();
  const [refresh, setRefresh] = React.useState(false);

  function refreshNexTrainings() {
    setRefresh(!refresh);
  }

  return (
    <>
      <Helmet>
        <title>CUC Fitness Studio</title>
        <meta
          name="description"
          content="El gimnasio de entrenamientos personales y grupos reducidos de Sa Cabaneta, Marratxí: funcional, yoga, pilates, asesoramiento nutricional, fisioterapia..."
        />
      </Helmet>
      <ChakraProvider>
        {auth.isLogged() ? (
          <NextTrainings refreshNexTrainings={refresh} />
        ) : (
          <Portrait />
        )}

        {!auth.isLogged() && (
          <section id="services">
            <ServicesOffered />
          </section>
        )}

        <section id="schedule">
          <Scheduler onSchedulerChange={refreshNexTrainings} />
        </section>

        {!auth.isLogged() && (
          <section id="bonos">
            <Bonos />
          </section>
        )}

        {!auth.isLogged() && (
          <div className="clear-fix-social">
            <section id="social">
              <InstagramFeed />
            </section>
          </div>
        )}

        {!auth.isLogged() && (
          <section id="team">
            <Coaches />
          </section>
        )}

        {!auth.isLogged() && (
          <section id="faqs">
            <FAQs />
          </section>
        )}
        {auth.isLogged() && auth.user.lastLogin === null && (
          <ConfirmationModal
            title={"¡Hola " + auth.user.name + "!"}
            message="Ya puedes disfrutar de una sesión de entrenamiento gratuito. Gracias por confiar en nosotros."
            yesButton="Continuar"
            onConfirm={() => auth.updateLastLogin()}
          />
        )}

        {/*!auth.isLogged() && (
                <section id="faqs">
                  <iframe
                    title="Ubicación de CUC Fitness Studio"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3073.244947691212!2d2.7462617758268237!3d39.621688271578456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1297eb20ba60405f%3A0x724aa92c96d227c4!2sCuc%20Fitness%20Studio!5e0!3m2!1ses!2ses!4v1710506046927!5m2!1ses!2ses"
                    width="100%"
                    height="400"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </section>
              )*/}
      </ChakraProvider>
    </>
  );
}

console.log(
  process.env.REACT_APP_NAME + ": " + process.env.REACT_APP_ENV + " environment"
);

export default Home;
