import * as React from "react";
import {
  ChakraProvider,
  Box,
  Input,
  InputGroup,
  FormControl,
  FormLabel,
  InputRightElement,
  Button,
  Stack,
  HStack,
  Heading,
  Text,
  Flex,
  Image,
  Center,
  Tab,
  TabPanels,
  TabPanel,
  Tabs,
  TabList,
  Divider,
  Link,
  Alert,
  AlertDescription,
  AlertIcon,
  Spacer,
} from "@chakra-ui/react";
//import { Spinner } from "@chakra-ui/react";
//import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FcGoogle } from "react-icons/fc";
import { useForm /*Controller*/ } from "react-hook-form";
import Logo from "../static/CUC_LOGO.svg";
import LoginPortrait from "../images/portrait/move_it.jpg";
import { useViewportSize } from "../hooks/useViewportSize";
import { Link as ReactRouterLink } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import axios from "axios";

// TODO: Set a message error when the token is invalid --> Fix the API call to return a 404 error
// TODO: add shadow to the whole tab
// SAMPLE: url.com/reset/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJjbXVyZ2E5QGdtYWlsLmNvbSIsImFjdGl2YXRlIjp0cnVlLCJleHAiOjE2OTI3NDg4MDB9.vld5AK5-tIikKxMkIRkdLgSn1SIHai48wTnvDEmHfYE
// DOCUMENTACIÓN PASSWORDS GESTOR DE CONTRASEÑAS: https://www.chromium.org/developers/design-documents/create-amazing-password-forms/
// DOCUMENTACIÓN: https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/

export function ResetPassword() {
  //const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const { height } = useViewportSize();
  const [isResetOk, setIsResetOk] = useState(false);
  const [isResetError, setIsResetError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);

  const {
    //control,
    handleSubmit,
    register: eventRegister,
    //setValue,
    //reset,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    shouldFocusError: false,
  });

  async function onSubmit(values) {
    if (getValues("password") !== getValues("password2")) {
      setError("password", { type: "manual" }); // passwords are not equal
      setError("password2", { type: "manual" }); // passwords are not equal
      setIsResetError(t("FVA-1"));
      return;
    }

    setIsLoading(true);

    await axios
      .post("/api/user/password/" + token, {
        password: values.password,
      })
      .then((res) => {
        setIsResetOk("Contraseña actualizada con éxito");
        setFormDisabled(true);
      })
      .catch((error) => {
        setIsResetError(error.response.data.message);
      });

    setIsLoading(false);
  }

  function onErrors(errors) {
    console.log("errors", errors);
  }

  return (
    <>
      <ChakraProvider>
        <Stack spacing={0} h={"100vh"}>
          <Box
            w="100%"
            h="80px"
            bg="gray.900"
            textAlign="center"
            display={{ base: "block", sm: "block", md: "block", lg: "none" }}
          >
            <Center p={4}>
              <Image
                src={Logo}
                width="70px"
                height="50px"
                alt="CUC logo"
                style={{ fill: "black" }}
              />
            </Center>
          </Box>

          <Stack minH={{ lg: "100vh" }} direction={"row"} spacing={0}>
            <Flex
              flex={1}
              display={{ base: "none", sm: "none", md: "none", lg: "flex" }}
            >
              <Image
                alt={"Login Image"}
                objectFit={"cover"}
                src={LoginPortrait}
              />
            </Flex>
            <Flex
              flex={1}
              align={"center"}
              justify={"center"}
              direction={"column"}
              //minH={{ base: "750px", sm: "750px" }}
            >
              <Stack
                spacing={4}
                display={{ base: "none", sm: "none", md: "none", lg: "flex" }}
                mb={8}
              >
                <Heading
                  fontFamily={"Oswald"}
                  color={"gray.800"}
                  lineHeight={"110%"}
                  fontWeight={500}
                  fontSize={{
                    base: "4xl",
                    sm: "5xl",
                    md: "4xl",
                    lg: "5xl",
                    xl: "5xl",
                    "2xl": "6xl",
                  }}
                  pt={{
                    base: height < 750 ? "103px" : 14,
                    sm: height < 750 ? "103px" : 16,
                    md: 0,
                  }}
                >
                  No cunda el&nbsp;
                  <Text
                    as={"span"}
                    bgGradient="linear(to-r, blue.500,blue.600)"
                    bgClip="text"
                  >
                    pánico
                  </Text>
                  ...
                </Heading>
                <Text
                  color={"gray.500"}
                  fontSize={{ base: "md", sm: "md" }}
                  maxW="500px"
                >
                  Pronto tendrás acceso completo a tu cuenta
                </Text>
              </Stack>
              <HStack
                border="1px solid #E2E8F0"
                bg="white"
                py={2}
                px={0}
                w={{ base: "100%", sm: "100%", md: "100%", lg: "80%" }}
                maxW={{ lg: "600px" }}
                h={{
                  base: "calc(100vh - 80px)",
                  sm: "calc(100vh - 80px)",
                  md: "calc(100vh - 80px)",
                  lg: "fit-content",
                }}
              >
                <Tabs isFitted id="login-tabs" w="100%" h="100%">
                  <TabList>
                    <Tab>Crear contraseña</Tab>
                  </TabList>

                  <TabPanels
                    h={
                      isResetError || isResetOk
                        ? "calc(100% - 90px)"
                        : "calc(100% - 42px)"
                    }
                  >
                    <TabPanel className="p0-tab-panel pt0-tab-panel" h="100%">
                      {isResetOk && (
                        <Alert status="success">
                          <AlertIcon />
                          <AlertDescription>{isResetOk}</AlertDescription>
                        </Alert>
                      )}

                      {isResetError && (
                        <Alert status="error">
                          <AlertIcon />
                          <AlertDescription>{isResetError}</AlertDescription>
                        </Alert>
                      )}
                      <form
                        onSubmit={handleSubmit(onSubmit, onErrors)}
                        className="same-height"
                      >
                        <Stack
                          bg="white"
                          p="16px 28px 0px 28px"
                          margin="auto"
                          spacing={2}
                          m="auto"
                          w="100%"
                          h="100%"
                        >
                          <Stack
                            mb={2}
                            textAlign="left"
                            display={{
                              base: "flex",
                              sm: "flex",
                              md: "flex",
                              lg: "none",
                            }}
                          >
                            <Text size="md">
                              ¡Que no cunda el pánico! Establece tu nueva
                              contraseña a continuación:
                            </Text>
                          </Stack>
                          <FormControl isRequired isInvalid={errors.password}>
                            <FormLabel htmlFor="password">
                              Nueva contraseña
                            </FormLabel>
                            <InputGroup>
                              <Input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                autoComplete="new-password"
                                isReadOnly={formDisabled}
                                placeholder="Introduce una nueva clave"
                                {...eventRegister("password", {
                                  required: true,
                                })}
                              />
                              <InputRightElement h={"full"}>
                                <Button
                                  variant={"ghost"}
                                  colorScheme={"white"}
                                  onClick={() =>
                                    setShowPassword(
                                      (showPassword) => !showPassword
                                    )
                                  }
                                >
                                  {showPassword ? (
                                    <ViewIcon />
                                  ) : (
                                    <ViewOffIcon />
                                  )}
                                </Button>
                              </InputRightElement>
                            </InputGroup>
                          </FormControl>

                          <FormControl
                            pt={2}
                            isRequired
                            isInvalid={errors.password2}
                          >
                            <FormLabel htmlFor="password2">
                              Repite la nueva contraseña
                            </FormLabel>
                            <InputGroup>
                              <Input
                                id="password2"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                autoComplete="new-password"
                                isReadOnly={formDisabled}
                                placeholder="Introduce tu clave nuevamente"
                                {...eventRegister("password2", {
                                  required: true,
                                })}
                              />
                              <InputRightElement h={"full"}>
                                <Button
                                  variant={"ghost"}
                                  colorScheme={"white"}
                                  onClick={() =>
                                    setShowPassword(
                                      (showPassword) => !showPassword
                                    )
                                  }
                                >
                                  {showPassword ? (
                                    <ViewIcon />
                                  ) : (
                                    <ViewOffIcon />
                                  )}
                                </Button>
                              </InputRightElement>
                            </InputGroup>
                          </FormControl>
                          <Stack
                            direction={{ base: "column", xs: "row" }}
                            align={"start"}
                            justify={"space-between"}
                            pt={2}
                            mb={2}
                          >
                            <Link
                              fontSize="sm"
                              as={ReactRouterLink}
                              to="/"
                              variant="link"
                              colorScheme="white"
                              color={"blue.800"}
                              fontWeight={500}
                            >
                              Página principal
                            </Link>
                            <Link
                              color={"blue.400"}
                              fontSize="sm"
                              as={ReactRouterLink}
                              to="https://wa.me/34674088825"
                              target="_blank"
                              rel="noreferrer"
                            >
                              ¿Tienes problemas?
                            </Link>
                          </Stack>
                          <Spacer />
                          <Button
                            type="submit"
                            colorScheme="blue"
                            borderRadius={0}
                            display={"flex"}
                            alt="Restablecer contraseña"
                            isLoading={isLoading}
                            isDisabled={formDisabled}
                            spinner={<BeatLoader size={8} color="white" />}
                          >
                            Guardar
                          </Button>
                          <HStack py={1}>
                            <Divider />
                            <Text
                              textStyle="sm"
                              whiteSpace="nowrap"
                              color="fg.muted"
                            >
                              próximamente
                            </Text>
                            <Divider />
                          </HStack>
                          <HStack>
                            <Button
                              w={"full"}
                              variant={"outline"}
                              leftIcon={<FcGoogle />}
                              isDisabled={true}
                              //onClick={() => GoogleLogin()}
                            >
                              Continúa con Google
                            </Button>
                          </HStack>
                        </Stack>
                      </form>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </HStack>
            </Flex>
          </Stack>
        </Stack>
      </ChakraProvider>
    </>
  );
}

export default ResetPassword;
