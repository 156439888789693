import { Navigate } from "react-router-dom";
import { useAuth } from "./auth/useAuth";
import { Outlet } from "react-router-dom";

// Only unlogged users can access this route
export function ProtectedRoute({ component: Component, ...rest }) {
  const auth = useAuth();

  return !auth.isLogged() ? <Outlet /> : <Navigate to="/" />;
}
