import { TrainingActivity } from "../../Activity/UserBookings/TrainingActivityCard";
import React from "react";
import { Box, SimpleGrid, Skeleton, Text } from "@chakra-ui/react";
//import { MdOutlineBookmarkAdd } from "react-icons/md";
import axios from "axios";
import { useEffect, useState } from "react";

export function UserTrainings(props) {
  const [trainings, setTrainings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setTrainings(null);
      setLoading(true);
      const { data } = await axios.get("/api/admin/bookings/" + props.userId);
      setTrainings(data);
      setLoading(false);
    })();
  }, [props.userId]);

  return (
    <>
      <Box w="100%" m="auto" background="#EDF2F7">
        {/*<Button
          title="Añadir nuevo bono"
          variant="ghost"
          size={{ base: "sm", sm: "sm", md: "sm" }}
          fontWeight={400}
          background-color="#EDF2F7"
        >
          Añadir reserva&nbsp;
          <MdOutlineBookmarkAdd size="1.25em" />
  </Button>*/}
      </Box>
      {!loading && trainings && trainings.length === 0 && (
        <Text mt={5} mb={4}>No existen reservas para este usuario</Text>
      )}

      <SimpleGrid
        w="100%"
        m="auto"
        spacing={2}
        columns={{ md: 1, lg: 1, xl: 2 }}
      >
        {trainings &&
          trainings.map((training) => (
            <TrainingActivity
              key={training.trainingId}
              trainingName={training.trainingName}
              trainingStatus={training.status}
              trainingDate={training.startDate}
              trainingTime={training.startDate}
              trainingImage={training.trainingImage}
              bonoAdmin
            />
          ))}

        {(!trainings || loading) && (
          <>
            <Skeleton h="145px" />
            <Skeleton h="145px" />
          </>
        )}
      </SimpleGrid>
    </>
  );
}
