import {
  ChakraProvider,
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { UserBonos } from "../components/Activity/UserBonos";
import { UserBookings } from "../components/Activity/UserBookings";
import { MdOutlineBook, MdOutlineLocalFireDepartment } from "react-icons/md";
import { Helmet } from "react-helmet-async";
// estilos en next trainings TODO: moverlos al componente

export function Activity() {
  return (
    <>
      <Helmet>
        <title>CUC Fitness Studio</title>
        <meta
          name="description"
          content="Todas tus reservas y bonos en CUC Fitness Studio."
        />
      </Helmet>
      <ChakraProvider>
          <Box pb={2}>
            <Box
              pt={"100px"}
              textAlign="justify"
              margin={"auto"}
              maxWidth={"800px"}
            >
              <Heading
                fontFamily={"Oswald"}
                fontWeight={400}
                fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
                lineHeight={"110%"}
                color={"gray.800"}
                p={6}
                pt={4}
                textAlign={"center"}
              >
                Mi actividad
              </Heading>
            </Box>
            <Box>
              <Tabs isFitted isLazy lazyBehavior="keepMounted">
                <TabList
                  width={{ base: "100%", sm: "100%", md: "80%" }}
                  maxW="1300px"
                  m="auto"
                >
                  <Tab>
                    <MdOutlineBook />
                    &nbsp;Reservas
                  </Tab>
                  <Tab>
                    <MdOutlineLocalFireDepartment />
                    &nbsp;Bonos
                  </Tab>
                </TabList>

                <TabPanels minH={"335px"}>
                  <TabPanel className="p0-tab-panel">
                    <UserBookings />
                  </TabPanel>
                  <TabPanel className="p0-tab-panel">
                    <UserBonos />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
      </ChakraProvider>
    </>
  );
}

export default Activity;
