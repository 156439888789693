import {
  Box,
  Button,
  Stack,
  Text,
  Link,
  Slide,
  Hide,
} from "@chakra-ui/react";
import { NavLink as ReachLink } from "react-router-dom";
import Cookies from "js-cookie";
import { useState } from "react";
// EJEMPLO COOKIE SÓLO TECNICA COMO ESTA: https://www.aepd.es/es
// CONSENTIMIENTO DE COOKIES BIBLIOGRAFIA: https://www.incibe.es/empresas/blog/son-las-cookies-y-mostrarlas-sitio-web
// otra web de interés: https://www.aepd.es/es
// i am using this library js-cookie

export function CookiesConsent() {
  const [isOpen, setIsOpen] = useState(!Cookies.get("ConsentCookie"));

  function setConsentCookie() {
    Cookies.set("ConsentCookie", "true", { expires: 7 });
    setIsOpen(false);
  }

  return (
    <>
      <Slide
        direction="bottom"
        in={isOpen}
        style={{ zIndex: 11 }}
        display={{ base: "none", sm: "flex" }}
      >
        <Box p="20px" color="white" bg="gray.800" shadow="md">
          <Stack
            direction="row"
            spacing={{ base: "8", sm: "10" }}
            justify="space-between"
            textAlign="left"
            py={1}
            px={1}
            columns={{ base: 1, sm: 2 }}
          >
            <Box width="100%" margin="auto">
              <Text
                fontWeight="medium"
                fontSize={{ base: "md", sm: "md", md: "large", lg: "xl" }}
                margin={"auto"}
              >
                Este portal únicamente utiliza{" "}
                <Link as={ReachLink} to="/cookies" color="blue.400">
                  cookies
                </Link>{" "}
                propias con finalidad técnica y no recaba ni cede datos de
                carácter personal de los usuarios sin su conocimiento.
              </Text>
            </Box>
            <Hide below="md">
              <Box display="flex" alignItems="center" width="fit-content">
                <Button
                  size={{ base: "sm", sm: "sm", md: "md" }}
                  bgColor="blue.400"
                  color={"white"}
                  borderRadius={0}
                  _hover={{
                    bg: "blue.600",
                  }}
                  fontWeight={400}
                  onClick={setConsentCookie}
                >
                  Entendido
                </Button>
              </Box>
            </Hide>
          </Stack>
          <Hide above="md">
            <Button
              size={{ base: "md", sm: "md", md: "md" }}
              bgColor="blue.400"
              color={"white"}
              borderRadius={0}
              _hover={{
                bg: "blue.600",
              }}
              fontWeight={400}
              onClick={setConsentCookie}
              width="100%"
              mt={4}
            >
              Entendido
            </Button>
          </Hide>
        </Box>
      </Slide>
    </>
  );
}

export default CookiesConsent;