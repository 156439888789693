import * as React from "react";
import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
  Spinner,
  Text,
  ChakraProvider,
} from "@chakra-ui/react";
import { AdminScheduler } from "../components/AdminScheduler";
import { AdminBonos } from "../components/AdminBonos";
//import { AdminPayments } from "../components/AdminPayments";
import { AdminStats } from "../components/AdminStats";
import { AdminUsers } from "../components/AdminUsers";
import { AdminCoaches } from "../components/AdminCoaches";
import {
  MdOutlineCalendarMonth,
  MdPersonOutline,
  MdOutlineLocalFireDepartment,
  MdWorkspacePremium,
  MdOutlineQueryStats,
} from "react-icons/md";
import axios from "axios";
import { useEffect } from "react";
import { useAuth } from "../auth/useAuth";
import { Helmet } from "react-helmet-async";

export function Admin() {
  const [schedulerData, setSchedulerData] = React.useState([]);
  const auth = useAuth();

  useEffect(() => {
    (async () => {
      const sessions = await axios.get("/api/admin/trainingTypes");
      const coaches = await axios.get("/api/admin/coaches");

      setSchedulerData({
        sessionsList: sessions.data,
        coachesList: coaches.data,
      });
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin - CUC Fitness Studio</title>
        <meta
          name="description"
          content="Área de administrador de CUC Fitness Studio."
        />
      </Helmet>
      <ChakraProvider>
        <Box
          pt={"100px"}
          textAlign="justify"
          margin={"auto"}
          maxWidth={"800px"}
        >
          <Heading
            fontFamily={"Oswald"}
            fontWeight={400}
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color={"gray.800"}
            p={6}
            pt={4}
            textAlign={"center"}
          >
            Área
            <Text as={"span"} color={"blue.500"}>
              &nbsp;administrador
            </Text>
          </Heading>
        </Box>
        <Box>
          <Tabs isFitted isLazy lazyBehavior="keepMounted">
            <TabList
              width={{ base: "100%", sm: "100%", md: "80%" }}
              maxW="1300px"
              m="auto"
            >
              <Tab>
                <MdOutlineQueryStats />
                <Text display={{ base: "none", sm: "none", md: "flex" }}>
                  &nbsp;Estadísticas
                </Text>
              </Tab>
              <Tab>
                <MdOutlineCalendarMonth />
                <Text display={{ base: "none", sm: "none", md: "flex" }}>
                  &nbsp;Calendario
                </Text>
              </Tab>
              <Tab>
                <MdPersonOutline />
                <Text display={{ base: "none", sm: "none", md: "flex" }}>
                  &nbsp;Usuarios
                </Text>
              </Tab>
              <Tab>
                <MdOutlineLocalFireDepartment />
                <Text display={{ base: "none", sm: "none", md: "flex" }}>
                  &nbsp;Bonos
                </Text>
              </Tab>
              {(auth.user.id === 1 || auth.user.id === 2) && (
                <Tab>
                  <MdWorkspacePremium />
                  <Text display={{ base: "none", sm: "none", md: "flex" }}>
                    &nbsp;Coaches
                  </Text>
                </Tab>
              )}
            </TabList>

            {schedulerData.sessionsList && schedulerData.coachesList ? (
              <TabPanels>
                <TabPanel className="p0-tab-panel pb0-tab-panel">
                  <AdminStats />
                </TabPanel>
                <TabPanel className="p0-tab-panel pb0-tab-panel">
                  <AdminScheduler
                    sessionsList={schedulerData.sessionsList}
                    coachesList={schedulerData.coachesList}
                  />
                </TabPanel>
                <TabPanel className="p0-tab-panel pb0-tab-panel" pt={{ md: 0 }}>
                  <AdminUsers />
                </TabPanel>
                <TabPanel className="p0-tab-panel pb0-tab-panel">
                  <AdminBonos />
                </TabPanel>
                <TabPanel className="p0-tab-panel pb0-tab-panel">
                  {(auth.user.id === 1 || auth.user.id === 2) && (
                    <AdminCoaches coachesList={schedulerData.coachesList} />
                  )}
                </TabPanel>
              </TabPanels>
            ) : (
              <Spinner size="xl" m={10} />
            )}
          </Tabs>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default Admin;
