import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import { Coach } from "../components/Coaches/CoachCard";
import Andreu from "../images/coaches/andreu_massot.webp";
import Elo from "../images/coaches/elo_salva.webp";
import Maria from "../images/coaches/maria_portalo.webp";
import Carlota from "../images/coaches/carlota_provenzali.webp";
import Marta from "../images/coaches/marta_daviu.webp";
import { Helmet } from "react-helmet-async";

export function About() {
  return (
    <>
      <Helmet>
        <title>Quiénes somos - CUC Fitness Studio</title>
        <meta
          name="description"
          content="Conoce más sobre nosotros y nuestro equipo."
        />
      </Helmet>
      <ChakraProvider>
        <Box pt={"100px"} pb={8} textAlign="justify" margin={"auto"}>
          <Heading
            fontFamily={"Oswald"}
            fontWeight={400}
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color={"gray.800"}
            p={6}
            mb={4}
            textAlign={"center"}
          >
            Quiénes
            <Text as={"span"} color={"blue.500"}>
              {" "}
              somos
            </Text>
          </Heading>
          <Box
            textAlign="justify"
            margin={"auto"}
            width="80%"
            maxWidth={"1100px"}
          >
            <Text>
              CUC Fitness Studio nace en el año 2020 con la clara convicción de
              que el entrenamiento debe ser personalizado y adaptarse al
              individuo. Por ello, hemos creado un espacio donde las sesiones en
              grupos reducidos y los entrenamientos personales e
              individualizados son la norma, no la excepción. Nuestra meta es
              clara: proporcionar un servicio que supere las expectativas de los
              deportistas que cruzan nuestra puerta, ententiendo que cada
              persona tiene objetivos y necesidades únicas que debemos
              satisfacer.
            </Text>
            <Text mt={8}>
              Contamos con un equipo altamente cualificado que se encargará de
              velar por la consecución de tus objetivos, ¿te los presento?
            </Text>
            <SimpleGrid
              columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
              m="auto"
              mt={5}
            >
              <Coach
                coachName="Andreu Massot"
                coachTitle="CEO & Coach"
                coachDescription="TAFAD, HYROX Coach y Especialista Universitario en Nutrición Deportiva"
                coachImage={Andreu}
                coachLink="https://www.instagram.com/andreu_massot/"
                about={true}
                coachTrainings={["FUNCIONAL"]}
              />
              <Coach
                coachName="Elo Salvà"
                coachTitle="Coach"
                coachDescription="Graduada en CAFYD y Máster en Formación del Profesorado"
                coachImage={Elo}
                about={true}
                coachTrainings={["FUNCIONAL", "ESPALDA SANA"]}
              />
              <Coach
                coachName="Maria Portalo"
                coachTitle="Yoga coach"
                coachDescription="Formación 300 RYT y Especialista en Yoga para la Mujer"
                coachImage={Maria}
                about={true}
                coachTrainings={["YOGA", "YOGA REST"]}
              />
              <Coach
                coachName="Carlota Provenzali"
                coachTitle="Diestista-nutricionista"
                coachDescription="Especialista en salud hormonal y digestiva. Col. IB00285"
                coachImage={Carlota}
                coachLink="https://www.instagram.com/carlota_nutri/"
                about={true}
                coachTrainings={["NUTRICIÓN"]}
              />
              <Coach
                coachName="Marta Daviu"
                coachTitle="Fisioterapeuta & Coach"
                coachDescription="Graduada en Fisioterapia y Especialista en Ejercicio Terapéutico. Col. 2082"
                coachImage={Marta}
                coachLink="https://www.instagram.com/marthaviu/"
                about={true}
                coachTrainings={["FISIOTERAPIA", "STRETCH"]}
              />
            </SimpleGrid>
          </Box>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default About;
