import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";

// Props incluyen el mensaje y una función para manejar la respuesta
const ConfirmationModal = ({
  title,
  message,
  yesButton,
  noButton,
  optionalButton,
  onConfirm,
  isDangerous,
}) => {
  const {
    isOpen: isConfirmationModalOpen,
    onOpen: onConfirmationModalOpen,
    onClose: onConfirmationModalClose,
  } = useDisclosure();

  React.useEffect(() => {
    onConfirmationModalOpen(); // Abre el modal automáticamente cuando el componente se monta
  }, [onConfirmationModalOpen]);

  // Maneja el cierre del modal y envía la respuesta al componente padre
  const handleResponse = (response) => {
    onConfirmationModalClose(); // Cierra el modal
    onConfirm(response); // Envía la respuesta al componente padre
  };

  return (
    <Modal
      isOpen={isConfirmationModalOpen}
      onClose={() => {}}
      closeOnOverlayClick={false}
      isCentered
      size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={2} textAlign={"left"}>
          {title}
        </ModalHeader>
        <ModalBody textAlign={"left"}>{message}</ModalBody>

        <ModalFooter justifyContent="space-between">
          {optionalButton && (
            <Button mr={3} onClick={() => handleResponse(null)}>
              {optionalButton}
            </Button>
          )}
          <Spacer />
          {noButton && (
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => handleResponse(false)}
            >
              {noButton}
            </Button>
          )}
          <Button
            variant={"outline"}
            colorScheme={isDangerous ? "red" : "blue"}
            borderRadius={0}
            display={"flex"}
            justifyContent={"flex-end"}
            onClick={() => handleResponse(true)}
          >
            {yesButton}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
