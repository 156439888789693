import {
  Box,
  Text,
  Stack,
  HStack,
  Badge,
  Avatar,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { ModalBono } from "./ModalBono";

export function BonoRequested(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ModalBono
        isOpen={isOpen}
        onClose={(refresh) => {
          onClose();
          refresh === true && props.onAction(refresh);
        }}
        usersList={[
          {
            id: props.bonoId,
            name: props.bonoUser.name,
            surname1: props.bonoUser.surname1,
            surname2: props.bonoUser.surname2,
            avatar: props.bonoUser.avatar,
          },
        ]}
        bonoTypesList={props.bonoTypesList}
        bonoRequested={{
          bonoId: parseInt(props.bonoId),
          bonoTypeId: parseInt(props.bonoTypeId),
          remainingSessions: parseInt(props.bonoSessions),
          total: parseFloat(props.bonoPrice).toFixed(2),
        }}
        returnFocusOnClose={false}
      />
      <Box m="auto" w="100%" onClick={onOpen} className="prevent-select">
        <HStack
          m="auto"
          role={"group"}
          p={4}
          bg="white"
          boxShadow={"sm"}
          spacing={2}
          w="100%"
          cursor={"pointer"}
          pl={4}
        >
          <Avatar
            size="xl"
            src={props.bonoUser.avatar}
            name={`${props.bonoUser.name} ${props.bonoUser.surname1}`.trim()}
          />

          <Stack mt={0} pl={2.5} textAlign={"left"} spacing={1}>
            <Text
              fontFamily={"Oswald"}
              fontWeight="500"
              fontSize="2xl"
              color="black"
            >
              {props.bonoType}
            </Text>
            <Badge
              variant="solid"
              colorScheme="blue"
              width={"fit-content"}
              borderRadius={0}
              fontWeight={500}
            >
              {props.bonoUser.name + " " + props.bonoUser.surname1}
            </Badge>
            <Stack fontWeight={400} fontSize={"sm"} spacing={-0.5} mt={1}>
              <Text>
                Solicitado:{" "}
                <b>
                  {new Date(props.bonoRequestDate).toLocaleString("es-ES", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </b>
              </Text>
            </Stack>
          </Stack>
        </HStack>
      </Box>
    </>
  );
}
